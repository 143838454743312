import { LandingPage } from '../../pages/LandingPage';
import { LoginPage } from '../../pages/Login';
import { Dashboard } from '../../pages/Dashboard';
import { RepairsPage } from '../../pages/Repairs';
import { CustomersPage } from '../../pages/Customers';
import { InventoryPage } from '../../pages/Inventory';
import { SalesPage } from '../../pages/Sales';
import { ReportsPage } from '../../pages/Reports';
import { SettingsPage } from '../../pages/Settings';
import { WholesaleClientsPage } from '../../pages/WholesaleClients';
import { WholesalePortal } from '../../pages/WholesalePortal';
import { RepairSchedulePage } from '../../pages/RepairSchedule';
import { NotFound } from '../../pages/NotFound';

export const publicRoutes = [
  { path: '/', element: LandingPage },
  { path: '/login', element: LoginPage, restricted: true },
  { path: '/portal', element: WholesalePortal },
];

export const privateRoutes = [
  { path: '/dashboard', element: Dashboard },
  { path: '/dashboard/repairs', element: RepairsPage },
  { path: '/dashboard/schedule', element: RepairSchedulePage },
  { path: '/dashboard/customers', element: CustomersPage },
  { path: '/dashboard/inventory', element: InventoryPage },
  { path: '/dashboard/sales', element: SalesPage },
  { path: '/dashboard/reports', element: ReportsPage },
  { path: '/dashboard/settings', element: SettingsPage },
  { path: '/dashboard/wholesale', element: WholesaleClientsPage },
];

export const notFoundRoute = {
  path: '*',
  element: NotFound,
};