import React, { useState } from 'react';
import { useCustomerStore } from '../store/customers';
import { CustomerForm } from '../components/customers/CustomerForm';
import { PlusCircle, Search, Phone, Mail, MapPin } from 'lucide-react';

export function CustomersPage() {
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const customers = useCustomerStore((state) => state.customers);
  const searchCustomers = useCustomerStore((state) => state.searchCustomers);

  const filteredCustomers = searchQuery ? searchCustomers(searchQuery) : customers;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Gestión de Clientes</h1>
        <button
          onClick={() => setShowForm(!showForm)}
          className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
        >
          <PlusCircle className="h-5 w-5" />
          Nuevo Cliente
        </button>
      </div>

      {showForm && (
        <div className="mb-8 rounded-lg border bg-white p-6 shadow-sm dark:bg-gray-900">
          <h2 className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
            Nuevo Cliente
          </h2>
          <CustomerForm 
            onSuccess={() => setShowForm(false)} 
            onCancel={() => setShowForm(false)}
          />
        </div>
      )}

      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar clientes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-lg border border-gray-300 pl-10 pr-4 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
          />
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {filteredCustomers.map((customer) => (
          <div
            key={customer.id}
            className="rounded-lg border bg-white p-6 shadow-sm dark:bg-gray-900"
          >
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              {customer.name}
            </h3>
            <div className="mt-4 space-y-2">
              <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                <Phone className="h-4 w-4" />
                <span>{customer.phone}</span>
              </div>
              {customer.email && (
                <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                  <Mail className="h-4 w-4" />
                  <span>{customer.email}</span>
                </div>
              )}
              {customer.address && (
                <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
                  <MapPin className="h-4 w-4" />
                  <span>{customer.address}</span>
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <button className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                Ver Historial
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}