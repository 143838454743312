import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNotifications } from '../../hooks/useNotifications';
import { useKeyboardShortcuts } from '../../hooks/useKeyboardShortcuts';
import { useRoutePersistence } from '../../hooks/useRoutePersistence';
import { NotificationSound } from '../notifications/NotificationSound';
import FloatingActions from '../floating/FloatingActions';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { publicRoutes, privateRoutes, notFoundRoute } from './routes';
import { useAuthStore } from '../../store/auth';

export function AppRoutes() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  // Initialize hooks
  useNotifications();
  useKeyboardShortcuts();
  useRoutePersistence();

  return (
    <>
      <NotificationSound />
      
      <Routes>
        {/* Public routes */}
        {publicRoutes.map(({ path, element: Element, restricted }) => (
          <Route
            key={path}
            path={path}
            element={
              <PublicRoute restricted={restricted}>
                <Element />
              </PublicRoute>
            }
          />
        ))}

        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          {privateRoutes.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
        </Route>

        {/* 404 route */}
        <Route path={notFoundRoute.path} element={<notFoundRoute.element />} />
      </Routes>

      {isAuthenticated && <FloatingActions />}
    </>
  );
}