import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, X, Loader2, Bot, Image as ImageIcon } from 'lucide-react';
import { useChatStore } from '../../store/chat';
import { useChatbotStore } from '../../store/chatbot';
import { cn } from '../../lib/utils';
import ReactMarkdown from 'react-markdown';
import { analyzePhoneImage, sendMessage } from '../../api/chat';
import { toast } from 'react-hot-toast';

interface ChatbotProps {
  onClose: () => void;
}

export function Chatbot({ onClose }: ChatbotProps) {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { messages, addMessage, clearMessages } = useChatStore();
  const { position, soundEnabled } = useChatbotStore();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const playNotificationSound = () => {
    if (soundEnabled) {
      const audio = new Audio('/notification.mp3');
      audio.play().catch(() => {});
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error('Por favor, sube solo archivos de imagen');
      return;
    }

    if (file.size > 4 * 1024 * 1024) {
      toast.error('La imagen debe ser menor a 4MB');
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setImageUrl(reader.result);
        }
      };
      reader.readAsDataURL(file);

      setIsLoading(true);
      addMessage({ 
        role: 'user', 
        content: 'Analizando imagen...' 
      });

      const response = await analyzePhoneImage(file);
      if (response) {
        addMessage({ 
          role: 'assistant', 
          content: response 
        });
        playNotificationSound();
      }
    } catch (error) {
      console.error('Error al analizar la imagen:', error);
      toast.error(error instanceof Error ? error.message : 'Error al analizar la imagen');
      addMessage({
        role: 'assistant',
        content: 'Lo siento, hubo un error al analizar la imagen. Por favor, intenta con otra imagen.',
      });
    } finally {
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    setInput('');
    addMessage({ role: 'user', content: userMessage });
    setIsLoading(true);

    try {
      const response = await sendMessage(userMessage);
      if (response) {
        addMessage({ 
          role: 'assistant', 
          content: response 
        });
        playNotificationSound();
      }
    } catch (error) {
      console.error('Error al enviar mensaje:', error);
      toast.error('Error al enviar mensaje');
      addMessage({
        role: 'assistant',
        content: 'Lo siento, ha ocurrido un error. Por favor, intenta de nuevo más tarde.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className={cn(
        "fixed z-50 flex h-[600px] w-[400px] flex-col rounded-lg border bg-white shadow-xl dark:border-gray-700 dark:bg-gray-800",
        position === 'left' ? "left-24 bottom-24" : "right-24 bottom-24"
      )}
    >
      <div className="flex items-center justify-between border-b p-4 dark:border-gray-700">
        <div className="flex items-center gap-2">
          <div className="rounded-lg bg-blue-100 p-2 dark:bg-blue-900/20">
            <Bot className="h-5 w-5 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="font-medium">Asistente Virtual</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              ¿En qué puedo ayudarte?
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => clearMessages()}
            className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          >
            Limpiar chat
          </button>
          <button
            onClick={onClose}
            className="rounded-full p-2 text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        <div className="space-y-4">
          {messages.length === 0 && (
            <div className="text-center text-gray-500 dark:text-gray-400">
              <p>¡Hola! Puedes preguntarme sobre reparaciones de teléfonos</p>
              <p className="mt-2">También puedes subir una imagen para que analice el modelo</p>
            </div>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              className={cn(
                "flex",
                message.role === 'user' ? "justify-end" : "justify-start"
              )}
            >
              <div
                className={cn(
                  "max-w-[80%] rounded-lg px-4 py-2",
                  message.role === 'user'
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 dark:bg-gray-700"
                )}
              >
                <ReactMarkdown className="prose prose-sm dark:prose-invert">
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex justify-start">
              <div className="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-2 dark:bg-gray-700">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Procesando...</span>
              </div>
            </div>
          )}
          {imageUrl && (
            <div className="flex justify-center">
              <img 
                src={imageUrl} 
                alt="Imagen subida" 
                className="max-h-48 rounded-lg object-contain"
              />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="border-t p-4 dark:border-gray-700">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="flex items-center gap-2 rounded-lg border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              <ImageIcon className="h-4 w-4" />
              Subir Imagen
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
          <div className="flex gap-2">
            <input
              ref={inputRef}
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Escribe un mensaje..."
              className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700"
            />
            <button
              type="submit"
              disabled={!input.trim() || isLoading}
              className="flex items-center gap-2 rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 disabled:opacity-50"
            >
              <Send className="h-4 w-4" />
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
}