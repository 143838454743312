import React from 'react';
import { Bar } from 'react-chartjs-2';
import { RepairOrder } from '../../types/repair';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface TechnicianPerformanceProps {
  repairs: RepairOrder[];
  dateRange: string;
}

export function TechnicianPerformance({ repairs, dateRange }: TechnicianPerformanceProps) {
  const calculatePerformance = () => {
    const techStats = new Map<string, { completed: number; avgTime: number }>();

    repairs.forEach(repair => {
      if (!repair.technician) return;

      const stats = techStats.get(repair.technician) || { completed: 0, avgTime: 0 };
      
      if (repair.status === 'completed') {
        stats.completed++;
        const repairTime = new Date(repair.updatedAt).getTime() - new Date(repair.createdAt).getTime();
        stats.avgTime = (stats.avgTime * (stats.completed - 1) + repairTime) / stats.completed;
      }

      techStats.set(repair.technician, stats);
    });

    return {
      technicians: Array.from(techStats.keys()),
      completedRepairs: Array.from(techStats.values()).map(s => s.completed),
      avgTimes: Array.from(techStats.values()).map(s => s.avgTime / (1000 * 60 * 60 * 24)),
    };
  };

  const { technicians, completedRepairs, avgTimes } = calculatePerformance();

  const data = {
    labels: technicians,
    datasets: [
      {
        label: 'Reparaciones Completadas',
        data: completedRepairs,
        backgroundColor: 'rgba(59, 130, 246, 0.8)',
        borderRadius: 4,
      },
      {
        label: 'Tiempo Promedio (días)',
        data: avgTimes,
        backgroundColor: 'rgba(16, 185, 129, 0.8)',
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full h-[300px]">
      <Bar data={data} options={options} />
    </div>
  );
}