import React, { Component, ErrorInfo, ReactNode } from 'react';
import { AlertTriangle, RefreshCw, Home } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error no controlado:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  private handleReload = () => {
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 dark:bg-gray-900">
          <div className="text-center">
            <div className="mb-4 flex justify-center">
              <AlertTriangle className="h-16 w-16 text-red-600" />
            </div>
            <h1 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
              Algo salió mal
            </h1>
            <p className="mb-8 text-gray-600 dark:text-gray-400">
              Ha ocurrido un error inesperado. Por favor, intenta una de las siguientes opciones:
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={this.handleReload}
                className="flex items-center gap-2 rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
              >
                <RefreshCw className="h-4 w-4" />
                Recargar página
              </button>
              <Link
                to="/"
                className="flex items-center gap-2 rounded-lg border border-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-800"
              >
                <Home className="h-4 w-4" />
                Ir al inicio
              </Link>
            </div>
            {process.env.NODE_ENV === 'development' && this.state.errorInfo && (
              <details className="mt-8 rounded-lg border border-gray-200 p-4 text-left dark:border-gray-700">
                <summary className="cursor-pointer text-sm font-medium">Detalles del error</summary>
                <pre className="mt-4 overflow-auto text-xs text-gray-600 dark:text-gray-400">
                  {this.state.error?.toString()}
                  {'\n'}
                  {this.state.errorInfo.componentStack}
                </pre>
              </details>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}