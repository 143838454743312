import React, { useState } from 'react';
import { Filter, X, ChevronDown, ChevronUp } from 'lucide-react';
import { RepairStatus } from '../../types/repair';

export interface RepairFilters {
  status: RepairStatus[];
  dateRange: {
    start: Date | null;
    end: Date | null;
  };
  technician: string[];
  priceRange: {
    min: number | null;
    max: number | null;
  };
  searchTerm: string;
  deviceType: string[];
}

interface RepairFiltersProps {
  filters: RepairFilters;
  onFilterChange: (filters: RepairFilters) => void;
  technicians: string[];
  deviceTypes: string[];
}

export function RepairFilters({
  filters,
  onFilterChange,
  technicians,
  deviceTypes,
}: RepairFiltersProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleStatusChange = (status: RepairStatus) => {
    const newStatuses = filters.status.includes(status)
      ? filters.status.filter(s => s !== status)
      : [...filters.status, status];
    onFilterChange({ ...filters, status: newStatuses });
  };

  const handleDateRangeChange = (field: 'start' | 'end', value: string) => {
    onFilterChange({
      ...filters,
      dateRange: {
        ...filters.dateRange,
        [field]: value ? new Date(value) : null,
      },
    });
  };

  const handleTechnicianChange = (tech: string) => {
    const newTechs = filters.technician.includes(tech)
      ? filters.technician.filter(t => t !== tech)
      : [...filters.technician, tech];
    onFilterChange({ ...filters, technician: newTechs });
  };

  const handlePriceRangeChange = (field: 'min' | 'max', value: string) => {
    onFilterChange({
      ...filters,
      priceRange: {
        ...filters.priceRange,
        [field]: value ? Number(value) : null,
      },
    });
  };

  const handleDeviceTypeChange = (type: string) => {
    const newTypes = filters.deviceType.includes(type)
      ? filters.deviceType.filter(t => t !== type)
      : [...filters.deviceType, type];
    onFilterChange({ ...filters, deviceType: newTypes });
  };

  const clearFilters = () => {
    onFilterChange({
      status: [],
      dateRange: { start: null, end: null },
      technician: [],
      priceRange: { min: null, max: null },
      searchTerm: '',
      deviceType: [],
    });
  };

  const hasActiveFilters = 
    filters.status.length > 0 ||
    filters.dateRange.start ||
    filters.dateRange.end ||
    filters.technician.length > 0 ||
    filters.priceRange.min ||
    filters.priceRange.max ||
    filters.deviceType.length > 0 ||
    filters.searchTerm;

  return (
    <div className="space-y-4 rounded-lg border bg-white p-4 dark:border-gray-700 dark:bg-gray-900">
      {/* Search and Toggle */}
      <div className="flex items-center justify-between gap-4">
        <div className="relative flex-1">
          <input
            type="text"
            value={filters.searchTerm}
            onChange={(e) => onFilterChange({ ...filters, searchTerm: e.target.value })}
            placeholder="Buscar por cliente, dispositivo o IMEI..."
            className="w-full rounded-lg border border-gray-300 pl-4 pr-10 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
          />
        </div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center gap-2 rounded-lg border border-gray-300 px-4 py-2 text-sm font-medium hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800"
        >
          <Filter className="h-4 w-4" />
          Filtros
          {isExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </button>
        {hasActiveFilters && (
          <button
            onClick={clearFilters}
            className="flex items-center gap-2 rounded-lg bg-red-100 px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-200 dark:bg-red-900/50 dark:text-red-400 dark:hover:bg-red-900/75"
          >
            <X className="h-4 w-4" />
            Limpiar
          </button>
        )}
      </div>

      {/* Expanded Filters */}
      {isExpanded && (
        <div className="grid gap-6 pt-4 lg:grid-cols-2">
          {/* Status */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
              Estado
            </label>
            <div className="flex flex-wrap gap-2">
              {['pending', 'in_progress', 'completed', 'delivered'].map((status) => (
                <label
                  key={status}
                  className="flex items-center gap-2 rounded-lg border border-gray-300 px-3 py-1.5 text-sm dark:border-gray-600"
                >
                  <input
                    type="checkbox"
                    checked={filters.status.includes(status as RepairStatus)}
                    onChange={() => handleStatusChange(status as RepairStatus)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  {status === 'pending' && 'Pendiente'}
                  {status === 'in_progress' && 'En Progreso'}
                  {status === 'completed' && 'Completado'}
                  {status === 'delivered' && 'Entregado'}
                </label>
              ))}
            </div>
          </div>

          {/* Date Range */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
              Rango de Fechas
            </label>
            <div className="grid gap-2 sm:grid-cols-2">
              <input
                type="date"
                value={filters.dateRange.start?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateRangeChange('start', e.target.value)}
                className="rounded-lg border border-gray-300 px-3 py-1.5 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
              <input
                type="date"
                value={filters.dateRange.end?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateRangeChange('end', e.target.value)}
                className="rounded-lg border border-gray-300 px-3 py-1.5 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
            </div>
          </div>

          {/* Technicians */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
              Técnico
            </label>
            <div className="flex flex-wrap gap-2">
              {technicians.map((tech) => (
                <label
                  key={tech}
                  className="flex items-center gap-2 rounded-lg border border-gray-300 px-3 py-1.5 text-sm dark:border-gray-600"
                >
                  <input
                    type="checkbox"
                    checked={filters.technician.includes(tech)}
                    onChange={() => handleTechnicianChange(tech)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  {tech}
                </label>
              ))}
            </div>
          </div>

          {/* Price Range */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
              Rango de Precio
            </label>
            <div className="grid gap-2 sm:grid-cols-2">
              <input
                type="number"
                placeholder="Mínimo"
                value={filters.priceRange.min || ''}
                onChange={(e) => handlePriceRangeChange('min', e.target.value)}
                className="rounded-lg border border-gray-300 px-3 py-1.5 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
              <input
                type="number"
                placeholder="Máximo"
                value={filters.priceRange.max || ''}
                onChange={(e) => handlePriceRangeChange('max', e.target.value)}
                className="rounded-lg border border-gray-300 px-3 py-1.5 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
            </div>
          </div>

          {/* Device Types */}
          <div className="lg:col-span-2">
            <label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
              Tipo de Dispositivo
            </label>
            <div className="flex flex-wrap gap-2">
              {deviceTypes.map((type) => (
                <label
                  key={type}
                  className="flex items-center gap-2 rounded-lg border border-gray-300 px-3 py-1.5 text-sm dark:border-gray-600"
                >
                  <input
                    type="checkbox"
                    checked={filters.deviceType.includes(type)}
                    onChange={() => handleDeviceTypeChange(type)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  {type}
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}