import React from 'react';
import { RepairOrder } from '../../types/repair';
import { Star, ThumbsUp, Clock, TrendingUp } from 'lucide-react';

interface CustomerSatisfactionProps {
  repairs: RepairOrder[];
}

export function CustomerSatisfaction({ repairs }: CustomerSatisfactionProps) {
  const completedRepairs = repairs.filter(r => r.status === 'completed');
  const totalRepairs = repairs.length;
  const onTimeDeliveries = completedRepairs.length;
  const satisfactionRate = totalRepairs > 0 ? (onTimeDeliveries / totalRepairs) * 100 : 0;

  const metrics = [
    {
      icon: Star,
      label: 'Satisfacción General',
      value: '4.8/5.0',
      trend: '+0.2',
      color: 'text-yellow-500',
    },
    {
      icon: ThumbsUp,
      label: 'Recomendaciones',
      value: '95%',
      trend: '+5%',
      color: 'text-blue-500',
    },
    {
      icon: Clock,
      label: 'Entregas a Tiempo',
      value: `${Math.round(satisfactionRate)}%`,
      trend: '+3%',
      color: 'text-green-500',
    },
    {
      icon: TrendingUp,
      label: 'Retención de Clientes',
      value: '88%',
      trend: '+2%',
      color: 'text-purple-500',
    },
  ];

  return (
    <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {metrics.map((metric) => (
        <div
          key={metric.label}
          className="rounded-lg border p-4 dark:border-gray-700"
        >
          <div className="flex items-center gap-3">
            <metric.icon className={`h-5 w-5 ${metric.color}`} />
            <span className="text-sm text-gray-500">{metric.label}</span>
          </div>
          <div className="mt-2 flex items-end justify-between">
            <span className="text-2xl font-semibold">{metric.value}</span>
            <span className="text-sm text-green-600 dark:text-green-400">
              {metric.trend}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}