import React from 'react';
import { Line } from 'react-chartjs-2';
import { RepairOrder } from '../../types/repair';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ComparisonChartProps {
  repairs: RepairOrder[];
  currentPeriod: { start: Date; end: Date };
  previousPeriod: { start: Date; end: Date };
}

export function ComparisonChart({ repairs, currentPeriod, previousPeriod }: ComparisonChartProps) {
  const generateData = (start: Date, end: Date) => {
    const days: string[] = [];
    const values: number[] = [];
    
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      days.push(d.toLocaleDateString());
      const dayRevenue = repairs
        .filter(repair => {
          const repairDate = new Date(repair.createdAt);
          return repairDate.toDateString() === d.toDateString();
        })
        .reduce((sum, repair) => sum + repair.estimatedCost, 0);
      values.push(dayRevenue);
    }

    return { days, values };
  };

  const currentData = generateData(currentPeriod.start, currentPeriod.end);
  const previousData = generateData(previousPeriod.start, previousPeriod.end);

  const data = {
    labels: currentData.days,
    datasets: [
      {
        label: 'Período Actual',
        data: currentData.values,
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
        fill: true,
      },
      {
        label: 'Período Anterior',
        data: previousData.values,
        borderColor: 'rgb(239, 68, 68)',
        backgroundColor: 'rgba(239, 68, 68, 0.1)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `$${context.raw.toFixed(2)}`,
        },
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          callback: function(tickValue: number | string) {
            return typeof tickValue === 'number' ? `$${tickValue}` : tickValue;
          },
        },
      },
      x: {
        type: 'category' as const,
        grid: {
          display: false,
        },
      },
    },
  } as const;

  return (
    <div className="w-full h-[300px]">
      <Line data={data} options={options} />
    </div>
  );
}