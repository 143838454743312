import React, { useEffect } from 'react';
import { useNotificationStore } from '../../store/notifications';

export function NotificationSound() {
  const notifications = useNotificationStore((state) => state.notifications);

  useEffect(() => {
    const audio = new Audio('/notification.mp3');
    const playSound = () => {
      audio.play().catch(() => {});
    };

    if (notifications.length > 0 && !notifications[0].read) {
      playSound();
    }
  }, [notifications]);

  return null;
}