import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';

export function useRoutePersistence() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  // Save current route to localStorage only if authenticated
  useEffect(() => {
    if (isAuthenticated && location.pathname !== '/') {
      localStorage.setItem('lastRoute', location.pathname + location.search + location.hash);
    }
  }, [location, isAuthenticated]);

  // Navigate to last route on mount if authenticated
  useEffect(() => {
    const lastRoute = localStorage.getItem('lastRoute');
    if (lastRoute && isAuthenticated && location.pathname === '/') {
      navigate(lastRoute, { replace: true });
    }
  }, [navigate, isAuthenticated]);
}