import React, { useState, useMemo } from 'react';
import { useRepairStore } from '../store/repairs';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isToday, isSameDay, addWeeks, subWeeks } from 'date-fns';
import { es } from 'date-fns/locale';
import { ChevronLeft, ChevronRight, Calendar, Clock, AlertTriangle } from 'lucide-react';
import { RepairOrder } from '../types/repair';
import { cn } from '../lib/utils';

const priorityColors = {
  low: 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-400',
  medium: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400',
  high: 'bg-orange-100 text-orange-800 dark:bg-orange-900/20 dark:text-orange-400',
  urgent: 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400',
};

export function RepairSchedulePage() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const repairs = useRepairStore((state) => state.repairs);
  const [selectedRepair, setSelectedRepair] = useState<RepairOrder | null>(null);

  const weekDays = useMemo(() => {
    const start = startOfWeek(currentDate, { locale: es });
    const end = endOfWeek(currentDate, { locale: es });
    return eachDayOfInterval({ start, end });
  }, [currentDate]);

  const scheduledRepairs = useMemo(() => {
    return repairs.filter(repair => repair.scheduledDate && repair.status !== 'delivered')
      .sort((a, b) => {
        if (!a.scheduledDate || !b.scheduledDate) return 0;
        return new Date(a.scheduledDate).getTime() - new Date(b.scheduledDate).getTime();
      });
  }, [repairs]);

  const repairsByDay = useMemo(() => {
    const grouped = new Map<string, RepairOrder[]>();
    weekDays.forEach(day => {
      grouped.set(format(day, 'yyyy-MM-dd'), []);
    });

    scheduledRepairs.forEach(repair => {
      if (!repair.scheduledDate) return;
      const date = format(new Date(repair.scheduledDate), 'yyyy-MM-dd');
      if (grouped.has(date)) {
        grouped.get(date)?.push(repair);
      }
    });

    return grouped;
  }, [weekDays, scheduledRepairs]);

  const handlePrevWeek = () => setCurrentDate(subWeeks(currentDate, 1));
  const handleNextWeek = () => setCurrentDate(addWeeks(currentDate, 1));
  const handleToday = () => setCurrentDate(new Date());

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Agenda de Reparaciones
          </h1>
          <div className="flex items-center gap-4">
            <button
              onClick={handleToday}
              className="flex items-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              <Calendar className="h-4 w-4" />
              Hoy
            </button>
            <div className="flex items-center gap-2 rounded-md border border-gray-300 dark:border-gray-600">
              <button
                onClick={handlePrevWeek}
                className="p-2 text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="px-2 text-sm font-medium">
                {format(weekDays[0], 'MMMM yyyy', { locale: es })}
              </span>
              <button
                onClick={handleNextWeek}
                className="p-2 text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-4">
        {weekDays.map((day) => (
          <div key={day.toString()}>
            <div className={cn(
              "mb-2 rounded-lg border p-2 text-center",
              isToday(day) ? "border-blue-500 bg-blue-50 dark:border-blue-500 dark:bg-blue-900/20" : "border-gray-200 dark:border-gray-700"
            )}>
              <div className="text-sm font-medium text-gray-900 dark:text-white">
                {format(day, 'EEEE', { locale: es })}
              </div>
              <div className="text-2xl font-bold text-gray-900 dark:text-white">
                {format(day, 'd')}
              </div>
            </div>

            <div className="space-y-2">
              {repairsByDay.get(format(day, 'yyyy-MM-dd'))?.map((repair) => (
                <button
                  key={repair.id}
                  onClick={() => setSelectedRepair(repair)}
                  className="w-full rounded-lg border border-gray-200 bg-white p-3 text-left shadow-sm transition-all hover:shadow-md dark:border-gray-700 dark:bg-gray-800"
                >
                  <div className="flex items-center justify-between">
                    <span className={cn(
                      "inline-flex rounded-full px-2 py-1 text-xs font-medium",
                      priorityColors[repair.priority]
                    )}>
                      {repair.priority === 'low' && 'Baja'}
                      {repair.priority === 'medium' && 'Media'}
                      {repair.priority === 'high' && 'Alta'}
                      {repair.priority === 'urgent' && 'Urgente'}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {repair.scheduledDate && format(new Date(repair.scheduledDate), 'HH:mm')}
                    </span>
                  </div>
                  <h3 className="mt-2 font-medium text-gray-900 dark:text-white">
                    {repair.customerName}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {repair.deviceBrand} {repair.deviceModel}
                  </p>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      {selectedRepair && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-bold">Detalles de la Reparación</h2>
              <button
                onClick={() => setSelectedRepair(null)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                ✕
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex items-center justify-between">
                  <h3 className="font-medium">{selectedRepair.customerName}</h3>
                  <span className={cn(
                    "inline-flex rounded-full px-2 py-1 text-xs font-medium",
                    priorityColors[selectedRepair.priority]
                  )}>
                    Prioridad: {selectedRepair.priority}
                  </span>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {selectedRepair.phone}
                </p>
              </div>

              <div className="rounded-lg border p-4 dark:border-gray-700">
                <div className="flex items-center gap-2">
                  <Clock className="h-5 w-5 text-gray-400" />
                  <div>
                    <p className="font-medium">Hora Programada</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {selectedRepair.scheduledDate && 
                        format(new Date(selectedRepair.scheduledDate), 'dd/MM/yyyy HH:mm')}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="font-medium">Dispositivo</h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {selectedRepair.deviceBrand} {selectedRepair.deviceModel}
                </p>
                {selectedRepair.imei && (
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    IMEI: {selectedRepair.imei}
                  </p>
                )}
              </div>

              <div>
                <h4 className="font-medium">Problema</h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {selectedRepair.issueDescription}
                </p>
              </div>

              {selectedRepair.technician && (
                <div>
                  <h4 className="font-medium">Técnico Asignado</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {selectedRepair.technician}
                  </p>
                </div>
              )}

              <div className="rounded-lg border border-yellow-200 bg-yellow-50 p-4 dark:border-yellow-900/50 dark:bg-yellow-900/20">
                <div className="flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5 text-yellow-600 dark:text-yellow-400" />
                  <p className="text-sm text-yellow-700 dark:text-yellow-300">
                    Tiempo estimado de entrega: {selectedRepair.scheduledDate && 
                      format(new Date(selectedRepair.scheduledDate), 'HH:mm')}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setSelectedRepair(null)}
                className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}