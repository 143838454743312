import React, { useState } from 'react';
import { useAuthStore } from '../../store/auth';
import { Bell, Moon, Sun, User, Menu, Search, Building2 } from 'lucide-react';
import { NotificationCenter } from '../notifications/NotificationCenter';
import { GlobalSearch } from './GlobalSearch';
import { Link } from 'react-router-dom';

export function Header() {
  const { user } = useAuthStore();
  const [isDark, setIsDark] = React.useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleTheme = () => {
    setIsDark(!isDark);
    document.documentElement.classList.toggle('dark');
  };

  return (
    <header className="border-b bg-white dark:bg-gray-900">
      <div className="mx-auto flex h-16 max-w-screen-2xl items-center gap-8 px-4 sm:px-6 lg:px-8">
        <button className="block text-gray-600 dark:text-gray-300 lg:hidden">
          <Menu className="h-6 w-6" />
        </button>

        <div className="flex flex-1 items-center justify-end gap-6">
          <Link
            to="/portal"
            className="flex items-center gap-2 rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-500 hover:border-gray-400 dark:border-gray-600 dark:text-gray-400 dark:hover:border-gray-500"
          >
            <Building2 className="h-4 w-4" />
            Portal Mayoristas
          </Link>

          <button
            onClick={() => setShowSearch(true)}
            className="flex items-center gap-2 rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-500 hover:border-gray-400 dark:border-gray-600 dark:text-gray-400 dark:hover:border-gray-500"
          >
            <Search className="h-4 w-4" />
            <span>Buscar...</span>
            <span className="ml-2 text-xs text-gray-400 dark:text-gray-500">⌘K</span>
          </button>

          <nav className="flex items-center gap-6">
            <button
              onClick={toggleTheme}
              className="block rounded-full p-2.5 text-gray-600 transition hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
            >
              {isDark ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>

            <NotificationCenter />

            <a
              href="#"
              className="block shrink-0 rounded-full bg-gray-100 p-2.5 text-gray-600 dark:bg-gray-800 dark:text-gray-300"
            >
              <User className="h-5 w-5" />
            </a>
          </nav>
        </div>
      </div>

      {showSearch && <GlobalSearch onClose={() => setShowSearch(false)} />}
    </header>
  );
}