import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { InventoryItem } from '../types/inventory';

interface InventoryState {
  items: InventoryItem[];
  addItem: (item: Omit<InventoryItem, 'id' | 'createdAt' | 'updatedAt'>) => void;
  updateItem: (id: string, updates: Partial<InventoryItem>) => void;
  removeItem: (id: string) => void;
  updateStock: (id: string, quantity: number) => void;
  getLowStockItems: () => InventoryItem[];
  setItems: (items: InventoryItem[]) => void;
}

export const useInventoryStore = create<InventoryState>()(
  persist(
    (set, get) => ({
      items: [],
      addItem: (item) => {
        const newItem: InventoryItem = {
          ...item,
          id: Math.random().toString(36).substring(7),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        set((state) => ({ items: [...state.items, newItem] }));
      },
      updateItem: (id, updates) => {
        set((state) => ({
          items: state.items.map((item) =>
            item.id === id
              ? { ...item, ...updates, updatedAt: new Date().toISOString() }
              : item
          ),
        }));
      },
      removeItem: (id) => {
        set((state) => ({
          items: state.items.filter((item) => item.id !== id),
        }));
      },
      updateStock: (id, quantity) => {
        set((state) => ({
          items: state.items.map((item) =>
            item.id === id
              ? {
                  ...item,
                  stock: item.stock + quantity,
                  updatedAt: new Date().toISOString(),
                }
              : item
          ),
        }));
      },
      getLowStockItems: () => {
        return get().items.filter((item) => item.stock <= item.minStock);
      },
      setItems: (items) => set({ items }),
    }),
    {
      name: 'inventory-storage',
    }
  )
);