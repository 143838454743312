import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { RepairOrder } from '../../types/repair';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RepairTimeChartProps {
  repairs: RepairOrder[];
  dateRange: string;
}

export function RepairTimeChart({ repairs, dateRange }: RepairTimeChartProps) {
  const calculateRepairTimes = () => {
    const times = repairs
      .filter(repair => repair.status === 'completed')
      .map(repair => {
        const start = new Date(repair.createdAt);
        const end = new Date(repair.updatedAt);
        return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
      });

    const ranges = {
      '0-2 días': 0,
      '2-4 días': 0,
      '4-7 días': 0,
      '7+ días': 0,
    };

    times.forEach(time => {
      if (time <= 2) ranges['0-2 días']++;
      else if (time <= 4) ranges['2-4 días']++;
      else if (time <= 7) ranges['4-7 días']++;
      else ranges['7+ días']++;
    });

    return Object.values(ranges);
  };

  const data = {
    labels: ['0-2 días', '2-4 días', '4-7 días', '7+ días'],
    datasets: [
      {
        label: 'Cantidad de Reparaciones',
        data: calculateRepairTimes(),
        backgroundColor: 'rgba(59, 130, 246, 0.8)',
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full h-[300px]">
      <Bar data={data} options={options} />
    </div>
  );
}