import React from 'react';

export function KeyboardShortcutsToast() {
  return (
    <div className="space-y-2">
      <p className="font-medium">Atajos de Teclado</p>
      <div className="text-sm space-y-1">
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">g d</kbd>
          <span>Dashboard</span>
        </div>
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">g r</kbd>
          <span>Reparaciones</span>
        </div>
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">g c</kbd>
          <span>Clientes</span>
        </div>
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">g i</kbd>
          <span>Inventario</span>
        </div>
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">g s</kbd>
          <span>Ventas</span>
        </div>
        <div className="flex items-center gap-2">
          <kbd className="px-2 py-1 text-xs font-mono bg-gray-100 border rounded dark:bg-gray-800 dark:border-gray-700">⌘K</kbd>
          <span>Búsqueda global</span>
        </div>
      </div>
    </div>
  );
}