import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    lng: 'es', // idioma predeterminado
    resources: {
      es: {
        translation: {
          // Navegación
          dashboard: 'Panel de Control',
          repairs: 'Reparaciones',
          customers: 'Clientes',
          inventory: 'Inventario',
          sales: 'Ventas',
          settings: 'Configuración',
          
          // Estados de reparación
          pending: 'Pendiente',
          diagnosed: 'Diagnosticado',
          in_repair: 'En Reparación',
          completed: 'Completado',
          delivered: 'Entregado',
          
          // Formularios
          save: 'Guardar',
          cancel: 'Cancelar',
          delete: 'Eliminar',
          edit: 'Editar',
          create: 'Crear',
          update: 'Actualizar',
          
          // Clientes
          new_customer: 'Nuevo Cliente',
          customer_details: 'Detalles del Cliente',
          customer_name: 'Nombre del Cliente',
          phone: 'Teléfono',
          email: 'Correo Electrónico',
          address: 'Dirección',
          
          // Reparaciones
          new_repair: 'Nueva Reparación',
          repair_details: 'Detalles de Reparación',
          device_type: 'Tipo de Dispositivo',
          brand: 'Marca',
          model: 'Modelo',
          serial_number: 'Número de Serie',
          problem: 'Problema',
          diagnosis: 'Diagnóstico',
          estimated_cost: 'Costo Estimado',
          final_cost: 'Costo Final',
          
          // Inventario
          new_item: 'Nuevo Artículo',
          item_details: 'Detalles del Artículo',
          item_name: 'Nombre del Artículo',
          description: 'Descripción',
          quantity: 'Cantidad',
          price: 'Precio',
          category: 'Categoría',
          supplier: 'Proveedor',
          
          // Mensajes
          confirm_delete: '¿Está seguro de que desea eliminar esto?',
          success_save: 'Guardado exitosamente',
          error_save: 'Error al guardar',
          success_delete: 'Eliminado exitosamente',
          error_delete: 'Error al eliminar',
          
          // Autenticación
          login: 'Iniciar Sesión',
          logout: 'Cerrar Sesión',
          email_placeholder: 'Ingrese su correo electrónico',
          password_placeholder: 'Ingrese su contraseña',
          forgot_password: '¿Olvidó su contraseña?',
          
          // Validaciones
          required_field: 'Este campo es requerido',
          invalid_email: 'Correo electrónico inválido',
          min_length: 'Debe tener al menos {{count}} caracteres',
          max_length: 'Debe tener máximo {{count}} caracteres',
          
          // Notificaciones
          notification_success: 'Operación exitosa',
          notification_error: 'Ha ocurrido un error',
          notification_warning: 'Advertencia',
          notification_info: 'Información',
        }
      },
      en: {
        translation: {
          // Navigation
          dashboard: 'Dashboard',
          repairs: 'Repairs',
          customers: 'Customers',
          inventory: 'Inventory',
          sales: 'Sales',
          settings: 'Settings',
          
          // Repair Status
          pending: 'Pending',
          diagnosed: 'Diagnosed',
          in_repair: 'In Repair',
          completed: 'Completed',
          delivered: 'Delivered',
          
          // Forms
          save: 'Save',
          cancel: 'Cancel',
          delete: 'Delete',
          edit: 'Edit',
          create: 'Create',
          update: 'Update',
          
          // Customers
          new_customer: 'New Customer',
          customer_details: 'Customer Details',
          customer_name: 'Customer Name',
          phone: 'Phone',
          email: 'Email',
          address: 'Address',
          
          // Repairs
          new_repair: 'New Repair',
          repair_details: 'Repair Details',
          device_type: 'Device Type',
          brand: 'Brand',
          model: 'Model',
          serial_number: 'Serial Number',
          problem: 'Problem',
          diagnosis: 'Diagnosis',
          estimated_cost: 'Estimated Cost',
          final_cost: 'Final Cost',
          
          // Inventory
          new_item: 'New Item',
          item_details: 'Item Details',
          item_name: 'Item Name',
          description: 'Description',
          quantity: 'Quantity',
          price: 'Price',
          category: 'Category',
          supplier: 'Supplier',
          
          // Messages
          confirm_delete: 'Are you sure you want to delete this?',
          success_save: 'Successfully saved',
          error_save: 'Error saving',
          success_delete: 'Successfully deleted',
          error_delete: 'Error deleting',
          
          // Authentication
          login: 'Login',
          logout: 'Logout',
          email_placeholder: 'Enter your email',
          password_placeholder: 'Enter your password',
          forgot_password: 'Forgot password?',
          
          // Validations
          required_field: 'This field is required',
          invalid_email: 'Invalid email',
          min_length: 'Must be at least {{count}} characters',
          max_length: 'Must be at most {{count}} characters',
          
          // Notifications
          notification_success: 'Operation successful',
          notification_error: 'An error has occurred',
          notification_warning: 'Warning',
          notification_info: 'Information',
        }
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
