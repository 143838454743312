import React from 'react';
import { Download, Upload, RefreshCw, Calendar } from 'lucide-react';

export function BackupSettings() {
  const backups = [
    { id: 1, date: '2024-02-20 10:30', size: '2.3 MB' },
    { id: 2, date: '2024-02-19 15:45', size: '2.1 MB' },
    { id: 3, date: '2024-02-18 09:15', size: '2.2 MB' },
  ];

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium">Copias de Seguridad</h2>
        <p className="text-sm text-gray-500">
          Gestiona las copias de seguridad de tus datos.
        </p>
      </div>

      <div className="flex flex-wrap gap-4">
        <button className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700">
          <Download className="h-4 w-4" />
          Crear Copia de Seguridad
        </button>
        <button className="flex items-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700">
          <Upload className="h-4 w-4" />
          Restaurar Copia
        </button>
        <button className="flex items-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700">
          <RefreshCw className="h-4 w-4" />
          Copias Automáticas
        </button>
      </div>

      <div className="rounded-lg border dark:border-gray-700">
        <div className="border-b px-4 py-3 dark:border-gray-700">
          <h3 className="text-sm font-medium">Historial de Copias de Seguridad</h3>
        </div>
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {backups.map((backup) => (
            <div
              key={backup.id}
              className="flex items-center justify-between px-4 py-3"
            >
              <div className="flex items-center gap-3">
                <Calendar className="h-5 w-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium">{backup.date}</p>
                  <p className="text-xs text-gray-500">{backup.size}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
                  Descargar
                </button>
                <button className="text-sm text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300">
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="rounded-lg border bg-gray-50 p-4 dark:border-gray-700 dark:bg-gray-800">
        <h3 className="mb-2 text-sm font-medium">Configuración de Copias Automáticas</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Frecuencia
            </label>
            <select className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800">
              <option value="daily">Diaria</option>
              <option value="weekly">Semanal</option>
              <option value="monthly">Mensual</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Retención
            </label>
            <select className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800">
              <option value="7">7 días</option>
              <option value="30">30 días</option>
              <option value="90">90 días</option>
            </select>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">
              Próxima copia: 21/02/2024 00:00
            </span>
            <button className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">
              Ejecutar Ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}