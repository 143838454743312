import React, { useState } from 'react';
import { Building2, Users, Bell, Palette, Database, Save, MessageSquare } from 'lucide-react';
import { CompanySettings } from '../components/settings/CompanySettings';
import { UserManagement } from '../components/settings/UserManagement';
import { NotificationSettings } from '../components/settings/NotificationSettings';
import { ThemeSettings } from '../components/settings/ThemeSettings';
import { BackupSettings } from '../components/settings/BackupSettings';
import { ChatbotSettings } from '../components/settings/ChatbotSettings';

const tabs = [
  { id: 'company', label: 'Empresa', icon: Building2 },
  { id: 'users', label: 'Usuarios', icon: Users },
  { id: 'notifications', label: 'Notificaciones', icon: Bell },
  { id: 'theme', label: 'Apariencia', icon: Palette },
  { id: 'chatbot', label: 'Chatbot', icon: MessageSquare },
  { id: 'backup', label: 'Copias de Seguridad', icon: Database },
];

export function SettingsPage() {
  const [activeTab, setActiveTab] = useState('company');
  const [hasChanges, setHasChanges] = useState(false);

  const handleSave = () => {
    // Implementar guardado de cambios
    setHasChanges(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Configuración
        </h1>
        {hasChanges && (
          <button
            onClick={handleSave}
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
          >
            <Save className="h-4 w-4" />
            Guardar Cambios
          </button>
        )}
      </div>

      <div className="flex flex-col gap-6 lg:flex-row">
        {/* Sidebar */}
        <div className="w-full lg:w-64">
          <nav className="space-y-1">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex w-full items-center gap-3 rounded-lg px-4 py-2 text-sm font-medium ${
                    activeTab === tab.id
                      ? 'bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400'
                      : 'text-gray-700 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800'
                  }`}
                >
                  <Icon className="h-5 w-5" />
                  {tab.label}
                </button>
              );
            })}
          </nav>
        </div>

        {/* Content */}
        <div className="flex-1">
          <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
            {activeTab === 'company' && <CompanySettings onUpdate={() => setHasChanges(true)} />}
            {activeTab === 'users' && <UserManagement onUpdate={() => setHasChanges(true)} />}
            {activeTab === 'notifications' && <NotificationSettings onUpdate={() => setHasChanges(true)} />}
            {activeTab === 'theme' && <ThemeSettings onUpdate={() => setHasChanges(true)} />}
            {activeTab === 'chatbot' && <ChatbotSettings onUpdate={() => setHasChanges(true)} />}
            {activeTab === 'backup' && <BackupSettings />}
          </div>
        </div>
      </div>
    </div>
  );
}