import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Position = 'left' | 'right';

interface ChatbotState {
  position: Position;
  autoSuggest: boolean;
  soundEnabled: boolean;
  setPosition: (position: Position) => void;
  setAutoSuggest: (enabled: boolean) => void;
  setSoundEnabled: (enabled: boolean) => void;
}

const initialState = {
  position: 'right' as const,
  autoSuggest: true,
  soundEnabled: true,
};

export const useChatbotStore = create<ChatbotState>()(
  persist(
    (set) => ({
      ...initialState,
      setPosition: (position) => set({ position }),
      setAutoSuggest: (autoSuggest) => set({ autoSuggest }),
      setSoundEnabled: (soundEnabled) => set({ soundEnabled }),
    }),
    {
      name: 'chatbot-storage',
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          // Migration from version 0 to 1
          return {
            ...initialState,
            ...persistedState,
          };
        }
        return persistedState;
      },
    }
  )
);