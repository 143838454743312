import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { RepairOrder, RepairStatus } from '../types/repair';

interface RepairState {
  repairs: RepairOrder[];
  addRepair: (repair: Omit<RepairOrder, 'id' | 'createdAt' | 'updatedAt' | 'statusHistory'>) => RepairOrder;
  updateRepair: (id: string, repair: Partial<RepairOrder>, note?: string) => void;
  getRepair: (id: string) => RepairOrder | undefined;
  setRepairs: (repairs: RepairOrder[]) => void;
}

export const useRepairStore = create<RepairState>()(
  persist(
    (set, get) => ({
      repairs: [],
      addRepair: (repair) => {
        const newRepair: RepairOrder = {
          ...repair,
          id: Math.random().toString(36).substring(7),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          statusHistory: [{
            id: Math.random().toString(36).substring(7),
            status: 'pending',
            timestamp: new Date().toISOString(),
            note: 'Orden creada'
          }]
        };
        set((state) => ({ repairs: [...state.repairs, newRepair] }));
        return newRepair;
      },
      updateRepair: (id, updates, note) => {
        set((state) => ({
          repairs: state.repairs.map((repair) => {
            if (repair.id !== id) return repair;

            const now = new Date().toISOString();
            const updatedRepair = { ...repair, ...updates, updatedAt: now };

            if (updates.status && updates.status !== repair.status) {
              updatedRepair.statusHistory = [
                ...repair.statusHistory,
                {
                  id: Math.random().toString(36).substring(7),
                  status: updates.status,
                  timestamp: now,
                  note,
                  technician: updates.technician
                }
              ];
            }

            return updatedRepair;
          })
        }));
      },
      getRepair: (id) => get().repairs.find((repair) => repair.id === id),
      setRepairs: (repairs) => set({ repairs }),
    }),
    {
      name: 'repairs-storage',
    }
  )
);