import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { KeyboardShortcutsToast } from '../components/keyboard/KeyboardShortcutsToast';

export function useKeyboardShortcuts() {
  const navigate = useNavigate();

  // Navegación rápida
  useHotkeys('g d', () => navigate('/dashboard'), { description: 'Ir al Dashboard' });
  useHotkeys('g r', () => navigate('/dashboard/repairs'), { description: 'Ir a Reparaciones' });
  useHotkeys('g c', () => navigate('/dashboard/customers'), { description: 'Ir a Clientes' });
  useHotkeys('g i', () => navigate('/dashboard/inventory'), { description: 'Ir a Inventario' });
  useHotkeys('g s', () => navigate('/dashboard/sales'), { description: 'Ir a Ventas' });

  // Acciones comunes
  useHotkeys('shift+?', () => {
    toast(<KeyboardShortcutsToast />, {
      duration: 5000,
      position: 'bottom-center',
      className: 'bg-gray-900 text-white dark:bg-white dark:text-gray-900',
    });
  }, { description: 'Mostrar ayuda' });

  return null;
}