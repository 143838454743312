import React, { useState, useCallback, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { Header } from './Header';

interface DashboardLayoutProps {
  children?: ReactNode;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const effectiveWidth = isHovering ? 'w-64' : (sidebarCollapsed ? 'w-20' : 'w-64');
  const effectiveMargin = isHovering ? 'ml-64' : (sidebarCollapsed ? 'ml-20' : 'ml-64');

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      {/* Sidebar Container */}
      <div 
        className={`fixed inset-y-0 z-50 transition-all duration-300 ${effectiveWidth}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Sidebar 
          isCollapsed={!isHovering && sidebarCollapsed} 
          onToggleCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
        />
      </div>

      {/* Main Content */}
      <div className={`flex-1 ${effectiveMargin} transition-all duration-300`}>
        <Header />
        <main className="p-4">
          {children}
        </main>
      </div>
    </div>
  );
}