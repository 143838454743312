import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { RepairOrder } from '../types/repair';
import { InventoryItem } from '../types/inventory';

export async function generateExcelReport(repairs: RepairOrder[], inventory: InventoryItem[], dateRange: string) {
  // Create workbook
  const wb = new ExcelJS.Workbook();
  wb.creator = 'Phone Repair Management';
  wb.lastModifiedBy = 'Phone Repair Management';
  wb.created = new Date();
  wb.modified = new Date();

  // Repairs worksheet
  const repairsWS = wb.addWorksheet('Reparaciones');
  repairsWS.columns = [
    { header: 'ID', key: 'id', width: 20 },
    { header: 'Cliente', key: 'customer', width: 30 },
    { header: 'Dispositivo', key: 'device', width: 30 },
    { header: 'Estado', key: 'status', width: 15 },
    { header: 'Costo', key: 'cost', width: 15 },
    { header: 'Fecha', key: 'date', width: 20 },
  ];

  // Add repairs data
  repairs.forEach(repair => {
    repairsWS.addRow({
      id: repair.id,
      customer: repair.customerName,
      device: `${repair.deviceBrand} ${repair.deviceModel}`,
      status: repair.status,
      cost: repair.estimatedCost,
      date: new Date(repair.createdAt).toLocaleDateString(),
    });
  });

  // Style the header row
  const headerRow = repairsWS.getRow(1);
  headerRow.font = { bold: true };
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE0E0E0' }
  };

  // Inventory worksheet
  const inventoryWS = wb.addWorksheet('Inventario');
  inventoryWS.columns = [
    { header: 'Nombre', key: 'name', width: 30 },
    { header: 'Categoría', key: 'category', width: 20 },
    { header: 'Stock', key: 'stock', width: 15 },
    { header: 'Precio', key: 'price', width: 15 },
    { header: 'Valor Total', key: 'totalValue', width: 20 },
  ];

  // Add inventory data
  inventory.forEach(item => {
    inventoryWS.addRow({
      name: item.name,
      category: item.category,
      stock: item.stock,
      price: item.price,
      totalValue: item.stock * item.price,
    });
  });

  // Style the header row
  const inventoryHeaderRow = inventoryWS.getRow(1);
  inventoryHeaderRow.font = { bold: true };
  inventoryHeaderRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE0E0E0' }
  };

  // Generate Excel file
  const buffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
  // Save file with proper date formatting
  const currentDate = new Date().toISOString().split('T')[0];
  const fileName = `reporte_${dateRange}_${currentDate}.xlsx`;
  saveAs(blob, fileName);
}