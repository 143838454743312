import React, { useState } from 'react';
import { Sale } from '../../types/sale';
import { useSettingsStore } from '../../store/settings';
import { Printer, Share2, Download } from 'lucide-react';
import { printSaleReceipt } from '../../utils/print';

interface SaleReceiptProps {
  sale: Sale;
  onClose: () => void;
}

export function SaleReceipt({ sale, onClose }: SaleReceiptProps) {
  const company = useSettingsStore((state) => state.company);
  const [printOptions, setPrintOptions] = useState({
    includeLogo: true,
    includeBarcode: false,
    includeFooter: true,
    copies: 1,
  });

  const handlePrint = () => {
    try {
      printSaleReceipt(sale, company, printOptions);
    } catch (error) {
      console.error('Error al imprimir:', error);
      alert('Hubo un error al intentar imprimir. Por favor, inténtalo de nuevo.');
    }
  };

  const handleDownload = () => {
    try {
      const receiptText = `
        Ticket de Venta #${sale.id}
        ${company.name}
        
        Cliente: ${sale.customerName || 'Cliente General'}
        Total: $${sale.total}
        
        Productos:
        ${sale.items.map(item => `${item.name} x${item.quantity} - $${item.total}`).join('\n')}
      `;

      const blob = new Blob([receiptText], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `ticket-${sale.id}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error al descargar:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
        <div className="mb-6 text-center">
          <h2 className="text-xl font-bold">Ticket de Venta</h2>
          <p className="text-sm text-gray-500">Venta #{sale.id}</p>
        </div>

        <div className="mb-6 space-y-4">
          <div className="rounded-lg border p-4 dark:border-gray-700">
            <h3 className="mb-2 font-medium">Opciones de Impresión</h3>
            <div className="space-y-3">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={printOptions.includeLogo}
                  onChange={(e) => setPrintOptions({
                    ...printOptions,
                    includeLogo: e.target.checked
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm">Incluir Logo</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={printOptions.includeBarcode}
                  onChange={(e) => setPrintOptions({
                    ...printOptions,
                    includeBarcode: e.target.checked
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm">Incluir Código de Barras</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={printOptions.includeFooter}
                  onChange={(e) => setPrintOptions({
                    ...printOptions,
                    includeFooter: e.target.checked
                  })}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm">Incluir Pie de Página</span>
              </label>
              <div className="flex items-center gap-2">
                <span className="text-sm">Copias:</span>
                <input
                  type="number"
                  min="1"
                  max="5"
                  value={printOptions.copies}
                  onChange={(e) => setPrintOptions({
                    ...printOptions,
                    copies: parseInt(e.target.value) || 1
                  })}
                  className="w-16 rounded-md border border-gray-300 px-2 py-1 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>
            </div>
          </div>

          <div className="rounded-lg border p-4 dark:border-gray-700">
            <h3 className="mb-2 font-medium">Resumen de la Venta</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-gray-600 dark:text-gray-400">Subtotal:</span>
                <span className="font-medium">${sale.total.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-gray-600 dark:text-gray-400">Total:</span>
                <span className="text-lg font-bold">${sale.total.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
          >
            Cerrar
          </button>
          <button
            onClick={handleDownload}
            className="flex items-center gap-2 rounded-md border border-blue-600 px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 dark:border-blue-500 dark:text-blue-400 dark:hover:bg-blue-900/50"
          >
            <Download className="h-4 w-4" />
            Descargar
          </button>
          <button
            onClick={handlePrint}
            className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
          >
            <Printer className="h-4 w-4" />
            Imprimir
          </button>
        </div>
      </div>
    </div>
  );
}