import React from 'react';
import { Bar } from 'react-chartjs-2';
import { RepairOrder } from '../../types/repair';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface TechnicianWorkloadProps {
  repairs: RepairOrder[];
}

export function TechnicianWorkload({ repairs }: TechnicianWorkloadProps) {
  const activeRepairs = repairs.filter(r => r.status === 'in_progress');
  const workload = new Map<string, number>();

  activeRepairs.forEach(repair => {
    if (repair.technician) {
      workload.set(
        repair.technician,
        (workload.get(repair.technician) || 0) + 1
      );
    }
  });

  const data = {
    labels: Array.from(workload.keys()),
    datasets: [
      {
        label: 'Reparaciones Activas',
        data: Array.from(workload.values()),
        backgroundColor: 'rgba(59, 130, 246, 0.8)',
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="h-[300px]">
      <Bar data={data} options={options} />
    </div>
  );
}