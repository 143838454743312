import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Home, Smartphone, Users, Box, BarChart3, Settings, LogOut, 
  DollarSign, Building2, Calendar, ChevronLeft, ChevronRight 
} from 'lucide-react';
import { useAuthStore } from '../../store/auth';
import { useSettingsStore } from '../../store/settings';
import { cn } from '../../lib/utils';

interface SidebarProps {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
}

export function Sidebar({ isCollapsed, onToggleCollapse }: SidebarProps) {
  const location = useLocation();
  const { logout } = useAuthStore();
  const { company } = useSettingsStore();

  const menuItems = [
    { icon: Home, label: 'Dashboard', path: '/dashboard' },
    { icon: Smartphone, label: 'Reparaciones', path: '/dashboard/repairs' },
    { icon: Calendar, label: 'Agenda', path: '/dashboard/schedule' },
    { icon: Users, label: 'Clientes', path: '/dashboard/customers' },
    { icon: Box, label: 'Inventario', path: '/dashboard/inventory' },
    { icon: DollarSign, label: 'Ventas', path: '/dashboard/sales' },
    { icon: Building2, label: 'Mayoristas', path: '/dashboard/wholesale' },
    { icon: BarChart3, label: 'Reportes', path: '/dashboard/reports' },
    { icon: Settings, label: 'Configuración', path: '/dashboard/settings' },
  ];

  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  return (
    <div className="flex h-full flex-col justify-between border-r bg-white shadow-sm transition-all duration-300 dark:border-gray-700 dark:bg-gray-900">
      <div className="flex flex-col px-4 py-6">
        <div className="mb-8 flex items-center justify-between">
          <Link 
            to="/dashboard" 
            className={cn(
              "relative flex items-center gap-3 overflow-hidden transition-all duration-300",
              isCollapsed ? "justify-center" : "px-2"
            )}
          >
            <div className={cn(
              "group relative flex shrink-0 items-center justify-center overflow-hidden rounded-xl transition-all duration-300",
              "bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-900/30 dark:to-blue-800/30",
              "shadow-lg ring-2 ring-blue-100 dark:ring-blue-900/50",
              "hover:ring-4 hover:ring-blue-200 dark:hover:ring-blue-800",
              isCollapsed ? "h-10 w-10" : "h-12 w-12"
            )}>
              {company.logo ? (
                <>
                  <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-transparent opacity-0 transition-opacity group-hover:opacity-100" />
                  <img
                    src={company.logo}
                    alt={company.name}
                    className={cn(
                      "h-full w-full object-contain p-2 transition-transform duration-300",
                      "group-hover:scale-110"
                    )}
                  />
                </>
              ) : (
                <Smartphone className={cn(
                  "text-blue-600 transition-all duration-300 dark:text-blue-400",
                  "group-hover:scale-110",
                  isCollapsed ? "h-6 w-6" : "h-7 w-7"
                )} />
              )}
            </div>
            {!isCollapsed && (
              <div className="min-w-0 flex-1">
                <h1 className="truncate text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  {company.name || 'PhoneRepair'}
                </h1>
                {company.contact?.address && (
                  <p className="truncate text-xs text-gray-500 dark:text-gray-400">
                    {company.contact.address}
                  </p>
                )}
              </div>
            )}
          </Link>
          <button
            onClick={onToggleCollapse}
            className="rounded-lg p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            {isCollapsed ? (
              <ChevronRight className="h-5 w-5" />
            ) : (
              <ChevronLeft className="h-5 w-5" />
            )}
          </button>
        </div>

        <nav className="flex-1">
          <ul className="space-y-1">
            {menuItems.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={cn(
                      "flex items-center gap-3 rounded-lg px-4 py-2.5 text-sm font-medium transition-all duration-200",
                      isCollapsed && "justify-center",
                      isActive 
                        ? "bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400" 
                        : "text-gray-700 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800"
                    )}
                    title={isCollapsed ? item.label : undefined}
                  >
                    <item.icon className={cn(
                      "h-5 w-5 flex-shrink-0 transition-transform",
                      isActive && "text-current"
                    )} />
                    {!isCollapsed && <span>{item.label}</span>}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>

      <div className="border-t p-4 dark:border-gray-700">
        <button
          onClick={handleLogout}
          className={cn(
            "flex w-full items-center gap-3 rounded-lg px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800",
            isCollapsed && "justify-center"
          )}
          title={isCollapsed ? "Cerrar Sesión" : undefined}
        >
          <LogOut className="h-5 w-5" />
          {!isCollapsed && <span>Cerrar Sesión</span>}
        </button>
      </div>
    </div>
  );
}