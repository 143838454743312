import React, { useState } from 'react';
import { useRepairStore } from '../store/repairs';
import { useInventoryStore } from '../store/inventory';
import { useCustomerStore } from '../store/customers';
import { 
  Smartphone, Users, Wrench, DollarSign, TrendingUp, Clock, 
  Package, AlertTriangle, Calendar, ArrowRight, Activity,
  ChevronRight, BarChart2, PieChart, LineChart
} from 'lucide-react';
import { StatCard } from '../components/dashboard/StatCard';
import { RepairStatusChart } from '../components/dashboard/RepairStatusChart';
import { RevenueChart } from '../components/reports/RevenueChart';
import { RepairTimeChart } from '../components/reports/RepairTimeChart';
import { RecentRepairs } from '../components/dashboard/RecentRepairs';
import { LowStockAlert } from '../components/dashboard/LowStockAlert';
import { TechnicianWorkload } from '../components/dashboard/TechnicianWorkload';
import { CustomerSatisfaction } from '../components/dashboard/CustomerSatisfaction';
import { cn } from '../lib/utils';

const timeRanges = [
  { id: 'today', label: 'Hoy' },
  { id: 'week', label: 'Esta Semana' },
  { id: 'month', label: 'Este Mes' },
  { id: 'year', label: 'Este Año' },
];

export function Dashboard() {
  const [timeRange, setTimeRange] = useState('week');
  const repairs = useRepairStore((state) => state.repairs);
  const inventory = useInventoryStore((state) => state.items);
  const customers = useCustomerStore((state) => state.customers);

  const calculateMetrics = () => {
    const activeRepairs = repairs.filter(r => r.status !== 'delivered').length;
    const newCustomers = customers.filter(c => {
      const createdAt = new Date(c.createdAt);
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      return createdAt >= thirtyDaysAgo;
    }).length;

    const completedRepairs = repairs.filter(r => r.status === 'completed');
    let avgRepairTime = 0;
    if (completedRepairs.length > 0) {
      const totalTime = completedRepairs.reduce((sum, repair) => {
        const start = new Date(repair.createdAt);
        const end = new Date(repair.updatedAt);
        return sum + (end.getTime() - start.getTime());
      }, 0);
      avgRepairTime = Math.round(totalTime / (completedRepairs.length * 1000 * 60 * 60 * 24));
    }

    const dailyRevenue = repairs
      .filter(r => {
        const today = new Date();
        const repairDate = new Date(r.createdAt);
        return repairDate.toDateString() === today.toDateString();
      })
      .reduce((sum, repair) => sum + repair.estimatedCost, 0);

    return { activeRepairs, newCustomers, avgRepairTime, dailyRevenue };
  };

  const metrics = calculateMetrics();

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              Panel de Control
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              Bienvenido de nuevo. Aquí está el resumen de tu negocio.
            </p>
          </div>
          <div className="flex items-center gap-2 rounded-lg border bg-white p-1 dark:border-gray-700 dark:bg-gray-800">
            {timeRanges.map((range) => (
              <button
                key={range.id}
                onClick={() => setTimeRange(range.id)}
                className={cn(
                  "rounded-md px-3 py-1.5 text-sm font-medium transition-colors",
                  timeRange === range.id
                    ? "bg-blue-100 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400"
                    : "text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                )}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>

        {/* Stats Grid */}
        <div className="mb-8 grid gap-6 lg:grid-cols-4">
          <StatCard
            title="Reparaciones Activas"
            value={metrics.activeRepairs}
            icon={Smartphone}
            trend={{ value: 12, isPositive: true }}
            chart={<Activity className="h-8 w-8 text-blue-600/25" />}
          />
          <StatCard
            title="Clientes Nuevos"
            value={metrics.newCustomers}
            icon={Users}
            trend={{ value: 8, isPositive: true }}
            chart={<LineChart className="h-8 w-8 text-green-600/25" />}
          />
          <StatCard
            title="Tiempo Promedio"
            value={`${metrics.avgRepairTime} días`}
            icon={Clock}
            trend={{ value: 5, isPositive: false }}
            chart={<BarChart2 className="h-8 w-8 text-yellow-600/25" />}
          />
          <StatCard
            title="Ingresos del Día"
            value={`$${metrics.dailyRevenue.toFixed(2)}`}
            icon={DollarSign}
            trend={{ value: 15, isPositive: true }}
            chart={<PieChart className="h-8 w-8 text-purple-600/25" />}
          />
        </div>

        {/* Main Content */}
        <div className="grid gap-6 lg:grid-cols-6">
          {/* Charts Section */}
          <div className="space-y-6 lg:col-span-4">
            {/* Revenue Chart */}
            <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
              <div className="mb-4 flex items-center justify-between">
                <div>
                  <h3 className="font-semibold">Ingresos</h3>
                  <p className="text-sm text-gray-500">Análisis de ingresos por período</p>
                </div>
                <button className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400">
                  Ver Detalles
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
              <RevenueChart repairs={repairs} dateRange={timeRange} />
            </div>

            {/* Repair Status and Time Charts */}
            <div className="grid gap-6 md:grid-cols-2">
              <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
                <h3 className="mb-4 font-semibold">Estado de Reparaciones</h3>
                <RepairStatusChart repairs={repairs} />
              </div>
              <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
                <h3 className="mb-4 font-semibold">Tiempos de Reparación</h3>
                <RepairTimeChart repairs={repairs} dateRange={timeRange} />
              </div>
            </div>

            {/* Technician Performance */}
            <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
              <div className="mb-4 flex items-center justify-between">
                <div>
                  <h3 className="font-semibold">Rendimiento por Técnico</h3>
                  <p className="text-sm text-gray-500">Productividad y eficiencia</p>
                </div>
                <button className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400">
                  Ver Todos
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
              <TechnicianWorkload repairs={repairs} />
            </div>
          </div>

          {/* Sidebar */}
          <div className="space-y-6 lg:col-span-2">
            {/* Recent Activity */}
            <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="font-semibold">Actividad Reciente</h3>
                <button className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400">
                  Ver Todo
                </button>
              </div>
              <RecentRepairs repairs={repairs} />
            </div>

            {/* Low Stock Alerts */}
            <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="font-semibold">Alertas de Inventario</h3>
                <button className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400">
                  Gestionar
                </button>
              </div>
              <LowStockAlert inventory={inventory} />
            </div>

            {/* Customer Satisfaction */}
            <div className="rounded-xl border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="font-semibold">Satisfacción del Cliente</h3>
                <button className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400">
                  Ver Detalles
                </button>
              </div>
              <CustomerSatisfaction repairs={repairs} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}