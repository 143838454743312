import OpenAI from 'openai';
//import axios from 'axios';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function sendMessage(message: string) {
  try {
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: 'system',
          content: 'Eres un asistente experto en reparación de teléfonos móviles. Proporciona respuestas concisas y técnicamente precisas.'
        },
        { 
          role: 'user', 
          content: message 
        }
      ],
      model: 'gpt-4o-mini',
      temperature: 0.7,
      max_tokens: 500,
    });

    return completion.choices[0].message.content;
  } catch (error) {
    console.error('Error al enviar mensaje:', error);
    throw new Error('No se pudo procesar tu mensaje. Por favor, intenta de nuevo.');
  }
}

export async function analyzePhoneImage(file: File) {
  try {
    // Convertir imagen a base64
    const base64Image = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64Data = reader.result.split(',')[1];
          resolve(base64Data);
        } else {
          reject(new Error('No se pudo convertir la imagen'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

    // Usar la API de Vision de OpenAI
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "user",
          content: [
            { 
              type: "text", 
              text: "Analiza esta imagen y dime qué modelo de teléfono es. Proporciona detalles sobre sus características principales y cualquier detalle relevante que puedas observar sobre su estado. Responde en español." 
            },
            {
              type: "image_url",
              image_url: {
                url: `data:image/jpeg;base64,${base64Image}`,
              },
            },
          ],
        },
      ],
      max_tokens: 500,
      temperature: 0.7,
    });

    if (!response.choices[0]?.message?.content) {
      throw new Error('No se recibió respuesta del análisis');
    }

    return response.choices[0].message.content;
  } catch (error: any) {
    console.error('Error al analizar la imagen:', error);
    
    // Manejar errores específicos de la API
    if (error?.error?.type === 'invalid_request_error') {
      throw new Error('Error de acceso a la API de visión. Por favor, verifica tu clave API.');
    }
    
    if (error?.error?.message) {
      throw new Error(`Error al analizar la imagen: ${error.error.message}`);
    }

    throw new Error('Error al procesar la imagen. Por favor, intenta con otra imagen o más tarde.');
  }
}

// Función auxiliar para comprimir imágenes antes de enviarlas
async function compressImage(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('No se pudo crear el contexto del canvas'));
        return;
      }

      // Calcular nuevas dimensiones manteniendo el aspect ratio
      let width = img.width;
      let height = img.height;
      const maxSize = 800;

      if (width > height && width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }

      canvas.width = width;
      canvas.height = height;

      // Dibujar imagen redimensionada
      ctx.drawImage(img, 0, 0, width, height);

      // Convertir a blob con calidad reducida
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('No se pudo comprimir la imagen'));
          }
        },
        'image/jpeg',
        0.8
      );
    };
    img.onerror = () => reject(new Error('Error al cargar la imagen'));
  });
}