import React from 'react';
import { Calendar } from 'lucide-react';

interface DateRangeSelectorProps {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  preset: string;
  onPresetChange: (preset: string) => void;
}

export function DateRangeSelector({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  preset,
  onPresetChange,
}: DateRangeSelectorProps) {
  return (
    <div className="flex items-center gap-4">
      <select
        value={preset}
        onChange={(e) => onPresetChange(e.target.value)}
        className="rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
      >
        <option value="today">Hoy</option>
        <option value="week">Esta Semana</option>
        <option value="month">Este Mes</option>
        <option value="quarter">Este Trimestre</option>
        <option value="year">Este Año</option>
        <option value="custom">Personalizado</option>
      </select>

      {preset === 'custom' && (
        <div className="flex items-center gap-2">
          <div className="relative">
            <Calendar className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
            <input
              type="date"
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => onStartDateChange(new Date(e.target.value))}
              className="rounded-md border border-gray-300 pl-10 pr-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
          <span className="text-gray-500">hasta</span>
          <div className="relative">
            <Calendar className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
            <input
              type="date"
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => onEndDateChange(new Date(e.target.value))}
              className="rounded-md border border-gray-300 pl-10 pr-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
        </div>
      )}
    </div>
  );
}