import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Plus, X, Home, Smartphone, Users, Box, 
  DollarSign, MessageSquare, Settings, Building2, Calendar 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Chatbot } from './Chatbot';
import { cn } from '../../lib/utils';

export default function FloatingActions() {
  const [isOpen, setIsOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [hoveredAction, setHoveredAction] = useState<string | null>(null);
  const navigate = useNavigate();

  const actions = [
    { icon: Home, label: 'Dashboard', path: '/dashboard' },
    { icon: Smartphone, label: 'Reparaciones', path: '/dashboard/repairs' },
    { icon: Calendar, label: 'Agenda', path: '/dashboard/schedule' },
    { icon: Users, label: 'Clientes', path: '/dashboard/customers' },
    { icon: Box, label: 'Inventario', path: '/dashboard/inventory' },
    { icon: DollarSign, label: 'Ventas', path: '/dashboard/sales' },
    { icon: Building2, label: 'Mayoristas', path: '/dashboard/wholesale' },
    { icon: Settings, label: 'Configuración', path: '/dashboard/settings' },
    { icon: MessageSquare, label: 'Chat', onClick: () => setShowChat(true) },
  ];

  const renderActionButton = (action: typeof actions[0], index: number) => {
    const Icon = action.icon;
    return (
      <div key={action.label} className="relative">
        <motion.button
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.05 }}
          onClick={() => {
            if (action.path) {
              navigate(action.path);
            } else if (action.onClick) {
              action.onClick();
            }
            setIsOpen(false);
          }}
          onMouseEnter={() => setHoveredAction(action.label)}
          onMouseLeave={() => setHoveredAction(null)}
          className="mx-2 flex h-10 w-10 items-center justify-center rounded-full bg-white/90 text-gray-700 shadow-lg transition-all hover:bg-blue-50 hover:text-blue-600 dark:bg-gray-800/90 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-blue-400"
        >
          <Icon className="h-5 w-5" />
        </motion.button>
        <AnimatePresence>
          {hoveredAction === action.label && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute -left-2 -top-10 rounded-md bg-gray-900 px-2 py-1 text-xs text-white dark:bg-white dark:text-gray-900"
            >
              {action.label}
              <div className="absolute -bottom-1 left-4 h-2 w-2 rotate-45 bg-gray-900 dark:bg-white" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <>
      <div className="fixed bottom-6 right-6 z-40">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute bottom-1 right-16"
            >
              <div className="relative flex h-14 items-center">
                {actions.map((action, index) => renderActionButton(action, index))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsOpen(!isOpen)}
          className={cn(
            "relative z-50 flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg transition-all duration-300",
            "hover:shadow-xl hover:shadow-blue-500/25 dark:hover:shadow-white/10",
            isOpen && "rotate-45"
          )}
        >
          {isOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Plus className="h-6 w-6" />
          )}
        </motion.button>
      </div>

      <AnimatePresence>
        {showChat && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Chatbot onClose={() => setShowChat(false)} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}