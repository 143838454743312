import React from 'react';
import { Sun, Moon, Monitor, Palette, Layout, Type, Check } from 'lucide-react';
import { cn } from '../../lib/utils';
import { useThemeStore } from '../../store/theme';

interface ThemeSettingsProps {
  onUpdate: () => void;
}

const themes = [
  { id: 'light', name: 'Claro', icon: Sun },
  { id: 'dark', name: 'Oscuro', icon: Moon },
  { id: 'system', name: 'Sistema', icon: Monitor },
] as const;

const colors = [
  { id: 'blue', name: 'Azul', class: 'bg-blue-500' },
  { id: 'green', name: 'Verde', class: 'bg-green-500' },
  { id: 'purple', name: 'Púrpura', class: 'bg-purple-500' },
  { id: 'red', name: 'Rojo', class: 'bg-red-500' },
  { id: 'orange', name: 'Naranja', class: 'bg-orange-500' },
  { id: 'pink', name: 'Rosa', class: 'bg-pink-500' },
  { id: 'teal', name: 'Turquesa', class: 'bg-teal-500' },
  { id: 'indigo', name: 'Índigo', class: 'bg-indigo-500' },
] as const;

const densities = [
  { id: 'comfortable', name: 'Cómoda' },
  { id: 'compact', name: 'Compacta' },
  { id: 'spacious', name: 'Espaciosa' },
] as const;

const fontSizes = ['small', 'medium', 'large'] as const;

export function ThemeSettings({ onUpdate }: ThemeSettingsProps) {
  const { 
    theme, 
    color, 
    density, 
    fontSize,
    setTheme,
    setColor,
    setDensity,
    setFontSize
  } = useThemeStore();

  const handleThemeChange = (newTheme: typeof theme) => {
    setTheme(newTheme);
    onUpdate();
  };

  const handleColorChange = (newColor: string) => {
    setColor(newColor);
    onUpdate();
  };

  const handleDensityChange = (newDensity: typeof density) => {
    setDensity(newDensity);
    onUpdate();
  };

  const handleFontSizeChange = (index: number) => {
    const sizes = fontSizes;
    setFontSize(sizes[index]);
    onUpdate();
  };

  return (
    <div className="space-y-8">
      {/* Tema */}
      <section>
        <h3 className="mb-4 text-lg font-medium">Tema</h3>
        <div className="grid gap-4 sm:grid-cols-3">
          {themes.map((themeOption) => {
            const Icon = themeOption.icon;
            return (
              <button
                key={themeOption.id}
                onClick={() => handleThemeChange(themeOption.id)}
                className={cn(
                  "flex flex-col items-center gap-3 rounded-lg border p-4 transition-all hover:border-blue-500",
                  theme === themeOption.id
                    ? "border-blue-500 bg-blue-50 dark:border-blue-400 dark:bg-blue-900/20"
                    : "border-gray-200 dark:border-gray-700"
                )}
              >
                <div className={cn(
                  "rounded-full p-3",
                  theme === themeOption.id
                    ? "bg-blue-100 text-blue-600 dark:bg-blue-900/40 dark:text-blue-400"
                    : "bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400"
                )}>
                  <Icon className="h-6 w-6" />
                </div>
                <span className="text-sm font-medium">{themeOption.name}</span>
              </button>
            );
          })}
        </div>
      </section>

      {/* Color Principal */}
      <section>
        <h3 className="mb-4 text-lg font-medium">Color Principal</h3>
        <div className="grid grid-cols-4 gap-3 sm:grid-cols-8">
          {colors.map((colorOption) => (
            <button
              key={colorOption.id}
              onClick={() => handleColorChange(colorOption.id)}
              className="group relative aspect-square"
            >
              <div className={cn(
                "absolute inset-0 rounded-lg transition-transform group-hover:scale-105",
                colorOption.class
              )} />
              {color === colorOption.id && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Check className="h-5 w-5 text-white" />
                </div>
              )}
              <span className="sr-only">{colorOption.name}</span>
            </button>
          ))}
        </div>
      </section>

      {/* Densidad de la Interfaz */}
      <section>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-medium">Densidad de la Interfaz</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Ajusta el espaciado entre elementos
            </p>
          </div>
          <Layout className="h-5 w-5 text-gray-400" />
        </div>
        <div className="mt-4 grid gap-3 sm:grid-cols-3">
          {densities.map((densityOption) => (
            <button
              key={densityOption.id}
              onClick={() => handleDensityChange(densityOption.id)}
              className={cn(
                "rounded-lg border px-4 py-3 text-sm transition-colors",
                density === densityOption.id
                  ? "border-blue-500 bg-blue-50 text-blue-700 dark:border-blue-400 dark:bg-blue-900/20 dark:text-blue-400"
                  : "border-gray-200 hover:border-blue-500 dark:border-gray-700"
              )}
            >
              {densityOption.name}
            </button>
          ))}
        </div>
      </section>

      {/* Tamaño de Fuente */}
      <section>
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-medium">Tamaño de Fuente</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Ajusta el tamaño del texto
            </p>
          </div>
          <Type className="h-5 w-5 text-gray-400" />
        </div>
        <div className="mt-4">
          <input
            type="range"
            min="0"
            max="2"
            step="1"
            value={fontSizes.indexOf(fontSize)}
            onChange={(e) => handleFontSizeChange(Number(e.target.value))}
            className="w-full"
          />
          <div className="mt-2 flex justify-between text-sm">
            <span>Pequeño</span>
            <span>Mediano</span>
            <span>Grande</span>
          </div>
        </div>
      </section>

      {/* Vista Previa */}
      <section className="rounded-lg border p-6 dark:border-gray-700">
        <h3 className="mb-4 text-lg font-medium">Vista Previa</h3>
        <div className={cn(
          "space-y-4 rounded-lg border p-4 dark:border-gray-700",
          density === 'compact' && "space-y-2",
          density === 'spacious' && "space-y-6"
        )}>
          <div className={cn(
            "rounded-lg bg-gray-100 p-4 dark:bg-gray-800",
            fontSize === 'small' && "text-sm",
            fontSize === 'large' && "text-lg"
          )}>
            <h4 className="font-medium">Título de Ejemplo</h4>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Este es un texto de ejemplo para visualizar los cambios en el tamaño y espaciado.
            </p>
          </div>
          <div className="flex gap-2">
            <button className={cn(
              "rounded-lg px-4 py-2 text-white",
              `bg-${color}-500`
            )}>
              Botón Principal
            </button>
            <button className="rounded-lg border px-4 py-2 dark:border-gray-700">
              Botón Secundario
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}