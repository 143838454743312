import React from 'react';
import { Sale } from '../../types/sale';
import { format } from 'date-fns';
import { DollarSign, CreditCard, Banknote } from 'lucide-react';

interface SalesListProps {
  sales: Sale[];
}

const paymentIcons = {
  cash: Banknote,
  card: CreditCard,
  transfer: DollarSign,
};

export function SalesList({ sales }: SalesListProps) {
  if (sales.length === 0) {
    return (
      <div className="rounded-lg border border-gray-200 bg-white p-8 text-center dark:border-gray-700 dark:bg-gray-900">
        <p className="text-gray-500 dark:text-gray-400">
          No se encontraron ventas para el período seleccionado
        </p>
      </div>
    );
  }

  return (
    <div className="overflow-hidden rounded-lg border dark:border-gray-700">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
              Fecha
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
              Cliente
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
              Items
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
              Método de Pago
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
          {sales.map((sale) => {
            const PaymentIcon = paymentIcons[sale.paymentMethod];
            return (
              <tr key={sale.id}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900 dark:text-gray-200">
                  {format(new Date(sale.createdAt), 'dd/MM/yyyy HH:mm')}
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                    {sale.customerName || 'Cliente General'}
                  </div>
                  {sale.customerPhone && (
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {sale.customerPhone}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900 dark:text-gray-200">
                    {sale.items.length} productos
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {sale.items.map((item) => item.name).join(', ')}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <div className="flex items-center gap-2">
                    <PaymentIcon className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-900 dark:text-gray-200">
                      {sale.paymentMethod === 'cash' && 'Efectivo'}
                      {sale.paymentMethod === 'card' && 'Tarjeta'}
                      {sale.paymentMethod === 'transfer' && 'Transferencia'}
                    </span>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200">
                  ${sale.total}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}