import React from 'react';
import { Bell, Mail, Phone } from 'lucide-react';

interface NotificationSettingsProps {
  onUpdate: () => void;
}

export function NotificationSettings({ onUpdate }: NotificationSettingsProps) {
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium">Configuración de Notificaciones</h2>
        <p className="text-sm text-gray-500">
          Personaliza cómo y cuándo recibes notificaciones.
        </p>
      </div>

      <div className="space-y-4">
        <div className="rounded-lg border p-4 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Bell className="h-5 w-5 text-gray-400" />
              <div>
                <p className="font-medium">Notificaciones del Sistema</p>
                <p className="text-sm text-gray-500">
                  Notificaciones dentro de la aplicación
                </p>
              </div>
            </div>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="peer sr-only"
                onChange={onUpdate}
                defaultChecked
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
          <div className="mt-4 space-y-2 pl-8">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                onChange={onUpdate}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
              <span className="text-sm">Nuevas reparaciones</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                onChange={onUpdate}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
              <span className="text-sm">Cambios de estado</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                onChange={onUpdate}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
              <span className="text-sm">Alertas de inventario</span>
            </label>
          </div>
        </div>

        <div className="rounded-lg border p-4 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Mail className="h-5 w-5 text-gray-400" />
              <div>
                <p className="font-medium">Notificaciones por Email</p>
                <p className="text-sm text-gray-500">
                  Recibe actualizaciones en tu correo
                </p>
              </div>
            </div>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="peer sr-only"
                onChange={onUpdate}
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
          <div className="mt-4">
            <input
              type="email"
              placeholder="Email para notificaciones"
              onChange={onUpdate}
              className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
        </div>

        <div className="rounded-lg border p-4 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Phone className="h-5 w-5 text-gray-400" />
              <div>
                <p className="font-medium">Notificaciones SMS</p>
                <p className="text-sm text-gray-500">
                  Recibe alertas importantes por mensaje de texto
                </p>
              </div>
            </div>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="peer sr-only"
                onChange={onUpdate}
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
          <div className="mt-4">
            <input
              type="tel"
              placeholder="Número de teléfono"
              onChange={onUpdate}
              className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
        </div>
      </div>
    </div>
  );
}