import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartData,
  ChartOptions,
  Scale,
  CoreScaleOptions,
  Tick,
} from 'chart.js';
import { RepairOrder } from '../../types/repair';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface RevenueChartProps {
  repairs: RepairOrder[];
  dateRange: string;
}

export function RevenueChart({ repairs, dateRange }: RevenueChartProps) {
  const generateData = () => {
    const now = new Date();
    const labels: string[] = [];
    const values: number[] = [];

    let startDate = new Date();
    let interval = 1;

    switch (dateRange) {
      case 'week':
        startDate.setDate(now.getDate() - 7);
        interval = 1;
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        interval = 2;
        break;
      case 'year':
        startDate.setFullYear(now.getFullYear() - 1);
        interval = 30;
        break;
    }

    for (let date = new Date(startDate); date <= now; date.setDate(date.getDate() + interval)) {
      labels.push(date.toLocaleDateString());
      const dayRevenue = repairs
        .filter(repair => {
          const repairDate = new Date(repair.createdAt);
          return repairDate.toDateString() === date.toDateString();
        })
        .reduce((sum, repair) => sum + repair.estimatedCost, 0);
      values.push(dayRevenue);
    }

    return { labels, values };
  };

  const { labels, values } = generateData();

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        label: 'Ingresos',
        data: values,
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `$${context.parsed.y}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          callback: function(this: Scale<CoreScaleOptions>, tickValue: string | number, index: number, ticks: Tick[]) {
            return `$${tickValue}`;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full h-[300px]">
      <Line data={data} options={options} />
    </div>
  );
}