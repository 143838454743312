import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface CompanySettings {
  name: string;
  logo: string | null;
  contact: {
    address: string;
    phone: string;
    email: string;
    website: string;
  };
  schedule: {
    weekdays: string;
    saturday: string;
    sunday: string;
  };
  payment: {
    cash: boolean;
    card: boolean;
    transfer: boolean;
    accountInfo: string;
  };
  terms: string;
  printer: {
    printReceipts: boolean;
    printerName: string;
    receiptFooter: string;
  };
  legalNotice: string; 
}

interface SettingsState {
  company: CompanySettings;
  updateCompanyName: (name: string) => void;
  updateCompanyLogo: (logo: string | null) => void;
  updateCompanyContact: (contact: Partial<CompanySettings['contact']>) => void;
  updateCompanySchedule: (schedule: Partial<CompanySettings['schedule']>) => void;
  updateCompanyPayment: (payment: Partial<CompanySettings['payment']>) => void;
  updateCompanyPrinter: (printer: Partial<CompanySettings['printer']>) => void;
  updateCompanyTerms: (terms: string) => void;
  updateCompanyLegalNotice: (legalNotice: string) => void; 
}

const initialState: CompanySettings = {
  name: 'PhoneRepair',
  logo: null,
  contact: {
    address: '',
    phone: '',
    email: '',
    website: '',
  },
  schedule: {
    weekdays: '9:00 - 18:00',
    saturday: '10:00 - 14:00',
    sunday: 'Cerrado',
  },
  payment: {
    cash: true,
    card: true,
    transfer: false,
    accountInfo: '',
  },
  terms: '',
  printer: {
    printReceipts: true,
    printerName: '',
    receiptFooter: 'Gracias por su preferencia',
  },
  legalNotice: 'Gracias por su preferencia',
};

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set) => ({
      company: initialState,
      updateCompanyName: (name) =>
        set((state) => ({
          company: { ...state.company, name },
        })),
      updateCompanyLogo: (logo) =>
        set((state) => ({
          company: { ...state.company, logo },
        })),
      updateCompanyContact: (contact) =>
        set((state) => ({
          company: {
            ...state.company,
            contact: { ...state.company.contact, ...contact },
          },
        })),
      updateCompanySchedule: (schedule) =>
        set((state) => ({
          company: {
            ...state.company,
            schedule: { ...state.company.schedule, ...schedule },
          },
        })),
      updateCompanyPayment: (payment) =>
        set((state) => ({
          company: {
            ...state.company,
            payment: { ...state.company.payment, ...payment },
          },
        })),
      updateCompanyPrinter: (printer) =>
        set((state) => ({
          company: {
            ...state.company,
            printer: { ...state.company.printer, ...printer },
          },
        })),
      updateCompanyTerms: (terms) =>
        set((state) => ({
          company: { ...state.company, terms },
        })),
      updateCompanyLegalNotice: (legalNotice) => 
        set((state) => ({
          company: { ...state.company, legalNotice },
        })),
    }),
    {
      name: 'settings-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ company: state.company }),
      merge: (persistedState: any, currentState) => ({
        ...currentState,
        company: {
          ...initialState,
          ...persistedState.company,
          contact: { ...initialState.contact, ...persistedState.company?.contact },
          schedule: { ...initialState.schedule, ...persistedState.company?.schedule },
          payment: { ...initialState.payment, ...persistedState.company?.payment },
          printer: { ...initialState.printer, ...persistedState.company?.printer },
          terms: persistedState.company?.terms ?? initialState.terms,
          legalNotice: persistedState.company?.legalNotice ?? initialState.legalNotice, 
        },
      }),
    }
  )
);