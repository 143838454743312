// React y hooks fundamentales
// useState: Para manejar estados locales del componente
// useRef: Para referencias a elementos del DOM (usado para el scroll de vista previa)
//useEffect: Para efectos secundarios como el scroll automático
import React, { useState, useRef, useEffect } from 'react';

// Stores de Zustand para el manejo del estado global
// useRepairStore: Gestiona el estado de las reparaciones (detalles, estado, etc.)
import { useRepairStore } from '../../store/repairs';
// useSettingsStore: Maneja la configuración global de la empresa (logo, datos, etc.)
import { useSettingsStore } from '../../store/settings';

// Utilidades de fecha para formateo
// format: Función para formatear fechas en el formato deseado (dd/MM/yyyy HH:mm)
import { format } from 'date-fns';

// Iconos de Lucide para la interfaz
// Printer: Icono para el botón de impresión
// Download: Icono para la descarga de PDF
// X: Icono para cerrar el modal
import { Printer, Download, X } from 'lucide-react';

// Sistema de notificaciones toast para feedback al usuario
// toast: Muestra mensajes de éxito o error en operaciones
import { toast } from 'react-hot-toast';

// Utilidades personalizadas para manejo de PDF e impresión
// generatePDF: Genera archivos PDF a partir de elementos HTML
import { generatePDF } from '../../utils/pdf';
// printRepairReceipt: Maneja la impresión de recibos de reparación
import { printRepairReceipt } from '../../utils/print'; // Corrected import path

// Componente para la vista del documento de reparación
// RepairDocument: Plantilla del documento que se va a imprimir/descargar
import { RepairDocument } from '../../documents/index';

// Interfaz que define las propiedades del componente
interface RepairDetailsProps {
  repairId: string;     // ID único de la reparación
  onClose: () => void;  // Función para cerrar el modal
}

export function RepairDetails({ repairId, onClose }: RepairDetailsProps) {
  // Obtener datos de la reparación y configuración de la empresa
  const repair = useRepairStore((state) => state.getRepair(repairId));
  const company = useSettingsStore((state) => state.company);

  // Estados locales para la gestión de la vista previa y opciones de impresión
  const [showPreview, setShowPreview] = useState(false);
  const [printFormat, setPrintFormat] = useState<'receipt' | 'ticket-58' | 'ticket-80'>('receipt');
  const [printOptions, setPrintOptions] = useState({
    includeLogo: true,      // Incluir logo de la empresa
    includeBarcode: true,   // Incluir código de barras
    includeFooter: true,    // Incluir pie de página
    copies: 1,              // Número de copias a imprimir
  });

  // Referencia para el scroll automático a la vista previa
  const previewRef = useRef<HTMLDivElement>(null);

  // Efecto para scroll automático cuando se muestra la vista previa
  useEffect(() => {
    if (showPreview && previewRef.current) {
      previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [showPreview]);

  // Si no se encuentra la reparación, no renderizar nada
  if (!repair) {
    return null;
  }

  // Manejador para la impresión del documento
  const handlePrint = async () => {
    try {
      await printRepairReceipt(repair, company, { ...printOptions, format: printFormat });
      toast.success('Documento enviado a imprimir');
    } catch (error) {
      console.error('Error al imprimir:', error);
      toast.error('Error al imprimir. Por favor, inténtalo de nuevo.');
    }
  };

  // Manejador para la descarga del PDF
  const handleDownloadPDF = async () => {
    try {
      // Asegurar que la vista previa esté visible para la generación del PDF
      if (!showPreview) {
        setShowPreview(true);
        // Esperar a que el DOM se actualice
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const elementId = `repair-document-${repair.id}`;
      const documentElement = document.getElementById(elementId);
      
      if (!documentElement) {
        toast.error('Error: No se pudo encontrar el documento para generar el PDF');
        return;
      }

      const fileName = `reparacion-${repair.id}-${format(new Date(), 'yyyy-MM-dd')}.pdf`;
      
      // Configurar opciones de PDF según el formato seleccionado
      const pdfOptions = {
        quality: 2,
        margin: printFormat === 'ticket-58' || printFormat === 'ticket-80' ? 0 : 10,
        scale: printFormat === 'ticket-58' || printFormat === 'ticket-80' ? 2 : 1.5,
        autoOrientation: printFormat !== 'ticket-58' && printFormat !== 'ticket-80'
      };

      await generatePDF(elementId, fileName, printFormat, pdfOptions);
      toast.success('PDF generado correctamente');
    } catch (error) {
      console.error('Error detallado al generar PDF:', error);
      toast.error('Error al generar el PDF. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    // Modal contenedor con fondo semi-transparente
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 p-4">
      <div className="relative w-full max-w-4xl rounded-lg bg-white shadow-xl dark:bg-gray-900">
        {/* Encabezado del modal */}
        <div className="sticky top-0 z-10 rounded-t-lg border-b bg-white px-4 py-4 dark:border-gray-700 dark:bg-gray-900 sm:px-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-bold sm:text-xl">Detalles de la Reparación</h2>
            <button
              onClick={onClose}
              className="rounded-full p-2 text-gray-500 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-800"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Contenido principal */}
        <div className="p-4 sm:p-6">
          <div className="mb-6 grid gap-6 md:grid-cols-2">
            {/* Sección de información del cliente */}
            <div className="rounded-lg border p-4 dark:border-gray-700">
              <h3 className="mb-3 font-medium">Información del Cliente</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Nombre:</span> {repair.customerName}</p>
                <p><span className="font-medium">Teléfono:</span> {repair.phone}</p>
              </div>
            </div>

            {/* Sección de información del dispositivo */}
            <div className="rounded-lg border p-4 dark:border-gray-700">
              <h3 className="mb-3 font-medium">Información del Dispositivo</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Marca:</span> {repair.deviceBrand}</p>
                <p><span className="font-medium">Modelo:</span> {repair.deviceModel}</p>
                {repair.imei && <p><span className="font-medium">IMEI:</span> {repair.imei}</p>}
                {repair.devicePassword && (
                  <p><span className="font-medium">Contraseña:</span> {repair.devicePassword}</p>
                )}
              </div>
            </div>

            {/* Sección de detalles de la reparación */}
            <div className="rounded-lg border p-4 dark:border-gray-700 md:col-span-2">
              <h3 className="mb-3 font-medium">Detalles de la Reparación</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Problema:</span> {repair.issueDescription}</p>
                {repair.accessories && repair.accessories.length > 0 && (
                  <p><span className="font-medium">Accesorios:</span> {repair.accessories.join(', ')}</p>
                )}
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <span className="font-semibold">Costo estimado:</span>
                    <span className="ml-2">${repair.estimatedCost.toFixed(2)}</span>
                  </div>
                  <div>
                    <span className="font-semibold">Anticipo:</span>
                    <span className="ml-2">${repair.advancePayment?.toFixed(2) || '0.00'}</span>
                  </div>
                  <div>
                    <span className="font-semibold">Saldo restante:</span>
                    <span className="ml-2">${(repair.estimatedCost - (repair.advancePayment || 0)).toFixed(2)}</span>
                  </div>
                </div>
                <p>
                  <span className="font-medium">Estado:</span>{' '}
                  {repair.status === 'pending' && 'Pendiente'}
                  {repair.status === 'in_progress' && 'En Progreso'}
                  {repair.status === 'completed' && 'Completado'}
                  {repair.status === 'delivered' && 'Entregado'}
                </p>
                {repair.technician && (
                  <p><span className="font-medium">Técnico:</span> {repair.technician}</p>
                )}
                {repair.scheduledDate && (
                  <p>
                    <span className="font-medium">Fecha Programada:</span>{' '}
                    {format(new Date(repair.scheduledDate), 'dd/MM/yyyy HH:mm')}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Sección de opciones de impresión */}
          <div className="space-y-4">
            <div className="rounded-lg border p-4 dark:border-gray-700">
              <h3 className="mb-4 font-medium">Opciones de Impresión</h3>
              
              {/* Selección de formato */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Formato
                </label>
                <div className="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      checked={printFormat === 'receipt'}
                      onChange={() => setPrintFormat('receipt')}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span>Recibo Completo</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      checked={printFormat === 'ticket-58'}
                      onChange={() => setPrintFormat('ticket-58')}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span>Ticket 58mm</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      checked={printFormat === 'ticket-80'}
                      onChange={() => setPrintFormat('ticket-80')}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span>Ticket 80mm</span>
                  </label>
                </div>
              </div>

              {/* Opciones de impresión */}
              <div className="grid gap-3 sm:grid-cols-2">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={printOptions.includeLogo}
                    onChange={(e) => setPrintOptions({
                      ...printOptions,
                      includeLogo: e.target.checked
                    })}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">Incluir Logo</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={printOptions.includeBarcode}
                    onChange={(e) => setPrintOptions({
                      ...printOptions,
                      includeBarcode: e.target.checked
                    })}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">Incluir Código de Barras</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={printOptions.includeFooter}
                    onChange={(e) => setPrintOptions({
                      ...printOptions,
                      includeFooter: e.target.checked
                    })}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">Incluir Pie de Página</span>
                </label>
                <div className="flex items-center gap-2">
                  <span className="text-sm">Copias:</span>
                  <input
                    type="number"
                    min="1"
                    max="5"
                    value={printOptions.copies}
                    onChange={(e) => setPrintOptions({
                      ...printOptions,
                      copies: parseInt(e.target.value) || 1
                    })}
                    className="w-16 rounded-md border border-gray-300 px-2 py-1 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                  />
                </div>
              </div>
            </div>

            {/* Vista previa */}
            {showPreview && (
              <div ref={previewRef} className="rounded-lg border p-4 dark:border-gray-700">
                <h3 className="mb-4 font-medium">Vista Previa</h3>
                <div 
                  id={`repair-document-${repair.id}`}
                  className="flex justify-center overflow-auto rounded border p-4 dark:border-gray-700"
                >
                  <RepairDocument
                    repair={repair}
                    company={company}
                    format={printFormat}
                    options={printOptions}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Barra de acciones inferior */}
        <div className="sticky bottom-0 rounded-b-lg border-t bg-white px-4 py-4 dark:border-gray-700 dark:bg-gray-900 sm:px-6">
          <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-end">
            {/* Botón de vista previa */}
            <button
              onClick={() => setShowPreview(!showPreview)}
              className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              {showPreview ? 'Ocultar Vista Previa' : 'Mostrar Vista Previa'}
            </button>
            {/* Botón de descarga PDF */}
            <button
              onClick={handleDownloadPDF}
              className="flex items-center justify-center gap-2 rounded-md border border-blue-600 px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 dark:border-blue-500 dark:text-blue-400 dark:hover:bg-blue-900/50"
            >
              <Download className="h-4 w-4" />
              <span className="hidden sm:inline">Descargar PDF</span>
              <span className="sm:hidden">PDF</span>
            </button>
            {/* Botón de impresión */}
            <button
              onClick={handlePrint}
              className="flex items-center justify-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
            >
              <Printer className="h-4 w-4" />
              <span className="hidden sm:inline">Imprimir</span>
              <span className="sm:hidden">Imprimir</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}