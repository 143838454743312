import React, { useState } from 'react';
import { RepairForm } from '../components/repairs/RepairForm';
import { RepairList } from '../components/repairs/RepairList';
import { RepairFilters, type RepairFilters as FilterType } from '../components/repairs/RepairFilters';
import { PlusCircle } from 'lucide-react';
import { useRepairStore } from '../store/repairs';

export function RepairsPage() {
  const [showForm, setShowForm] = useState(false);
  const repairs = useRepairStore((state) => state.repairs);
  const [filters, setFilters] = useState<FilterType>({
    status: [],
    dateRange: { start: null, end: null },
    technician: [],
    priceRange: { min: null, max: null },
    searchTerm: '',
    deviceType: [],
  });

  // Extract unique technicians and device types from repairs
  const technicians = Array.from(new Set(repairs
    .filter(r => r.technician)
    .map(r => r.technician!)
  ));

  const deviceTypes = Array.from(new Set(repairs
    .map(r => `${r.deviceBrand} ${r.deviceModel}`)
  ));

  const filteredRepairs = repairs.filter(repair => {
    // Status filter
    if (filters.status.length > 0 && !filters.status.includes(repair.status)) {
      return false;
    }

    // Date range filter
    if (filters.dateRange.start || filters.dateRange.end) {
      const repairDate = new Date(repair.createdAt);
      if (filters.dateRange.start && repairDate < filters.dateRange.start) {
        return false;
      }
      if (filters.dateRange.end) {
        const endDate = new Date(filters.dateRange.end);
        endDate.setHours(23, 59, 59, 999);
        if (repairDate > endDate) {
          return false;
        }
      }
    }

    // Technician filter
    if (filters.technician.length > 0 && !filters.technician.includes(repair.technician || '')) {
      return false;
    }

    // Price range filter
    if (filters.priceRange.min && repair.estimatedCost < filters.priceRange.min) {
      return false;
    }
    if (filters.priceRange.max && repair.estimatedCost > filters.priceRange.max) {
      return false;
    }

    // Device type filter
    const deviceType = `${repair.deviceBrand} ${repair.deviceModel}`;
    if (filters.deviceType.length > 0 && !filters.deviceType.includes(deviceType)) {
      return false;
    }

    // Search term filter
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      return (
        repair.customerName.toLowerCase().includes(searchLower) ||
        repair.deviceBrand.toLowerCase().includes(searchLower) ||
        repair.deviceModel.toLowerCase().includes(searchLower) ||
        repair.imei?.toLowerCase().includes(searchLower) ||
        repair.issueDescription.toLowerCase().includes(searchLower)
      );
    }

    return true;
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Gestión de Reparaciones
        </h1>
        <button
          onClick={() => setShowForm(!showForm)}
          className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
        >
          <PlusCircle className="h-5 w-5" />
          Nueva Reparación
        </button>
      </div>

      {showForm && (
        <div className="mb-8 rounded-lg border bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-900">
          <h2 className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
            Nueva Orden de Reparación
          </h2>
          <RepairForm onSuccess={() => setShowForm(false)} />
        </div>
      )}

      <div className="space-y-6">
        <RepairFilters
          filters={filters}
          onFilterChange={setFilters}
          technicians={technicians}
          deviceTypes={deviceTypes}
        />

        <RepairList repairs={filteredRepairs} />
      </div>
    </div>
  );
}