import React, { useState } from 'react';
import { useRepairStore } from '../../store/repairs';
import { RepairStatus, RepairOrder } from '../../types/repair';
import { CheckCircle, Clock, Wrench, Package } from 'lucide-react';
import { RepairDetails } from './RepairDetails';
import toast from 'react-hot-toast';

const statusIcons = {
  pending: Clock,
  in_progress: Wrench,
  completed: CheckCircle,
  delivered: Package,
  pendiente: Clock,
};

const statusColors = {
  pending: 'text-yellow-500',
  in_progress: 'text-blue-500',
  completed: 'text-green-500',
  delivered: 'text-purple-500',
  pendiente: 'text-yellow-500',
};

interface RepairListProps {
  repairs: RepairOrder[];
}

export function RepairList({ repairs }: RepairListProps) {
  const updateRepair = useRepairStore((state) => state.updateRepair);
  const [selectedRepairId, setSelectedRepairId] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleStatusChange = async (id: string, status: RepairStatus) => {
    try {
      setIsUpdating(true);
      await updateRepair(id, { status });
      toast.success('Estado actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      toast.error('Error al actualizar el estado');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderStatusIcon = (status: RepairStatus) => {
    const IconComponent = statusIcons[status];
    if (!IconComponent) {
      console.error(`No icon found for status: ${status}`);
      return null;
    }
    return React.createElement(IconComponent, {
      className: `h-5 w-5 ${statusColors[status]}`,
      'aria-hidden': 'true'
    });
  };

  if (!repairs || repairs.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500 dark:text-gray-400">No hay reparaciones disponibles</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {repairs.map((repair) => (
        <div
          key={repair.id}
          className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 hover:shadow-md transition-shadow duration-200"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <span className="flex-shrink-0">
                {renderStatusIcon(repair.status)}
              </span>
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  {repair.customerName}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {repair.deviceBrand} {repair.deviceModel}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <select
                value={repair.status}
                onChange={(e) => handleStatusChange(repair.id, e.target.value as RepairStatus)}
                disabled={isUpdating}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="pending">Pendiente</option>
                <option value="in_progress">En Progreso</option>
                <option value="completed">Completado</option>
                <option value="delivered">Entregado</option>
              </select>
              <button
                onClick={() => setSelectedRepairId(repair.id)}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-indigo-200 dark:bg-indigo-900 dark:hover:bg-indigo-800"
              >
                Ver detalles
              </button>
            </div>
          </div>
        </div>
      ))}
      {selectedRepairId && (
        <RepairDetails
          repairId={selectedRepairId}
          onClose={() => setSelectedRepairId(null)}
        />
      )}
    </div>
  );
}