import React, { useState } from 'react';
import { WholesaleClient } from '../../types/wholesaleClient';
import { useWholesaleClientsStore } from '../../store/wholesaleClients';
import { Building2, Mail, Phone, MapPin, User, Key, Pencil, Trash2 } from 'lucide-react';
import { WholesaleClientForm } from './WholesaleClientForm';
import { toast } from 'react-hot-toast';

interface WholesaleClientListProps {
  clients: WholesaleClient[];
}

export function WholesaleClientList({ clients }: WholesaleClientListProps) {
  const [editingClient, setEditingClient] = useState<WholesaleClient | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const updateClient = useWholesaleClientsStore((state) => state.updateClient);
  const removeClient = useWholesaleClientsStore((state) => state.removeClient);

  const handleDelete = (clientId: string) => {
    removeClient(clientId);
    setShowDeleteConfirm(null);
    toast.success('Cliente eliminado correctamente');
  };

  return (
    <>
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {clients.map((client) => (
          <div
            key={client.id}
            className="rounded-lg border bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="rounded-full bg-blue-100 p-2 dark:bg-blue-900/20">
                  <Building2 className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {client.businessName}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {client.rfc}
                  </p>
                </div>
              </div>
              <span
                className={`inline-flex rounded-full px-2 py-1 text-xs font-medium ${
                  client.status === 'active'
                    ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                    : 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-400'
                }`}
              >
                {client.status === 'active' ? 'Activo' : 'Inactivo'}
              </span>
            </div>

            <div className="mt-4 space-y-2">
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <User className="h-4 w-4" />
                <span>{client.contactPerson}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <Phone className="h-4 w-4" />
                <span>{client.phone}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <Mail className="h-4 w-4" />
                <span>{client.email}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <MapPin className="h-4 w-4" />
                <span>{client.address}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <Key className="h-4 w-4" />
                <span>Código de acceso: {client.accessCode}</span>
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between border-t pt-4 dark:border-gray-700">
              <span className="text-sm font-medium text-gray-900 dark:text-white">
                Descuento: {client.discountRate}%
              </span>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setEditingClient(client)}
                  className="rounded-full p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
                  title="Editar"
                >
                  <Pencil className="h-4 w-4" />
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(client.id)}
                  className="rounded-full p-2 text-red-600 hover:bg-red-100 dark:text-red-400 dark:hover:bg-red-900/20"
                  title="Eliminar"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Modal */}
      {editingClient && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="w-full max-w-4xl rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <h2 className="mb-4 text-lg font-medium">Editar Cliente Mayorista</h2>
            <WholesaleClientForm
              initialData={editingClient}
              onSuccess={() => {
                setEditingClient(null);
                toast.success('Cliente actualizado correctamente');
              }}
              onCancel={() => setEditingClient(null)}
            />
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <h2 className="mb-4 text-lg font-medium">Confirmar Eliminación</h2>
            <p className="mb-6 text-gray-600 dark:text-gray-400">
              ¿Estás seguro de que deseas eliminar este cliente mayorista? Esta acción no se puede deshacer.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleDelete(showDeleteConfirm)}
                className="rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}