import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { useSettingsStore } from '../store/settings';
import { 
  Smartphone, Clock, Shield, Wrench, MapPin, Phone, Mail, 
  ChevronRight, Star, Users, Building2, Check 
} from 'lucide-react';
import { toast } from 'react-hot-toast';

export function LandingPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { company } = useSettingsStore();

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (email === 'admin@example.com' && password === 'admin') {
        login({
          id: '1',
          name: 'Administrador',
          email: email,
          role: 'admin',
          permissions: ['all'],
        });
        toast.success('Inicio de sesión exitoso');
        navigate('/dashboard');
      } else if (email === 'tech@example.com' && password === 'tech') {
        login({
          id: '2',
          name: 'Técnico',
          email: email,
          role: 'technician',
          permissions: ['repairs.view', 'repairs.update'],
        });
        toast.success('Inicio de sesión exitoso');
        navigate('/dashboard');
      } else {
        toast.error('Credenciales inválidas');
      }
    } catch (error) {
      toast.error('Error al iniciar sesión');
    }
  };

  const features = [
    {
      icon: Wrench,
      title: 'Reparación Profesional',
      description: 'Técnicos certificados y herramientas especializadas para garantizar la mejor reparación.'
    },
    {
      icon: Clock,
      title: 'Servicio Rápido',
      description: 'La mayoría de las reparaciones se completan en el mismo día.'
    },
    {
      icon: Shield,
      title: 'Garantía Asegurada',
      description: 'Todas nuestras reparaciones incluyen garantía por escrito.'
    },
    {
      icon: Users,
      title: 'Atención Personalizada',
      description: 'Te mantenemos informado durante todo el proceso de reparación.'
    }
  ];

  const wholesaleFeatures = [
    {
      title: "Portal Exclusivo",
      description: "Acceso 24/7 al estado de tus reparaciones"
    },
    {
      title: "Descuentos Especiales",
      description: "Precios preferenciales para clientes mayoristas"
    },
    {
      title: "Atención Prioritaria",
      description: "Gestión prioritaria de tus dispositivos"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Hero Section */}
      <header className="relative overflow-hidden bg-blue-600 dark:bg-blue-900">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1591799264318-7e6ef8ddb7ea?auto=format&fit=crop&q=80"
            alt="Fondo"
            className="h-full w-full object-cover opacity-10"
          />
        </div>
        <div className="relative">
          <div className="container mx-auto px-4 py-16 sm:px-6 lg:px-8">
            <div className="grid gap-12 lg:grid-cols-2">
              <div className="flex flex-col justify-center">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
                  {company.name}
                </h1>
                <p className="mt-4 text-xl text-blue-100">
                  Expertos en reparación de dispositivos móviles. Servicio profesional y garantizado.
                </p>
                <div className="mt-8 flex flex-wrap gap-4">
                  <a
                    href="#contact"
                    className="rounded-lg bg-white px-6 py-3 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-50"
                  >
                    Contáctanos
                  </a>
                  <a
                    href="#services"
                    className="rounded-lg border border-white px-6 py-3 text-sm font-semibold text-white hover:bg-white/10"
                  >
                    Nuestros Servicios
                  </a>
                </div>
              </div>
              <div className="rounded-lg bg-white/10 p-8 backdrop-blur-sm">
                <h2 className="text-2xl font-bold text-white">
                  Acceso al Sistema
                </h2>
                <p className="mt-2 text-sm text-blue-100">
                  Ingresa tus credenciales para acceder al panel de control
                </p>
                <form onSubmit={handleLogin} className="mt-8 space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="mt-1 block w-full rounded-lg border border-white/20 bg-white/10 px-3 py-2 text-white placeholder-white/50 backdrop-blur-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-white"
                      placeholder="tu@email.com"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-white">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="mt-1 block w-full rounded-lg border border-white/20 bg-white/10 px-3 py-2 text-white placeholder-white/50 backdrop-blur-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-white"
                      placeholder="••••••••"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full rounded-lg bg-white px-4 py-2 text-sm font-semibold text-blue-600 hover:bg-blue-50"
                  >
                    Iniciar Sesión
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section id="services" className="py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Nuestros Servicios
            </h2>
            <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
              Soluciones profesionales para tu dispositivo
            </p>
          </div>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div
                  key={index}
                  className="rounded-lg border bg-white p-6 shadow-sm transition-all hover:-translate-y-1 hover:shadow-md dark:border-gray-700 dark:bg-gray-800"
                >
                  <div className="mb-4 inline-flex rounded-lg bg-blue-100 p-3 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400">
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="mb-2 text-lg font-medium text-gray-900 dark:text-white">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    {feature.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Wholesale Section */}
      <section className="bg-blue-600 py-24 text-white dark:bg-blue-900">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid gap-12 lg:grid-cols-2">
            <div>
              <h2 className="text-3xl font-bold">Portal Mayorista</h2>
              <p className="mt-4 text-xl text-blue-100">
                ¿Eres distribuidor o tienes múltiples dispositivos? Accede a beneficios exclusivos.
              </p>
              <div className="mt-8 space-y-6">
                {wholesaleFeatures.map((feature, index) => (
                  <div key={index} className="flex items-start gap-4">
                    <div className="rounded-full bg-blue-500 bg-opacity-20 p-2">
                      <Check className="h-6 w-6" />
                    </div>
                    <div>
                      <h3 className="font-medium">{feature.title}</h3>
                      <p className="text-blue-100">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-8">
                <Link
                  to="/portal"
                  className="inline-flex items-center gap-2 rounded-lg bg-white px-6 py-3 font-medium text-blue-600 transition-all hover:-translate-y-1 hover:shadow-lg"
                >
                  Acceder al Portal
                  <ChevronRight className="h-5 w-5" />
                </Link>
              </div>
            </div>
            <div className="relative hidden lg:block">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-50">
                <img
                  src="https://images.unsplash.com/photo-1577375729152-4c8b5fcda381?auto=format&fit=crop&q=80"
                  alt="Dispositivos móviles"
                  className="h-full w-full object-cover opacity-50"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="rounded-lg bg-white p-8 shadow-lg dark:bg-gray-800">
            <div className="grid gap-8 lg:grid-cols-2">
              <div>
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                  Contáctanos
                </h2>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
                  Estamos aquí para ayudarte con cualquier problema que tengas con tu dispositivo.
                </p>
                <div className="mt-8 space-y-6">
                  <div className="flex items-center gap-4">
                    <MapPin className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    <div>
                      <p className="font-medium text-gray-900 dark:text-white">
                        Dirección
                      </p>
                      <p className="text-gray-600 dark:text-gray-400">
                        {company.contact.address}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Phone className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    <div>
                      <p className="font-medium text-gray-900 dark:text-white">
                        Teléfono
                      </p>
                      <p className="text-gray-600 dark:text-gray-400">
                        {company.contact.phone}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Mail className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    <div>
                      <p className="font-medium text-gray-900 dark:text-white">
                        Email
                      </p>
                      <p className="text-gray-600 dark:text-gray-400">
                        {company.contact.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                  Horario de Atención
                </h3>
                <div className="mt-4 space-y-4">
                  <div className="flex justify-between border-b pb-2 dark:border-gray-700">
                    <span className="text-gray-600 dark:text-gray-400">
                      Lunes a Viernes
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {company.schedule.weekdays}
                    </span>
                  </div>
                  <div className="flex justify-between border-b pb-2 dark:border-gray-700">
                    <span className="text-gray-600 dark:text-gray-400">
                      Sábados
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {company.schedule.saturday}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Domingos
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {company.schedule.sunday}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-12 text-gray-400">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid gap-8 lg:grid-cols-3">
            <div>
              <h3 className="text-lg font-bold text-white">{company.name}</h3>
              <p className="mt-4">
                Expertos en reparación de dispositivos móviles con años de experiencia.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-bold text-white">Enlaces Rápidos</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="#services" className="hover:text-white">
                    Servicios
                  </a>
                </li>
                <li>
                  <a href="#contact" className="hover:text-white">
                    Contacto
                  </a>
                </li>
                <li>
                  <Link to="/portal" className="hover:text-white">
                    Portal Mayoristas
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-bold text-white">Síguenos</h3>
              <div className="mt-4 flex space-x-4">
                {/* Add your social media links here */}
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8 text-center">
            <p>© {new Date().getFullYear()} {company.name}. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}