import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ThemeState {
  theme: 'light' | 'dark' | 'system';
  color: string;
  density: 'comfortable' | 'compact' | 'spacious';
  fontSize: 'small' | 'medium' | 'large';
  setTheme: (theme: 'light' | 'dark' | 'system') => void;
  setColor: (color: string) => void;
  setDensity: (density: 'comfortable' | 'compact' | 'spacious') => void;
  setFontSize: (size: 'small' | 'medium' | 'large') => void;
}

const initialState = {
  theme: 'system' as const,
  color: 'blue',
  density: 'comfortable' as const,
  fontSize: 'medium' as const,
};

export const useThemeStore = create<ThemeState>()(
  persist(
    (set) => ({
      ...initialState,
      setTheme: (theme) => {
        set({ theme });
        if (theme === 'dark') {
          document.documentElement.classList.add('dark');
        } else if (theme === 'light') {
          document.documentElement.classList.remove('dark');
        } else {
          // System theme
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.classList.add('dark');
          } else {
            document.documentElement.classList.remove('dark');
          }
        }
      },
      setColor: (color) => {
        set({ color });
        document.documentElement.style.setProperty('--primary', `var(--${color})`);
      },
      setDensity: (density) => set({ density }),
      setFontSize: (fontSize) => set({ fontSize }),
    }),
    {
      name: 'theme-storage',
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          // Migration from version 0 to 1
          return {
            ...initialState,
            ...persistedState,
          };
        }
        return persistedState;
      },
    }
  )
);