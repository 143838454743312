import React, { useState, useEffect, useRef } from 'react';
import { useCustomerStore } from '../../store/customers';
import { Customer } from '../../types/customer';
import { UserPlus, Search } from 'lucide-react';
import { cn } from '../../lib/utils';

interface CustomerSearchProps {
  onSelect: (customer: Customer) => void;
  onNewCustomer: () => void;
}

export function CustomerSearch({ onSelect, onNewCustomer }: CustomerSearchProps) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const searchCustomers = useCustomerStore((state) => state.searchCustomers);
  const [results, setResults] = useState<Customer[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (query.length >= 2) {
      setResults(searchCustomers(query));
      setIsOpen(true);
    } else {
      setResults([]);
      setIsOpen(false);
    }
  }, [query, searchCustomers]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (customer: Customer) => {
    onSelect(customer);
    setQuery(customer.name);
    setIsOpen(false);
  };

  return (
    <div ref={containerRef} className="relative">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar cliente..."
          className="block w-full rounded-md border border-gray-300 pl-10 pr-3 py-2 text-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg dark:bg-gray-800">
          <ul className="max-h-60 overflow-auto rounded-md py-1 text-base">
            {results.length > 0 ? (
              results.map((customer) => (
                <li
                  key={customer.id}
                  onClick={() => handleSelect(customer)}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <div className="font-medium">{customer.name}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">{customer.phone}</div>
                </li>
              ))
            ) : (
              <li
                onClick={onNewCustomer}
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-blue-600 hover:bg-gray-100 dark:text-blue-400 dark:hover:bg-gray-700"
              >
                <UserPlus className="h-4 w-4" />
                <span>Registrar nuevo cliente</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}