import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { WholesaleClient } from '../types/wholesaleClient';
import { RepairOrder } from '../types/repair';

interface WholesaleClientsState {
  clients: WholesaleClient[];
  addClient: (client: Omit<WholesaleClient, 'id' | 'createdAt' | 'updatedAt' | 'accessCode'>) => WholesaleClient;
  updateClient: (id: string, updates: Partial<WholesaleClient>) => void;
  removeClient: (id: string) => void;
  getClient: (id: string) => WholesaleClient | undefined;
  getClientByAccessCode: (accessCode: string) => WholesaleClient | undefined;
  getClientRepairs: (clientId: string, repairs: RepairOrder[]) => RepairOrder[];
}

export const useWholesaleClientsStore = create<WholesaleClientsState>()(
  persist(
    (set, get) => ({
      clients: [],
      addClient: (client) => {
        const accessCode = Math.random().toString(36).substring(2, 8).toUpperCase();
        const newClient: WholesaleClient = {
          ...client,
          id: Math.random().toString(36).substring(7),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          accessCode,
        };
        set((state) => ({ clients: [...state.clients, newClient] }));
        return newClient;
      },
      updateClient: (id, updates) => {
        set((state) => ({
          clients: state.clients.map((client) =>
            client.id === id
              ? { ...client, ...updates, updatedAt: new Date().toISOString() }
              : client
          ),
        }));
      },
      removeClient: (id) => {
        set((state) => ({
          clients: state.clients.filter((client) => client.id !== id),
        }));
      },
      getClient: (id) => get().clients.find((client) => client.id === id),
      getClientByAccessCode: (accessCode) => 
        get().clients.find((client) => client.accessCode === accessCode),
      getClientRepairs: (clientId, repairs) => 
        repairs.filter((repair) => {
          const client = get().getClient(clientId);
          return client && repair.phone === client.phone;
        }),
    }),
    {
      name: 'wholesale-clients-storage',
    }
  )
);