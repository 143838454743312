import { useEffect } from 'react';
import { useNotificationStore } from '../store/notifications';
import { useRepairStore } from '../store/repairs';
import { useInventoryStore } from '../store/inventory';
import { RepairOrder } from '../types/repair';

const statusMessages = {
  pending: 'está pendiente de revisión',
  in_progress: 'está siendo reparado',
  completed: 'ha sido reparado',
  delivered: 'ha sido entregado al cliente',
};

export function useNotifications() {
  const addNotification = useNotificationStore((state) => state.addNotification);
  const repairs = useRepairStore((state) => state.repairs);
  const lowStockItems = useInventoryStore((state) => state.getLowStockItems());

  // Monitor repair status changes
  useEffect(() => {
    repairs.forEach((repair) => {
      const lastStatus = repair.statusHistory[repair.statusHistory.length - 1];
      const prevStatus = repair.statusHistory[repair.statusHistory.length - 2];

      if (prevStatus && lastStatus.status !== prevStatus.status) {
        addNotification({
          type: 'status_update',
          title: 'Actualización de Reparación',
          message: `El dispositivo ${repair.deviceBrand} ${repair.deviceModel} ${statusMessages[lastStatus.status]}`,
          metadata: {
            repairId: repair.id,
            status: lastStatus.status
          }
        });
      }
    });
  }, [repairs]);

  // Monitor inventory levels
  useEffect(() => {
    lowStockItems.forEach((item) => {
      if (item.stock <= item.minStock) {
        addNotification({
          type: 'low_stock',
          title: 'Alerta de Inventario',
          message: `${item.name} tiene stock bajo (${item.stock} unidades disponibles)`,
          metadata: {
            itemId: item.id
          }
        });
      }
    });
  }, [lowStockItems]);

  // Monitor new repairs
  useEffect(() => {
    repairs.forEach((repair) => {
      if (repair.statusHistory.length === 1) {
        addNotification({
          type: 'new_repair',
          title: 'Nueva Orden de Reparación',
          message: `Se ha registrado una nueva reparación para ${repair.customerName}`,
          metadata: {
            repairId: repair.id
          }
        });
      }
    });
  }, [repairs]);

  return null;
}