import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { InventoryItem } from '../../types/inventory';

ChartJS.register(ArcElement, Tooltip, Legend);

interface InventoryValueChartProps {
  inventory: InventoryItem[];
}

export function InventoryValueChart({ inventory }: InventoryValueChartProps) {
  const calculateCategoryValues = () => {
    const categories = {
      screen: { label: 'Pantallas', value: 0 },
      battery: { label: 'Baterías', value: 0 },
      accessory: { label: 'Accesorios', value: 0 },
      component: { label: 'Componentes', value: 0 },
    };

    inventory.forEach(item => {
      categories[item.category].value += item.stock * item.cost;
    });

    return {
      labels: Object.values(categories).map(c => c.label),
      values: Object.values(categories).map(c => c.value),
    };
  };

  const { labels, values } = calculateCategoryValues();

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          'rgba(59, 130, 246, 0.8)',
          'rgba(16, 185, 129, 0.8)',
          'rgba(245, 158, 11, 0.8)',
          'rgba(239, 68, 68, 0.8)',
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `$${context.raw.toFixed(2)}`,
        },
      },
    },
  };

  return (
    <div className="w-full h-[300px]">
      <Doughnut data={data} options={options} />
    </div>
  );
}