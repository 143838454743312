import React, { useRef } from 'react';
import { 
  Building2, MapPin, Phone, Mail, Globe, Printer, Clock, 
  Upload, X, DollarSign, CreditCard, Banknote 
} from 'lucide-react';
import { useSettingsStore } from '../../store/settings';
import { cn } from '../../lib/utils';

interface CompanySettingsProps {
  onUpdate: () => void;
}

export function CompanySettings({ onUpdate }: CompanySettingsProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { 
    company,
    updateCompanyName,
    updateCompanyLogo,
    updateCompanyContact,
    updateCompanySchedule,
    updateCompanyPayment,
    updateCompanyPrinter
  } = useSettingsStore();

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateCompanyLogo(reader.result as string);
        onUpdate();
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoRemove = () => {
    updateCompanyLogo('');
    onUpdate();
  };

  return (
    <div className="space-y-8">
      {/* Información Básica */}
      <section>
        <h3 className="mb-4 text-lg font-medium">Información de la Empresa</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Nombre de la Empresa
            </label>
            <div className="mt-1 flex items-center gap-3">
              <Building2 className="h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={company.name}
                onChange={(e) => {
                  updateCompanyName(e.target.value);
                  onUpdate();
                }}
                className="block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Logo
            </label>
            <div className="mt-1 flex items-center gap-4">
              <div className={cn(
                "group relative h-24 w-24 overflow-hidden rounded-xl border transition-all",
                "bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-900/30 dark:to-blue-800/30",
                "shadow-lg ring-2 ring-blue-100 dark:ring-blue-900/50",
                "hover:ring-4 hover:ring-blue-200 dark:hover:ring-blue-800"
              )}>
                {company.logo ? (
                  <>
                    <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-transparent opacity-0 transition-opacity group-hover:opacity-100" />
                    <img
                      src={company.logo}
                      alt="Logo"
                      className="h-full w-full object-contain p-4 transition-transform group-hover:scale-110"
                    />
                    <button
                      onClick={handleLogoRemove}
                      className="absolute right-1 top-1 rounded-full bg-red-100 p-1 text-red-600 hover:bg-red-200 dark:bg-red-900/50 dark:text-red-400"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </>
                ) : (
                  <div className="flex h-full w-full items-center justify-center">
                    <Building2 className="h-8 w-8 text-blue-600 dark:text-blue-400" />
                  </div>
                )}
              </div>
              <button
                onClick={() => fileInputRef.current?.click()}
                className="flex items-center gap-2 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                <Upload className="h-4 w-4" />
                Subir Logo
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                className="hidden"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Rest of the component remains unchanged */}
      {/* ... */}
    </div>
  );
}