import React from 'react';
import { Link } from 'react-router-dom';
import { Home, ArrowLeft } from 'lucide-react';

export function NotFound() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 dark:bg-gray-900">
      <div className="text-center">
        <h1 className="mb-4 text-6xl font-bold text-blue-600 dark:text-blue-400">404</h1>
        <h2 className="mb-4 text-2xl font-semibold text-gray-900 dark:text-white">
          Página no encontrada
        </h2>
        <p className="mb-8 text-gray-600 dark:text-gray-400">
          Lo sentimos, la página que buscas no existe o ha sido movida.
        </p>
        <div className="flex justify-center gap-4">
          <Link
            to="/"
            className="flex items-center gap-2 rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
          >
            <Home className="h-4 w-4" />
            Inicio
          </Link>
          <button
            onClick={() => window.history.back()}
            className="flex items-center gap-2 rounded-lg border border-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-800"
          >
            <ArrowLeft className="h-4 w-4" />
            Volver
          </button>
        </div>
      </div>
    </div>
  );
}