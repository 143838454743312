import React, { useState, useMemo } from 'react';
import { useSalesStore } from '../store/sales';
import { useInventoryStore } from '../store/inventory';
import { SaleForm } from '../components/sales/SaleForm';
import { SalesList } from '../components/sales/SalesList';
import { SalesFilters } from '../components/sales/SalesFilters';
import { PlusCircle, TrendingUp, DollarSign, CreditCard, Banknote } from 'lucide-react';
import { format, startOfDay, endOfDay } from 'date-fns';

export function SalesPage() {
  const [showForm, setShowForm] = useState(false);
  const sales = useSalesStore((state) => state.sales);
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [searchTerm, setSearchTerm] = useState('');

  const dailyStats = useMemo(() => {
    const dayStart = startOfDay(dateFilter);
    const dayEnd = endOfDay(dateFilter);
    
    const daySales = sales.filter(sale => {
      const saleDate = new Date(sale.createdAt);
      return saleDate >= dayStart && saleDate <= dayEnd;
    });

    const totalSales = daySales.reduce((sum, sale) => sum + sale.total, 0);
    const totalTransactions = daySales.length;
    
    const paymentMethods = {
      cash: daySales.filter(s => s.paymentMethod === 'cash').reduce((sum, s) => sum + s.total, 0),
      card: daySales.filter(s => s.paymentMethod === 'card').reduce((sum, s) => sum + s.total, 0),
      transfer: daySales.filter(s => s.paymentMethod === 'transfer').reduce((sum, s) => sum + s.total, 0),
    };

    const averageTicket = totalTransactions > 0 ? totalSales / totalTransactions : 0;

    return {
      totalSales,
      totalTransactions,
      paymentMethods,
      averageTicket,
    };
  }, [sales, dateFilter]);

  const filteredSales = sales.filter((sale) => {
    const matchesSearch = !searchTerm || 
      sale.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sale.customerPhone?.includes(searchTerm);
    
    const saleDate = new Date(sale.createdAt);
    const isMatchingDate = format(saleDate, 'yyyy-MM-dd') === format(dateFilter, 'yyyy-MM-dd');

    return matchesSearch && isMatchingDate;
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Gestión de Ventas
        </h1>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
        >
          <PlusCircle className="h-5 w-5" />
          Nueva Venta
        </button>
      </div>

      {/* Stats Grid */}
      <div className="mb-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Ventas Totales</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                ${dailyStats.totalSales.toFixed(2)}
              </p>
            </div>
            <div className="rounded-full bg-blue-100 p-3 dark:bg-blue-900/20">
              <DollarSign className="h-6 w-6 text-blue-600 dark:text-blue-400" />
            </div>
          </div>
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Transacciones</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                {dailyStats.totalTransactions}
              </p>
            </div>
            <div className="rounded-full bg-green-100 p-3 dark:bg-green-900/20">
              <TrendingUp className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Ticket Promedio</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                ${dailyStats.averageTicket.toFixed(2)}
              </p>
            </div>
            <div className="rounded-full bg-purple-100 p-3 dark:bg-purple-900/20">
              <CreditCard className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            </div>
          </div>
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Efectivo</p>
              <p className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                ${dailyStats.paymentMethods.cash.toFixed(2)}
              </p>
            </div>
            <div className="rounded-full bg-yellow-100 p-3 dark:bg-yellow-900/20">
              <Banknote className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
            </div>
          </div>
        </div>
      </div>

      <SalesFilters
        date={dateFilter}
        onDateChange={setDateFilter}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
      />

      <div className="mt-6">
        <SalesList sales={filteredSales} />
      </div>

      {showForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-4xl rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <h2 className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
              Nueva Venta
            </h2>
            <SaleForm
              onSuccess={() => setShowForm(false)}
              onCancel={() => setShowForm(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}