import React from 'react';
import { InventoryItem } from '../../types/inventory';
import { AlertTriangle, Package } from 'lucide-react';

interface LowStockAlertProps {
  inventory: InventoryItem[];
}

export function LowStockAlert({ inventory }: LowStockAlertProps) {
  const lowStockItems = inventory
    .filter(item => item.stock <= item.minStock)
    .sort((a, b) => (a.stock / a.minStock) - (b.stock / b.minStock));

  if (lowStockItems.length === 0) {
    return (
      <div className="flex items-center justify-center gap-3 rounded-lg border border-green-200 bg-green-50 p-4 text-green-800 dark:border-green-900/50 dark:bg-green-900/20 dark:text-green-200">
        <Package className="h-5 w-5" />
        <p>Todos los productos tienen stock suficiente</p>
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {lowStockItems.map((item) => {
        const stockPercentage = (item.stock / item.minStock) * 100;
        return (
          <div
            key={item.id}
            className="overflow-hidden rounded-lg border border-yellow-200 bg-yellow-50 dark:border-yellow-900/50 dark:bg-yellow-900/20"
          >
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center gap-3">
                <div className="rounded-full bg-yellow-100 p-2 dark:bg-yellow-900/50">
                  <AlertTriangle className="h-5 w-5 text-yellow-600 dark:text-yellow-400" />
                </div>
                <div>
                  <div className="font-medium text-yellow-800 dark:text-yellow-200">
                    {item.name}
                  </div>
                  <div className="text-sm text-yellow-700 dark:text-yellow-300">
                    Stock actual: {item.stock} (Mínimo: {item.minStock})
                  </div>
                </div>
              </div>
              <button className="rounded-md bg-yellow-600 px-3 py-1 text-sm font-medium text-white transition-colors hover:bg-yellow-700 dark:bg-yellow-500 dark:hover:bg-yellow-600">
                Reabastecer
              </button>
            </div>
            <div className="h-1 w-full bg-yellow-200 dark:bg-yellow-900/50">
              <div 
                className="h-full bg-yellow-600 transition-all dark:bg-yellow-500" 
                style={{ width: `${Math.min(stockPercentage, 100)}%` }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}