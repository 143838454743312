import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRepairStore } from '../../store/repairs';
import { useCustomerStore } from '../../store/customers';
import { useInventoryStore } from '../../store/inventory';
import { 
  Search, X, Smartphone, User, Package, ArrowRight, 
  Clock, DollarSign, Calendar, Phone, Mail 
} from 'lucide-react';
import { cn } from '../../lib/utils';

interface GlobalSearchProps {
  onClose: () => void;
}

export function GlobalSearch({ onClose }: GlobalSearchProps) {
  const [query, setQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  
  const repairs = useRepairStore((state) => state.repairs);
  const customers = useCustomerStore((state) => state.customers);
  const inventory = useInventoryStore((state) => state.items);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        onClose();
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedIndex(i => Math.min(i + 1, results.length - 1));
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex(i => Math.max(i - 1, 0));
      }
      if (e.key === 'Enter' && results[selectedIndex]) {
        handleSelect(results[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [selectedIndex, query]);

  const results = [
    ...repairs
      .filter(r => 
        r.customerName.toLowerCase().includes(query.toLowerCase()) ||
        r.deviceBrand.toLowerCase().includes(query.toLowerCase()) ||
        r.deviceModel.toLowerCase().includes(query.toLowerCase()) ||
        r.imei?.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5)
      .map(r => ({
        type: 'repair' as const,
        id: r.id,
        title: `${r.deviceBrand} ${r.deviceModel}`,
        subtitle: r.customerName,
        status: r.status,
        date: new Date(r.createdAt).toLocaleDateString(),
        cost: r.estimatedCost,
        path: `/repairs?id=${r.id}`,
      })),
    ...customers
      .filter(c => 
        c.name.toLowerCase().includes(query.toLowerCase()) ||
        c.phone.toLowerCase().includes(query.toLowerCase()) ||
        c.email?.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5)
      .map(c => ({
        type: 'customer' as const,
        id: c.id,
        title: c.name,
        subtitle: c.phone,
        email: c.email,
        address: c.address,
        path: `/customers?id=${c.id}`,
      })),
    ...inventory
      .filter(i => 
        i.name.toLowerCase().includes(query.toLowerCase()) ||
        i.sku?.toLowerCase().includes(query.toLowerCase()) ||
        i.brand?.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5)
      .map(i => ({
        type: 'inventory' as const,
        id: i.id,
        title: i.name,
        subtitle: `Stock: ${i.stock}`,
        price: i.price,
        category: i.category,
        path: `/inventory?id=${i.id}`,
      })),
  ];

  const handleSelect = (result: typeof results[0]) => {
    navigate(result.path);
    onClose();
  };

  const getIcon = (type: 'repair' | 'customer' | 'inventory') => {
    switch (type) {
      case 'repair': return Smartphone;
      case 'customer': return User;
      case 'inventory': return Package;
    }
  };

  const renderResultDetails = (result: typeof results[0]) => {
    switch (result.type) {
      case 'repair':
        return (
          <div className="mt-2 grid grid-cols-3 gap-4 rounded-lg bg-gray-50 p-3 text-sm dark:bg-gray-800">
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4 text-gray-400" />
              <span>{result.status}</span>
            </div>
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4 text-gray-400" />
              <span>{result.date}</span>
            </div>
            <div className="flex items-center gap-2">
              <DollarSign className="h-4 w-4 text-gray-400" />
              <span>${result.cost}</span>
            </div>
          </div>
        );
      case 'customer':
        return (
          <div className="mt-2 space-y-2 rounded-lg bg-gray-50 p-3 text-sm dark:bg-gray-800">
            {result.email && (
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4 text-gray-400" />
                <span>{result.email}</span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <Phone className="h-4 w-4 text-gray-400" />
              <span>{result.subtitle}</span>
            </div>
          </div>
        );
      case 'inventory':
        return (
          <div className="mt-2 grid grid-cols-2 gap-4 rounded-lg bg-gray-50 p-3 text-sm dark:bg-gray-800">
            <div className="flex items-center gap-2">
              <Package className="h-4 w-4 text-gray-400" />
              <span>{result.category}</span>
            </div>
            <div className="flex items-center gap-2">
              <DollarSign className="h-4 w-4 text-gray-400" />
              <span>${result.price}</span>
            </div>
          </div>
        );
    }
  };

  const groupedResults = results.reduce((acc, result) => {
    const group = acc.find(g => g.type === result.type);
    if (group) {
      group.items.push(result);
    } else {
      acc.push({ type: result.type, items: [result] });
    }
    return acc;
  }, [] as { type: string; items: typeof results }[]);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto p-4 pt-[20vh]" ref={searchRef}>
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      
      <div className="relative mx-auto max-w-2xl rounded-xl bg-white shadow-2xl dark:bg-gray-800">
        <div className="flex items-center border-b px-4 dark:border-gray-700">
          <Search className="h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar reparaciones, clientes o inventario..."
            className="w-full border-0 bg-transparent px-4 py-4 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 dark:text-white dark:placeholder-gray-400"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            autoFocus
          />
          <div className="flex items-center gap-2">
            <kbd className="hidden rounded border border-gray-200 bg-gray-100 px-2 py-1 text-xs text-gray-500 dark:border-gray-700 dark:bg-gray-800 sm:inline-block">
              ⌘K
            </kbd>
            <button
              onClick={onClose}
              className="rounded-lg p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {query && (
          <div className="max-h-[60vh] overflow-y-auto p-2">
            {groupedResults.length > 0 ? (
              <div className="space-y-4">
                {groupedResults.map((group) => (
                  <div key={group.type}>
                    <div className="mb-2 px-3 text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
                      {group.type === 'repair' && 'Reparaciones'}
                      {group.type === 'customer' && 'Clientes'}
                      {group.type === 'inventory' && 'Inventario'}
                    </div>
                    <div className="space-y-1">
                      {group.items.map((result, index) => {
                        const Icon = getIcon(result.type);
                        const isSelected = selectedIndex === results.findIndex(r => r.id === result.id);
                        return (
                          <div key={`${result.type}-${result.id}`}>
                            <button
                              className={cn(
                                "flex w-full items-center gap-3 rounded-lg px-3 py-2 text-left text-sm transition-colors",
                                isSelected
                                  ? "bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400"
                                  : "text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                              )}
                              onClick={() => handleSelect(result)}
                              onMouseEnter={() => {
                                setSelectedIndex(results.findIndex(r => r.id === result.id));
                                setSelectedSection(result.type);
                              }}
                            >
                              <Icon className="h-5 w-5" />
                              <div className="flex-1">
                                <div className="font-medium">{result.title}</div>
                                <div className="text-xs text-gray-500 dark:text-gray-400">
                                  {result.subtitle}
                                </div>
                              </div>
                              <ArrowRight className="h-4 w-4" />
                            </button>
                            {isSelected && renderResultDetails(result)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-center text-sm text-gray-500 dark:text-gray-400">
                No se encontraron resultados para "{query}"
              </div>
            )}
          </div>
        )}

        {!query && (
          <div className="p-4 text-center text-sm text-gray-500 dark:text-gray-400">
            Comienza a escribir para buscar...
          </div>
        )}

        <div className="border-t p-4 dark:border-gray-700">
          <div className="flex items-center justify-center gap-4 text-xs text-gray-500">
            <div className="flex items-center gap-1">
              <span>↑↓</span>
              <span>navegar</span>
            </div>
            <div className="flex items-center gap-1">
              <span>↵</span>
              <span>seleccionar</span>
            </div>
            <div className="flex items-center gap-1">
              <span>esc</span>
              <span>cerrar</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}