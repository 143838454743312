import React from 'react';
import { cn } from '../../lib/utils';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
  trend?: {
    value: number;
    isPositive: boolean;
  };
  chart?: React.ReactNode;
  className?: string;
}

export function StatCard({ title, value, icon: Icon, trend, chart, className }: StatCardProps) {
  return (
    <div className={cn(
      "group relative overflow-hidden rounded-xl border bg-white p-6 transition-all hover:shadow-lg dark:border-gray-700 dark:bg-gray-800",
      "hover:-translate-y-1 hover:border-blue-100 dark:hover:border-blue-900",
      className
    )}>
      <div className="relative z-10 flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</p>
          <p className="mt-2 text-3xl font-semibold text-gray-900 dark:text-white">{value}</p>
        </div>
        <div className="rounded-full bg-blue-50 p-3 transition-colors group-hover:bg-blue-100 dark:bg-blue-900/20 dark:group-hover:bg-blue-900/30">
          <Icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
        </div>
      </div>

      <div className="relative z-10 mt-4 flex items-center gap-2">
        {trend && (
          <span
            className={cn(
              "flex items-center gap-1 rounded-full px-2 py-0.5 text-sm font-medium",
              trend.isPositive 
                ? "bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400"
                : "bg-red-50 text-red-600 dark:bg-red-900/20 dark:text-red-400"
            )}
          >
            {trend.isPositive ? "+" : "-"}{Math.abs(trend.value)}%
          </span>
        )}
        <span className="text-sm text-gray-500 dark:text-gray-400">vs último mes</span>
      </div>

      {chart && (
        <div className="absolute bottom-0 right-0 -mb-3 -mr-3 opacity-25 transition-opacity group-hover:opacity-50">
          {chart}
        </div>
      )}

      <div className="absolute inset-0 -z-10 bg-gradient-to-br from-blue-50/50 to-transparent opacity-0 transition-opacity group-hover:opacity-100 dark:from-blue-900/10" />
    </div>
  );
}