import React, { useState, useEffect } from 'react';
import { useNotificationStore } from '../../store/notifications';
import { 
  Bell, Check, AlertTriangle, Wrench, CheckCircle, 
  Package, Clock, X, ChevronDown 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { cn } from '../../lib/utils';

const notificationIcons = {
  status_update: Wrench,
  low_stock: Package,
  new_repair: Bell,
  repair_completed: CheckCircle,
  reminder: Clock,
};

const notificationColors = {
  status_update: 'bg-blue-50 text-blue-600 dark:bg-blue-900/20 dark:text-blue-400',
  low_stock: 'bg-yellow-50 text-yellow-600 dark:bg-yellow-900/20 dark:text-yellow-400',
  new_repair: 'bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400',
  repair_completed: 'bg-purple-50 text-purple-600 dark:bg-purple-900/20 dark:text-purple-400',
  reminder: 'bg-orange-50 text-orange-600 dark:bg-orange-900/20 dark:text-orange-400',
};

export function NotificationCenter() {
  const {
    notifications,
    unreadCount,
    markAsRead,
    markAllAsRead,
    removeNotification,
  } = useNotificationStore();

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<'all' | 'unread'>('all');
  const [showOlder, setShowOlder] = useState(false);

  // Auto-close when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.notification-center')) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const filteredNotifications = notifications.filter(notification => 
    filter === 'all' || !notification.read
  );

  const recentNotifications = filteredNotifications.slice(0, 5);
  const olderNotifications = filteredNotifications.slice(5);

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  return (
    <div className="notification-center relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative rounded-full p-2 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-800"
      >
        <Bell className="h-5 w-5" />
        <AnimatePresence>
          {unreadCount > 0 && (
            <motion.span
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white"
            >
              {unreadCount}
            </motion.span>
          )}
        </AnimatePresence>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute right-0 z-50 mt-2 w-96 rounded-lg border bg-white shadow-xl dark:border-gray-700 dark:bg-gray-800"
          >
            {/* Header */}
            <div className="flex items-center justify-between border-b p-4 dark:border-gray-700">
              <div>
                <h3 className="font-medium">Notificaciones</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {unreadCount} sin leer
                </p>
              </div>
              <div className="flex items-center gap-2">
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value as 'all' | 'unread')}
                  className="rounded-md border border-gray-300 bg-transparent px-2 py-1 text-sm dark:border-gray-600"
                >
                  <option value="all">Todas</option>
                  <option value="unread">Sin leer</option>
                </select>
                {unreadCount > 0 && (
                  <button
                    onClick={handleMarkAllAsRead}
                    className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                  >
                    Marcar como leídas
                  </button>
                )}
              </div>
            </div>

            {/* Notifications List */}
            <div className="max-h-[60vh] overflow-y-auto">
              {filteredNotifications.length === 0 ? (
                <div className="flex flex-col items-center justify-center p-8">
                  <Bell className="h-12 w-12 text-gray-400" />
                  <p className="mt-2 text-gray-500 dark:text-gray-400">
                    No hay notificaciones
                  </p>
                </div>
              ) : (
                <div className="divide-y dark:divide-gray-700">
                  {recentNotifications.map((notification) => {
                    const Icon = notificationIcons[notification.type];
                    return (
                      <div
                        key={notification.id}
                        className={cn(
                          "group relative flex items-start gap-3 p-4 transition-colors hover:bg-gray-50 dark:hover:bg-gray-700/50",
                          !notification.read && "bg-blue-50/50 dark:bg-blue-900/20"
                        )}
                      >
                        <div className={cn(
                          "flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full",
                          notificationColors[notification.type]
                        )}>
                          <Icon className="h-4 w-4" />
                        </div>
                        <div className="flex-1 space-y-1">
                          <div className="flex items-start justify-between gap-2">
                            <p className="font-medium text-gray-900 dark:text-white">
                              {notification.title}
                            </p>
                            <span className="text-xs text-gray-500">
                              {format(new Date(notification.createdAt), 'PPp', { locale: es })}
                            </span>
                          </div>
                          <p className="text-sm text-gray-600 dark:text-gray-300">
                            {notification.message}
                          </p>
                        </div>
                        <div className="absolute right-2 top-2 flex opacity-0 transition-opacity group-hover:opacity-100">
                          {!notification.read && (
                            <button
                              onClick={() => markAsRead(notification.id)}
                              className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                              title="Marcar como leída"
                            >
                              <Check className="h-4 w-4" />
                            </button>
                          )}
                          <button
                            onClick={() => removeNotification(notification.id)}
                            className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                            title="Eliminar"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    );
                  })}

                  {olderNotifications.length > 0 && (
                    <>
                      <button
                        onClick={() => setShowOlder(!showOlder)}
                        className="flex w-full items-center justify-center gap-2 p-2 text-sm text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700"
                      >
                        <span>
                          {showOlder ? 'Ocultar anteriores' : 'Mostrar más'}
                        </span>
                        <ChevronDown className={cn(
                          "h-4 w-4 transition-transform",
                          showOlder && "rotate-180"
                        )} />
                      </button>

                      <AnimatePresence>
                        {showOlder && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                            className="overflow-hidden"
                          >
                            {olderNotifications.map((notification) => {
                              const Icon = notificationIcons[notification.type];
                              return (
                                <div
                                  key={notification.id}
                                  className={cn(
                                    "group relative flex items-start gap-3 p-4 transition-colors hover:bg-gray-50 dark:hover:bg-gray-700/50",
                                    !notification.read && "bg-blue-50/50 dark:bg-blue-900/20"
                                  )}
                                >
                                  <div className={cn(
                                    "flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full",
                                    notificationColors[notification.type]
                                  )}>
                                    <Icon className="h-4 w-4" />
                                  </div>
                                  <div className="flex-1 space-y-1">
                                    <div className="flex items-start justify-between gap-2">
                                      <p className="font-medium text-gray-900 dark:text-white">
                                        {notification.title}
                                      </p>
                                      <span className="text-xs text-gray-500">
                                        {format(new Date(notification.createdAt), 'PPp', { locale: es })}
                                      </span>
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-300">
                                      {notification.message}
                                    </p>
                                  </div>
                                  <div className="absolute right-2 top-2 flex opacity-0 transition-opacity group-hover:opacity-100">
                                    {!notification.read && (
                                      <button
                                        onClick={() => markAsRead(notification.id)}
                                        className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                                        title="Marcar como leída"
                                      >
                                        <Check className="h-4 w-4" />
                                      </button>
                                    )}
                                    <button
                                      onClick={() => removeNotification(notification.id)}
                                      className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                                      title="Eliminar"
                                    >
                                      <X className="h-4 w-4" />
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}