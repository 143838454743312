import React from 'react';
import { RepairOrder } from '../../types/repair';
import { Clock, CheckCircle, Wrench, Package } from 'lucide-react';

const statusIcons = {
  pending: Clock,
  in_progress: Wrench,
  completed: CheckCircle,
  delivered: Package,
};

const statusColors = {
  pending: 'text-yellow-500 bg-yellow-50 dark:bg-yellow-900/20',
  in_progress: 'text-blue-500 bg-blue-50 dark:bg-blue-900/20',
  completed: 'text-green-500 bg-green-50 dark:bg-green-900/20',
  delivered: 'text-purple-500 bg-purple-50 dark:bg-purple-900/20',
};

interface RecentRepairsProps {
  repairs: RepairOrder[];
}

export function RecentRepairs({ repairs }: RecentRepairsProps) {
  const recentRepairs = [...repairs]
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .slice(0, 5);

  return (
    <div className="space-y-3">
      {recentRepairs.map((repair) => {
        const StatusIcon = statusIcons[repair.status];
        return (
          <div
            key={repair.id}
            className="group flex items-center justify-between rounded-lg border p-4 transition-all hover:border-blue-200 hover:bg-blue-50/50 dark:border-gray-700 dark:hover:border-blue-900 dark:hover:bg-blue-900/20"
          >
            <div className="flex items-center gap-4">
              <div className={`rounded-full p-2 transition-colors ${statusColors[repair.status]}`}>
                <StatusIcon className="h-5 w-5" />
              </div>
              <div>
                <div className="font-medium text-gray-900 dark:text-white">
                  {repair.customerName}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {repair.deviceBrand} {repair.deviceModel}
                </div>
              </div>
            </div>
            <div className="text-right">
              <div className="font-medium text-gray-900 dark:text-white">
                ${repair.estimatedCost}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {new Date(repair.createdAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}