import React, { useState } from 'react';
import { useWholesaleClientsStore } from '../store/wholesaleClients';
import { useRepairStore } from '../store/repairs';
import { Building2, Search, Clock, ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export function WholesalePortal() {
  const [accessCode, setAccessCode] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const getClientByAccessCode = useWholesaleClientsStore((state) => state.getClientByAccessCode);
  const getClientRepairs = useWholesaleClientsStore((state) => state.getClientRepairs);
  const repairs = useRepairStore((state) => state.repairs);

  const handleLogin = () => {
    const client = getClientByAccessCode(accessCode);
    if (client) {
      setIsAuthenticated(true);
      toast.success('Bienvenido al portal mayorista');
    } else {
      toast.error('Código de acceso inválido');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex min-h-screen flex-col bg-gray-50 px-4 dark:bg-gray-900">
        <div className="container mx-auto py-8">
          <Link 
            to="/"
            className="mb-8 inline-flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <ArrowLeft className="h-4 w-4" />
            Volver al inicio
          </Link>
        </div>

        <div className="flex flex-1 items-center justify-center">
          <div className="w-full max-w-md space-y-8">
            <div className="text-center">
              <Building2 className="mx-auto h-12 w-12 text-blue-600" />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                Portal de Clientes Mayoristas
              </h2>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Ingresa tu código de acceso para ver el estado de tus reparaciones
              </p>
            </div>

            <div className="mt-8">
              <div className="space-y-4">
                <input
                  type="text"
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value.toUpperCase())}
                  placeholder="Código de acceso"
                  className="block w-full rounded-lg border border-gray-300 px-4 py-3 text-center text-lg uppercase placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
                <button
                  onClick={handleLogin}
                  className="w-full rounded-lg bg-blue-600 px-4 py-3 text-sm font-medium text-white hover:bg-blue-700"
                >
                  Acceder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const client = getClientByAccessCode(accessCode)!;
  const clientRepairs = getClientRepairs(client.id, repairs);
  
  const filteredRepairs = clientRepairs.filter((repair) =>
    repair.deviceBrand.toLowerCase().includes(searchTerm.toLowerCase()) ||
    repair.deviceModel.toLowerCase().includes(searchTerm.toLowerCase()) ||
    repair.status.includes(searchTerm.toLowerCase())
  );

  return (
  
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Bienvenido, {client.businessName}
              </h1>
              <p className="text-gray-600 dark:text-gray-400">
                Aquí puedes ver el estado de tus reparaciones
              </p>
            </div>
            <button
              onClick={() => setIsAuthenticated(false)}
              className="rounded-lg border border-gray-300 px-4 py-2 text-sm text-gray-600 hover:text-gray-900 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white"
            >
              Cerrar Sesión
            </button>
          </div>
        </div>

        <div className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Buscar reparaciones..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full rounded-lg border border-gray-300 pl-10 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
        </div>

        <div className="overflow-hidden rounded-lg border bg-white shadow dark:border-gray-700 dark:bg-gray-800">
          <div className="px-4 py-5 sm:p-6">
            {filteredRepairs.length === 0 ? (
              <div className="text-center">
                <Clock className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
                  No hay reparaciones activas
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Tus reparaciones aparecerán aquí cuando las tengas
                </p>
              </div>
            ) : (
              <div className="flow-root">
                <ul className="-my-5 divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredRepairs.map((repair) => (
                    <li key={repair.id} className="py-5">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                            {repair.deviceBrand} {repair.deviceModel}
                          </h3>
                          <div className="mt-1 flex items-center gap-4">
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              Orden #{repair.id}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {format(new Date(repair.createdAt), 'dd/MM/yyyy')}
                            </span>
                          </div>
                          <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                            {repair.issueDescription}
                          </p>
                        </div>
                        <div className="ml-4">
                          <span
                            className={`inline-flex rounded-full px-3 py-1 text-sm font-medium ${
                              repair.status === 'pending'
                                ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-400'
                                : repair.status === 'in_progress'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-400'
                                : repair.status === 'completed'
                                ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400'
                                : 'bg-purple-100 text-purple-800 dark:bg-purple-900/20 dark:text-purple-400'
                            }`}
                          >
                            {repair.status === 'pending' && 'Pendiente'}
                            {repair.status === 'in_progress' && 'En Progreso'}
                            {repair.status === 'completed' && 'Completado'}
                            {repair.status === 'delivered' && 'Entregado'}
                          </span>
                          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7453628827116663"
     crossOrigin="anonymous"></script>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}