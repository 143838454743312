import React, { useState } from 'react';
import { useSalesStore } from '../../store/sales';
import { useInventoryStore } from '../../store/inventory';
import { SaleItem } from '../../types/sale';
import { Search, Plus, Minus, Trash2, DollarSign, CreditCard, Banknote } from 'lucide-react';
import { SaleReceipt } from './SaleReceipt';
import { cn } from '../../lib/utils';

interface SaleFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

export function SaleForm({ onSuccess, onCancel }: SaleFormProps) {
  const [items, setItems] = useState<SaleItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<'cash' | 'card' | 'transfer'>('cash');
  const [notes, setNotes] = useState('');
  const [showReceipt, setShowReceipt] = useState(false);
  const [currentSale, setCurrentSale] = useState<any>(null);

  const inventory = useInventoryStore((state) => state.items);
  const addSale = useSalesStore((state) => state.addSale);
  const updateStock = useInventoryStore((state) => state.updateStock);

  const filteredProducts = inventory.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.sku?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const total = items.reduce((sum, item) => sum + item.total, 0);

  const handleAddItem = (productId: string) => {
    const product = inventory.find((p) => p.id === productId);
    if (!product) return;

    const existingItem = items.find((item) => item.productId === productId);
    if (existingItem) {
      setItems(items.map((item) =>
        item.productId === productId
          ? {
              ...item,
              quantity: item.quantity + 1,
              total: (item.quantity + 1) * item.price,
            }
          : item
      ));
    } else {
      setItems([
        ...items,
        {
          id: Math.random().toString(36).substring(7),
          productId,
          name: product.name,
          quantity: 1,
          price: product.price,
          total: product.price,
        },
      ]);
    }
    setSearchTerm('');
  };

  const handleUpdateQuantity = (itemId: string, newQuantity: number) => {
    if (newQuantity < 1) return;
    
    const product = inventory.find((p) => 
      p.id === items.find((item) => item.id === itemId)?.productId
    );
    
    if (!product || newQuantity > product.stock) {
      alert('No hay suficiente stock disponible');
      return;
    }

    setItems(items.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantity: newQuantity,
            total: newQuantity * item.price,
          }
        : item
    ));
  };

  const handleRemoveItem = (itemId: string) => {
    setItems(items.filter((item) => item.id !== itemId));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (items.length === 0) {
      alert('Debe agregar al menos un producto');
      return;
    }

    // Verificar stock
    for (const item of items) {
      const product = inventory.find((p) => p.id === item.productId);
      if (!product || item.quantity > product.stock) {
        alert(`No hay suficiente stock de ${item.name}`);
        return;
      }
    }

    // Crear venta
    const sale = addSale({
      items,
      total,
      paymentMethod,
      status: 'completed',
      customerName: customerName || undefined,
      customerPhone: customerPhone || undefined,
      notes: notes || undefined,
    });

    // Actualizar inventario
    items.forEach((item) => {
      updateStock(item.productId, -item.quantity);
    });

    setCurrentSale(sale);
    setShowReceipt(true);
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setCurrentSale(null);
    onSuccess();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Cliente */}
        <div className="grid gap-4 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Nombre del Cliente
            </label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Teléfono
            </label>
            <input
              type="tel"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
        </div>

        {/* Búsqueda de Productos */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Agregar Productos
          </label>
          <div className="relative mt-1">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar productos..."
              className="block w-full rounded-md border border-gray-300 pl-10 pr-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>
          {searchTerm && (
            <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-gray-300 bg-white shadow-lg dark:border-gray-600 dark:bg-gray-800">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <button
                    key={product.id}
                    type="button"
                    onClick={() => handleAddItem(product.id)}
                    className="flex w-full items-center justify-between px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <div>
                      <div className="font-medium">{product.name}</div>
                      <div className="text-sm text-gray-500">Stock: {product.stock}</div>
                    </div>
                    <div className="text-blue-600">${product.price}</div>
                  </button>
                ))
              ) : (
                <div className="px-4 py-2 text-gray-500">No se encontraron productos</div>
              )}
            </div>
          )}
        </div>

        {/* Lista de Items */}
        {items.length > 0 && (
          <div className="rounded-lg border dark:border-gray-700">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
                    Producto
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
                    Cantidad
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
                    Precio
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
                    Total
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
                {items.map((item) => (
                  <tr key={item.id}>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900 dark:text-gray-200">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="flex items-center gap-2">
                        <button
                          type="button"
                          onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}
                          className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                          <Minus className="h-4 w-4" />
                        </button>
                        <span className="w-8 text-center text-sm">{item.quantity}</span>
                        <button
                          type="button"
                          onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}
                          className="rounded-full p-1 text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                          <Plus className="h-4 w-4" />
                        </button>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900 dark:text-gray-200">
                      ${item.price}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900 dark:text-gray-200">
                      ${item.total}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(item.id)}
                        className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Método de Pago */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Método de Pago
          </label>
          <div className="mt-1 grid grid-cols-3 gap-3">
            <button
              type="button"
              onClick={() => setPaymentMethod('cash')}
              className={cn(
                "flex flex-col items-center gap-2 rounded-lg border p-4 text-sm",
                paymentMethod === 'cash'
                  ? "border-blue-600 bg-blue-50 text-blue-600 dark:border-blue-500 dark:bg-blue-900/20"
                  : "border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800"
              )}
            >
              <Banknote className="h-5 w-5" />
              Efectivo
            </button>
            <button
              type="button"
              onClick={() => setPaymentMethod('card')}
              className={cn(
                "flex flex-col items-center gap-2 rounded-lg border p-4 text-sm",
                paymentMethod === 'card'
                  ? "border-blue-600 bg-blue-50 text-blue-600 dark:border-blue-500 dark:bg-blue-900/20"
                  : "border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800"
              )}
            >
              <CreditCard className="h-5 w-5" />
              Tarjeta
            </button>
            <button
              type="button"
              onClick={() => setPaymentMethod('transfer')}
              className={cn(
                "flex flex-col items-center gap-2 rounded-lg border p-4 text-sm",
                paymentMethod === 'transfer'
                  ? "border-blue-600 bg-blue-50 text-blue-600 dark:border-blue-500 dark:bg-blue-900/20"
                  : "border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800"
              )}
            >
              <DollarSign className="h-5 w-5" />
              Transferencia
            </button>
          </div>
        </div>

        {/* Notas */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Notas
          </label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            rows={3}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
          />
        </div>

        {/* Total y Acciones */}
        <div className="flex items-center justify-between border-t pt-4 dark:border-gray-700">
          <div className="text-2xl font-bold">
            Total: ${total.toFixed(2)}
          </div>
          <div className="flex gap-3">
            <button
              type="button"
              onClick={onCancel}
              className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
            >
              Completar Venta
            </button>
          </div>
        </div>
      </form>

      {showReceipt && currentSale && (
        <SaleReceipt
          sale={currentSale}
          onClose={handleCloseReceipt}
        />
      )}
    </>
  );
}