import React from 'react';
import { RepairOrder } from '../types/repair';
import { Customer } from '../types/customer';
import { Company } from '../types/settings';

interface RepairDocumentProps {
  repair: RepairOrder;
  company: Company;
  format?: 'receipt' | 'ticket-58' | 'ticket-80';
  options?: {
    includeLogo?: boolean;
    includeBarcode?: boolean;
    includeFooter?: boolean;
  };
}

const RepairDocument: React.FC<RepairDocumentProps> = ({ 
  repair, 
  company, 
  format = 'receipt', 
  options = {} 
}) => {
  const { 
    includeLogo = true, 
    includeBarcode = true, 
    includeFooter = true 
  } = options;

  const renderReceipt = () => {
    return (
      <div className="bg-white p-8 text-black">
        {includeLogo && company.logo && (
          <img 
            src={company.logo} 
            alt={company.name} 
            className="mx-auto mb-4 h-16"
            crossOrigin="anonymous"
          />
        )}
        <h2 className="text-xl font-bold text-center">{company.name}</h2>
        
        <div className="mt-4 space-y-2">
          <p><strong>Orden #:</strong> {repair.id}</p>
          <p><strong>Fecha:</strong> {new Date(repair.createdAt).toLocaleDateString()}</p>
          <p><strong>Cliente:</strong> {repair.customerName}</p>
          <p><strong>Teléfono:</strong> {repair.phone}</p>
          <p><strong>Dispositivo:</strong> {repair.deviceBrand} {repair.deviceModel}</p>
          
          <div className="mt-4">
            <p><strong>Descripción del Problema:</strong></p>
            <p>{repair.issueDescription}</p>
          </div>

          <div className="mt-4">
            <p><strong>Costo Estimado:</strong> ${repair.estimatedCost.toFixed(2)}</p>
            {repair.advancePayment !== undefined && repair.advancePayment > 0 && (
              <p><strong>Anticipo:</strong> ${repair.advancePayment.toFixed(2)}</p>
            )}
            {repair.advancePayment !== undefined && repair.advancePayment > 0 && (
              <p><strong>Saldo:</strong> ${(repair.estimatedCost - repair.advancePayment).toFixed(2)}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  switch (format) {
    case 'receipt':
      return renderReceipt();
    default:
      return renderReceipt();
  }
};

export default RepairDocument;
