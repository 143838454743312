import React, { useState, useEffect } from 'react';
import { useRepairStore } from '../store/repairs';
import { useInventoryStore } from '../store/inventory';
import { BarChart3, DollarSign, Clock, Package, Download, FileText } from 'lucide-react';
import { StatCard } from '../components/dashboard/StatCard';
import { RevenueChart } from '../components/reports/RevenueChart';
import { RepairTimeChart } from '../components/reports/RepairTimeChart';
import { InventoryValueChart } from '../components/reports/InventoryValueChart';
import { TechnicianPerformance } from '../components/reports/TechnicianPerformance';
import { ComparisonChart } from '../components/reports/ComparisonChart';
import { DateRangeSelector } from '../components/reports/DateRangeSelector';
import { generateExcelReport } from '../utils/reports';

export function ReportsPage() {
  const [datePreset, setDatePreset] = useState('week');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const repairs = useRepairStore((state) => state.repairs);
  const inventory = useInventoryStore((state) => state.items);

  useEffect(() => {
    const now = new Date();
    switch (datePreset) {
      case 'today':
        setStartDate(new Date(now.setHours(0, 0, 0, 0)));
        setEndDate(new Date(now.setHours(23, 59, 59, 999)));
        break;
      case 'week':
        setStartDate(new Date(now.setDate(now.getDate() - 7)));
        setEndDate(new Date());
        break;
      case 'month':
        setStartDate(new Date(now.setMonth(now.getMonth() - 1)));
        setEndDate(new Date());
        break;
      case 'quarter':
        setStartDate(new Date(now.setMonth(now.getMonth() - 3)));
        setEndDate(new Date());
        break;
      case 'year':
        setStartDate(new Date(now.setFullYear(now.getFullYear() - 1)));
        setEndDate(new Date());
        break;
    }
  }, [datePreset]);

  const calculateMetrics = () => {
    const filteredRepairs = repairs.filter(repair => {
      const repairDate = new Date(repair.createdAt);
      return repairDate >= startDate && repairDate <= endDate;
    });

    const completedRepairs = filteredRepairs.filter(r => r.status === 'completed');
    const totalRevenue = filteredRepairs.reduce((sum, repair) => sum + repair.estimatedCost, 0);
    const inventoryValue = inventory.reduce((sum, item) => sum + (item.cost * item.stock), 0);
    
    let averageRepairTime = 0;
    if (completedRepairs.length > 0) {
      const totalTime = completedRepairs.reduce((sum, repair) => {
        const start = new Date(repair.createdAt);
        const end = new Date(repair.updatedAt);
        return sum + (end.getTime() - start.getTime());
      }, 0);
      averageRepairTime = Math.round(totalTime / (completedRepairs.length * 1000 * 60 * 60 * 24));
    }

    return {
      totalRevenue,
      completedRepairs: completedRepairs.length,
      averageRepairTime,
      inventoryValue,
    };
  };

  const metrics = calculateMetrics();

  const handleExport = async (format: 'excel' | 'pdf') => {
    if (format === 'excel') {
      try {
        await generateExcelReport(repairs, inventory, `${startDate.toISOString()}_${endDate.toISOString()}`);
      } catch (error) {
        console.error('Error al generar el reporte:', error);
        // toast.error('Error al generar el reporte de Excel');
      }
    }
    // TODO: Implement PDF export
  };

  const getPreviousPeriod = () => {
    const duration = endDate.getTime() - startDate.getTime();
    const previousStart = new Date(startDate.getTime() - duration);
    const previousEnd = new Date(endDate.getTime() - duration);
    return { start: previousStart, end: previousEnd };
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Reportes y Estadísticas
        </h1>
        <div className="flex items-center gap-4">
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            preset={datePreset}
            onPresetChange={setDatePreset}
          />
          <div className="flex gap-2">
            <button
              onClick={() => handleExport('excel')}
              className="flex items-center gap-2 rounded-md bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-700"
            >
              <FileText className="h-4 w-4" />
              Excel
            </button>
            <button
              onClick={() => handleExport('pdf')}
              className="flex items-center gap-2 rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
            >
              <Download className="h-4 w-4" />
              PDF
            </button>
          </div>
        </div>
      </div>

      <div className="mb-8 grid gap-6 lg:grid-cols-4">
        <StatCard
          title="Ingresos Totales"
          value={`$${metrics.totalRevenue.toFixed(2)}`}
          icon={DollarSign}
          trend={{ value: 15, isPositive: true }}
        />
        <StatCard
          title="Reparaciones Completadas"
          value={metrics.completedRepairs}
          icon={BarChart3}
          trend={{ value: 8, isPositive: true }}
        />
        <StatCard
          title="Tiempo Promedio"
          value={`${metrics.averageRepairTime} días`}
          icon={Clock}
          trend={{ value: 10, isPositive: false }}
        />
        <StatCard
          title="Valor del Inventario"
          value={`$${metrics.inventoryValue.toFixed(2)}`}
          icon={Package}
          trend={{ value: 5, isPositive: true }}
        />
      </div>

      <div className="grid gap-6 lg:grid-cols-2">
        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Ingresos por Período</h3>
          <RevenueChart repairs={repairs} dateRange={datePreset} />
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Comparativa con Período Anterior</h3>
          <ComparisonChart
            repairs={repairs}
            currentPeriod={{ start: startDate, end: endDate }}
            previousPeriod={getPreviousPeriod()}
          />
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Rendimiento por Técnico</h3>
          <TechnicianPerformance repairs={repairs} dateRange={datePreset} />
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Tiempo de Reparación</h3>
          <RepairTimeChart repairs={repairs} dateRange={datePreset} />
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Valor del Inventario por Categoría</h3>
          <InventoryValueChart inventory={inventory} />
        </div>

        <div className="rounded-lg border bg-white p-6 dark:border-gray-700 dark:bg-gray-900">
          <h3 className="mb-4 text-lg font-medium">Estadísticas Detalladas</h3>
          <div className="space-y-4">
            <div className="flex justify-between border-b pb-2 dark:border-gray-700">
              <span className="text-gray-600 dark:text-gray-400">Tasa de Éxito</span>
              <span className="font-medium">
                {repairs.length > 0
                  ? ((metrics.completedRepairs / repairs.length) * 100).toFixed(1)
                  : 0}%
              </span>
            </div>
            <div className="flex justify-between border-b pb-2 dark:border-gray-700">
              <span className="text-gray-600 dark:text-gray-400">Reparaciones en Progreso</span>
              <span className="font-medium">
                {repairs.filter(r => r.status === 'in_progress').length}
              </span>
            </div>
            <div className="flex justify-between border-b pb-2 dark:border-gray-700">
              <span className="text-gray-600 dark:text-gray-400">Pendientes</span>
              <span className="font-medium">
                {repairs.filter(r => r.status === 'pending').length}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600 dark:text-gray-400">Ingreso Promedio por Reparación</span>
              <span className="font-medium">
                ${repairs.length > 0
                  ? (metrics.totalRevenue / repairs.length).toFixed(2)
                  : '0.00'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}