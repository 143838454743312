import React, { useState } from 'react';
import { useInventoryStore } from '../store/inventory';
import { PlusCircle, Package, Search, AlertTriangle } from 'lucide-react';
import { InventoryForm } from '../components/inventory/InventoryForm';
import { StockAdjustment } from '../components/inventory/StockAdjustment';
import { InventoryItem } from '../types/inventory';

export function InventoryPage() {
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
  const items = useInventoryStore((state) => state.items);
  const lowStockItems = useInventoryStore((state) => state.getLowStockItems());

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.sku?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Inventario
        </h1>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
        >
          <PlusCircle className="h-5 w-5" />
          Nuevo Artículo
        </button>
      </div>

      {lowStockItems.length > 0 && (
        <div className="mb-6 rounded-lg border border-yellow-200 bg-yellow-50 p-4 dark:border-yellow-900/50 dark:bg-yellow-900/20">
          <div className="flex items-center gap-2 text-yellow-800 dark:text-yellow-200">
            <AlertTriangle className="h-5 w-5" />
            <h2 className="font-medium">Alerta de Stock Bajo</h2>
          </div>
          <div className="mt-2 space-y-1">
            {lowStockItems.map((item) => (
              <p key={item.id} className="text-sm text-yellow-700 dark:text-yellow-300">
                {item.name} - Stock actual: {item.stock} (Mínimo: {item.minStock})
              </p>
            ))}
          </div>
        </div>
      )}

      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar por nombre o SKU..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-lg border border-gray-300 pl-10 pr-4 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
          />
        </div>
      </div>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredItems.map((item) => (
          <div
            key={item.id}
            className="rounded-lg border bg-white p-6 shadow-sm dark:bg-gray-900"
          >
            <div className="flex items-center justify-between">
              <h3 className="font-medium text-gray-900 dark:text-white">
                {item.name}
              </h3>
              <Package className="h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500 dark:text-gray-400">Stock:</span>
                <span className={`font-medium ${
                  item.stock <= item.minStock
                    ? 'text-red-600 dark:text-red-400'
                    : 'text-gray-900 dark:text-white'
                }`}>
                  {item.stock}
                </span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500 dark:text-gray-400">Precio:</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  ${item.price}
                </span>
              </div>
              {item.sku && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500 dark:text-gray-400">SKU:</span>
                  <span className="font-medium text-gray-900 dark:text-white">
                    {item.sku}
                  </span>
                </div>
              )}
              <div className="flex justify-between text-sm">
                <span className="text-gray-500 dark:text-gray-400">Categoría:</span>
                <span className="font-medium text-gray-900 dark:text-white">
                  {item.category}
                </span>
              </div>
            </div>
            <div className="mt-4 flex justify-end gap-2">
              <button 
                onClick={() => setSelectedItem(item)}
                className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Ajustar Stock
              </button>
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-4xl rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <h2 className="mb-4 text-lg font-medium text-gray-900 dark:text-white">
              Nuevo Artículo de Inventario
            </h2>
            <InventoryForm
              onSuccess={() => setShowForm(false)}
              onCancel={() => setShowForm(false)}
            />
          </div>
        </div>
      )}

      {selectedItem && (
        <StockAdjustment
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
}