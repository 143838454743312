import { RepairOrder } from '../types/repair';
import { Sale } from '../types/sale';
import { CompanySettings } from '../store/settings';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Customer } from '../types/customer';
import { renderToString } from 'react-dom/server';
import { RepairDocumentGenerator } from '../documents/RepairDocumentGenerator';
import { toast } from 'react-toastify';
import * as React from 'react'; // Import React

export async function printRepairReceipt(
  repair: RepairOrder,
  company: CompanySettings,
  options: {
    includeLogo: boolean;
    includeBarcode: boolean;
    includeFooter: boolean;
    copies: number;
    format?: 'receipt' | 'ticket-58' | 'ticket-80' | 'compact' | 'detailed' | 'sticker' | 'service-label';
  }
): Promise<void> {
  try {
    const customerData: Customer = {
      id: '', 
      name: repair.customerName,
      phone: repair.phone,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const content = options.format === 'sticker' 
      ? generateStickerHTML(repair, company)
      : renderToString(
          React.createElement(RepairDocumentGenerator, {
            repair,
            customer: customerData,
            company,
            format: options.format === 'service-label' ? 'receipt' : options.format,
            options: {
              includeLogo: options.includeLogo,
              includeBarcode: options.includeBarcode,
              includeFooter: options.includeFooter
            }
          })
        );
    
    const success = await printDocument(content, options.copies);
    if (success) {
      toast.success('Impreso correctamente');
    }
  } catch (error) {
    console.error('Error al imprimir:', error);
    toast.error('Error al imprimir. Por favor, inténtalo de nuevo.');
    throw error;
  }
}

export async function printSaleReceipt(
  sale: Sale,
  company: CompanySettings,
  options: {
    includeLogo: boolean;
    includeBarcode: boolean;
    includeFooter: boolean;
    copies: number;
  }
): Promise<void> {
  try {
    const content = generateSaleReceiptHTML(sale, company, options);
    const success = await printDocument(content, options.copies);
    if (success) {
      toast.success('Impreso correctamente');
    }
  } catch (error) {
    console.error('Error al imprimir:', error);
    toast.error('Error al imprimir. Por favor, inténtalo de nuevo.');
    throw error;
  }
}

function generateRepairReceiptHTML(
  repair: RepairOrder,
  company: CompanySettings,
  options: {
    includeLogo: boolean;
    includeBarcode: boolean;
    includeFooter: boolean;
  }
): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Recibo #${repair.id}</title>
        <style>
          @page {
            size: 80mm 297mm;
            margin: 0;
          }
          @media print {
            * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              font-size: 10pt;
            }
            body {
              padding: 10mm;
            }
            .logo {
              text-align: center;
              margin-bottom: 5mm;
            }
            .logo img {
              max-width: 30mm;
              max-height: 30mm;
            }
            .header {
              text-align: center;
              margin-bottom: 5mm;
            }
            .content {
              margin: 5mm 0;
            }
            .field {
              margin-bottom: 2mm;
            }
            .label {
              font-weight: bold;
            }
            .footer {
              margin-top: 10mm;
              text-align: center;
              font-size: 8pt;
            }
            .barcode {
              text-align: center;
              margin: 5mm 0;
              padding: 2mm;
              border: 1px solid black;
            }
          }
        </style>
      </head>
      <body>
        ${options.includeLogo && company.logo ? `
          <div class="logo">
            <img src="${company.logo}" alt="${company.name}">
          </div>
        ` : ''}
        
        <div class="header">
          <h1>${company.name}</h1>
          <p>${company.contact.address || ''}</p>
          <p>Tel: ${company.contact.phone || ''}</p>
        </div>

        <div class="content">
          <div class="field">
            <span class="label">Orden #:</span> ${repair.id}
          </div>
          <div class="field">
            <span class="label">Fecha:</span> ${format(new Date(repair.createdAt), 'PPP', { locale: es })}
          </div>
          <div class="field">
            <span class="label">Cliente:</span> ${repair.customerName}
          </div>
          <div class="field">
            <span class="label">Teléfono:</span> ${repair.phone}
          </div>
          <div class="field">
            <span class="label">Dispositivo:</span> ${repair.deviceBrand} ${repair.deviceModel}
          </div>
          ${repair.imei ? `
            <div class="field">
              <span class="label">IMEI:</span> ${repair.imei}
            </div>
          ` : ''}
          ${repair.devicePassword ? `
            <div class="field">
              <span class="label">Contraseña:</span> ${repair.devicePassword}
            </div>
          ` : ''}
          <div class="field">
            <span class="label">Problema:</span><br>
            ${repair.issueDescription}
          </div>
          ${repair.accessories?.length ? `
            <div class="field">
              <span class="label">Accesorios:</span><br>
              ${repair.accessories.join(', ')}
            </div>
          ` : ''}
          <div class="field">
            <span class="label">Costo Estimado:</span> $${repair.estimatedCost.toFixed(2)}
          </div>
          ${repair.technician ? `
            <div class="field">
              <span class="label">Técnico:</span> ${repair.technician}
            </div>
          ` : ''}
        </div>

        ${options.includeBarcode ? `
          <div class="barcode">
            ${repair.id}
          </div>
        ` : ''}

        ${options.includeFooter && company.printer.receiptFooter ? `
          <div class="footer">
            ${company.printer.receiptFooter}
          </div>
        ` : ''}
      </body>
    </html>
  `;
}

function generateStickerHTML(
  repair: RepairOrder,
  company: CompanySettings
): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Sticker #${repair.id}</title>
        <style>
          @page {
            size: 80mm 50mm;
            margin: 0;
          }
          @media print {
            * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              font-size: 8pt;
            }
            body {
              width: 80mm;
              height: 50mm;
              padding: 4mm;
            }
            .container {
              display: flex;
              justify-content: space-between;
            }
            .info {
              flex: 1;
            }
            .logo {
              width: 20mm;
              text-align: center;
            }
            .logo img {
              max-width: 16mm;
              max-height: 16mm;
            }
            .field {
              margin-bottom: 1mm;
            }
            .label {
              font-weight: bold;
            }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="info">
            <div class="field">
              <span class="label">Cliente:</span>
              ${repair.customerName}
            </div>
            <div class="field">
              <span class="label">Tel:</span>
              ${repair.phone}
            </div>
            <div class="field">
              <span class="label"># Orden:</span>
              ${repair.id}
            </div>
            <div class="field">
              <span class="label">Dispositivo:</span>
              ${repair.deviceBrand} ${repair.deviceModel}
            </div>
            <div class="field">
              <span class="label">Problema:</span>
              ${repair.issueDescription.substring(0, 50)}${repair.issueDescription.length > 50 ? '...' : ''}
            </div>
            ${repair.devicePassword ? `
              <div class="field">
                <span class="label">Passcode:</span>
                ${repair.devicePassword}
              </div>
            ` : ''}
            ${repair.scheduledDate ? `
              <div class="field">
                <span class="label">Entrega:</span>
                ${format(new Date(repair.scheduledDate), 'dd/MM/yyyy HH:mm', { locale: es })}
              </div>
            ` : ''}
          </div>
          <div class="logo">
            ${company.logo ? `
              <img src="${company.logo}" alt="${company.name}">
            ` : `
              <div style="font-weight: bold; font-size: 8pt;">${company.name}</div>
            `}
          </div>
        </div>
      </body>
    </html>
  `;
}

function generateSaleReceiptHTML(
  sale: Sale,
  company: CompanySettings,
  options: {
    includeLogo: boolean;
    includeBarcode: boolean;
    includeFooter: boolean;
  }
): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Venta #${sale.id}</title>
        <style>
          @page {
            size: 80mm 297mm;
            margin: 0;
          }
          @media print {
            * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              font-size: 10pt;
            }
            body {
              padding: 10mm;
            }
            .logo {
              text-align: center;
              margin-bottom: 5mm;
            }
            .logo img {
              max-width: 30mm;
              max-height: 30mm;
            }
            .header {
              text-align: center;
              margin-bottom: 5mm;
            }
            .content {
              margin: 5mm 0;
            }
            .items {
              margin: 5mm 0;
            }
            .item {
              margin-bottom: 2mm;
            }
            .total {
              margin-top: 5mm;
              font-weight: bold;
            }
            .footer {
              margin-top: 10mm;
              text-align: center;
              font-size: 8pt;
            }
          }
        </style>
      </head>
      <body>
        ${options.includeLogo && company.logo ? `
          <div class="logo">
            <img src="${company.logo}" alt="${company.name}">
          </div>
        ` : ''}
        
        <div class="header">
          <h1>${company.name}</h1>
          <p>${company.contact.address || ''}</p>
          <p>Tel: ${company.contact.phone || ''}</p>
        </div>

        <div class="content">
          <div>Venta #${sale.id}</div>
          <div>Fecha: ${format(new Date(sale.createdAt), 'PPP', { locale: es })}</div>
          ${sale.customerName ? `<div>Cliente: ${sale.customerName}</div>` : ''}
          ${sale.customerPhone ? `<div>Teléfono: ${sale.customerPhone}</div>` : ''}
        </div>

        <div class="items">
          ${sale.items.map(item => `
            <div class="item">
              <div>${item.name}</div>
              <div>${item.quantity} x $${item.price.toFixed(2)} = $${item.total.toFixed(2)}</div>
            </div>
          `).join('')}
        </div>

        <div class="total">
          Total: $${sale.total.toFixed(2)}
        </div>

        ${options.includeFooter && company.printer.receiptFooter ? `
          <div class="footer">
            ${company.printer.receiptFooter}
          </div>
        ` : ''}
      </body>
    </html>
  `;
}

async function printDocument(content: string, copies: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      const contentWindow = iframe.contentWindow;
      if (!contentWindow) {
        document.body.removeChild(iframe);
        throw new Error('No se pudo crear el marco de impresión');
      }

      contentWindow.document.open();
      contentWindow.document.write(content);
      contentWindow.document.close();

      // Wait for images to load
      const images = contentWindow.document.images;
      const imageLoadPromises = Array.from(images).map(img => {
        if (img.complete) return Promise.resolve();
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      Promise.all(imageLoadPromises)
        .then(async () => {
          for (let i = 0; i < copies; i++) {
            contentWindow.print();
            if (i < copies - 1) {
              await new Promise(resolve => setTimeout(resolve, 500));
            }
          }
          
          // Clean up
          setTimeout(() => {
            document.body.removeChild(iframe);
            resolve(true);
          }, 1000);
        })
        .catch(error => {
          document.body.removeChild(iframe);
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}