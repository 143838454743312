/**
 * Componente de formulario para crear nuevas órdenes de reparación
 * Maneja la selección de clientes, entrada de datos del dispositivo y generación de recibos
 */

import React, { useState } from 'react';
import { useRepairStore } from '../../store/repairs';
import { useCustomerStore } from '../../store/customers';
import { useSettingsStore } from '../../store/settings';
import { CustomerSearch } from '../customers/CustomerSearch';
import { CustomerForm } from '../customers/CustomerForm';
import { Customer } from '../../types/customer';
import RepairDocument from '../../documents/RepairDocument.tsx'; 
import { RepairOrder, RepairPriority } from '../../types/repair';
import { toast } from 'react-hot-toast';
import { generatePDF } from '../../utils/pdf';
import { Calendar, Clock } from 'lucide-react';

interface RepairFormProps {
  onSuccess?: () => void;  // Callback llamado después de crear exitosamente una reparación
}

export function RepairForm({ onSuccess }: RepairFormProps) {
  // Estados para manejar el cliente seleccionado y el formulario de cliente
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [customerFormData, setCustomerFormData] = useState<{ name?: string; phone?: string }>({});
  
  // Estados para manejar el recibo y la reparación actual
  const [showReceipt, setShowReceipt] = useState(false);
  const [currentRepair, setCurrentRepair] = useState<RepairOrder | null>(null);
  
  // Estados para la fecha y hora programada
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');

  // Hooks de los stores
  const addRepair = useRepairStore((state) => state.addRepair);
  const getCustomer = useCustomerStore((state) => state.getCustomer);
  const company = useSettingsStore((state) => state.company);

  /**
   * Maneja la selección de un cliente
   * @param customer Cliente seleccionado
   */
  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
    setShowCustomerForm(false);
  };

  /**
   * Maneja la creación de un nuevo cliente
   */
  const handleNewCustomer = () => {
    setShowCustomerForm(true);
  };

  /**
   * Maneja la creación de un nuevo cliente
   * @param customerId ID del cliente creado
   */
  const handleCustomerSave = (customerId: string) => {
    const customer = getCustomer(customerId);
    if (customer) {
      setSelectedCustomer(customer);
      setShowCustomerForm(false);
    }
  };

  /**
   * Maneja el envío del formulario
   * @param e Evento de envío del formulario
   */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedCustomer) {
      toast.error('Por favor, selecciona un cliente');
      return;
    }

    const formData = new FormData(e.currentTarget);
    
    // Validar campos requeridos
    const requiredFields = ['deviceBrand', 'deviceModel', 'issueDescription', 'estimatedCost'];
    for (const field of requiredFields) {
      if (!formData.get(field)) {
        toast.error(`Por favor, completa el campo ${field}`);
        return;
      }
    }
    
    // Combine date and time for scheduled datetime
    let scheduledDateTime = null;
    if (scheduledDate && scheduledTime) {
      const dateTime = new Date(`${scheduledDate}T${scheduledTime}`);
      if (isNaN(dateTime.getTime())) {
        toast.error('La fecha y hora programada no son válidas');
        return;
      }
      scheduledDateTime = dateTime.toISOString();
    }
    
    const repairData: Omit<RepairOrder, 'id'> = {
      customerName: selectedCustomer.name,
      phone: selectedCustomer.phone,
      deviceBrand: formData.get('deviceBrand') as string,
      deviceModel: formData.get('deviceModel') as string,
      issueDescription: formData.get('issueDescription') as string,
      estimatedCost: Number(formData.get('estimatedCost')),
      advancePayment: Number(formData.get('advancePayment')) || 0,
      status: 'pending' as const,
      priority: formData.get('priority') as RepairPriority,
      accessories: formData.get('accessories') as string ? 
        (formData.get('accessories') as string).split(',').map(a => a.trim()).filter(Boolean) : [],
      imei: formData.get('imei') as string || undefined,
      devicePassword: formData.get('devicePassword') as string || undefined,
      scheduledDate: scheduledDateTime || undefined,
      technician: formData.get('technician') as string || undefined,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      statusHistory: [{
        id: Math.random().toString(36).substring(2, 15),
        status: 'pending',
        timestamp: new Date().toISOString(),
        technician: formData.get('technician') as string || undefined,
        note: 'Reparación creada'
      }]
    };

    try {
      // Guardar una referencia al formulario antes de las operaciones asíncronas
      const form = e.currentTarget;
      
      // Esperar a que se complete la adición de la reparación
      const repairId = await addRepair(repairData);
      
      // Crear el objeto de reparación completo con el ID
      const newRepair = {
        ...repairData,
        id: String(repairId) // Ensure id is a string
      };
      
      // Actualizar el estado local
      setCurrentRepair(newRepair);
      setShowReceipt(true);
      
      // Limpiar el formulario y estados
      form.reset();
      setSelectedCustomer(null);
      setScheduledDate('');
      setScheduledTime('');

      // Esperar a que el DOM se actualice usando MutationObserver
      try {
        await new Promise<void>((resolve, reject) => {
          const elementId = `repair-document-${repairId}`;
          const maxWaitTime = 5000; // 5 segundos máximo de espera
          
          const timeout = setTimeout(() => {
            observer.disconnect();
            reject(new Error('Tiempo de espera agotado esperando el elemento del recibo'));
          }, maxWaitTime);

          const observer = new MutationObserver((mutations, obs) => {
            const element = document.getElementById(elementId);
            if (element) {
              clearTimeout(timeout);
              obs.disconnect();
              // Dar tiempo adicional para que las imágenes y estilos se carguen
              setTimeout(resolve, 500);
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true
          });
        });
      } catch (observerError) {
        console.error('Error esperando el elemento del recibo:', observerError);
        // Continuamos con la ejecución aunque falle el observer
      }
      
      // Intentar generar el PDF
      const elementId = `repair-document-${repairId}`;
      const element = document.getElementById(elementId);
      
      if (!element) {
        throw new Error('ELEMENT_NOT_FOUND');
      }
      
      try {
        const fileName = `reparacion-${repairId}.pdf`;
        await generatePDF(elementId, fileName, 'receipt', {
          margin: 10,
          scale: 2,
          autoOrientation: true,
          saveToFile: true
        });
        
        toast.success('Ticket guardado correctamente');
      } catch (pdfError) {
        console.error('Error al generar el PDF:', pdfError);
        // No lanzar el error aquí para permitir que el formulario se limpie
        toast.error('No se pudo generar el PDF, pero la orden fue creada correctamente');
      }

      toast.success('Orden de reparación creada exitosamente');
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      console.error('Error al crear la reparación:', error);
      toast.error('Error al crear la orden de reparación');
    }
  };

  /**
   * Maneja el cierre del recibo
   */
  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setCurrentRepair(null);
    if (typeof onSuccess === 'function') onSuccess();
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Cliente
          </label>
          {showCustomerForm ? (
            <CustomerForm
              initialData={customerFormData}
              onSuccess={handleCustomerSave}
              onCancel={() => setShowCustomerForm(false)}
            />
          ) : (
            <CustomerSearch
              onSelect={handleCustomerSelect}
              onNewCustomer={handleNewCustomer}
            />
          )}
        </div>

        {selectedCustomer && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Marca del Dispositivo
                </label>
                <input
                  type="text"
                  name="deviceBrand"
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Modelo
                </label>
                <input
                  type="text"
                  name="deviceModel"
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Descripción del Problema
                </label>
                <textarea
                  name="issueDescription"
                  required
                  rows={3}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Costo estimado
                  </label>
                  <input
                    type="number"
                    name="estimatedCost"
                    required
                    min="0"
                    step="0.01"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                    placeholder="Ingrese el costo estimado"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Anticipo
                  </label>
                  <input
                    type="number"
                    name="advancePayment"
                    min="0"
                    step="0.01"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                    placeholder="Ingrese el anticipo (opcional)"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Prioridad
                </label>
                <select
                  name="priority"
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                >
                  <option value="low">Baja</option>
                  <option value="medium">Media</option>
                  <option value="high">Alta</option>
                  <option value="urgent">Urgente</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  IMEI
                </label>
                <input
                  type="text"
                  name="imei"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Contraseña del Dispositivo
                </label>
                <input
                  type="text"
                  name="devicePassword"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Fecha Programada
                </label>
                <div className="relative">
                  <Calendar className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                  <input
                    type="date"
                    value={scheduledDate}
                    onChange={(e) => setScheduledDate(e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 pl-10 pr-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Hora Programada
                </label>
                <div className="relative">
                  <Clock className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                  <input
                    type="time"
                    value={scheduledTime}
                    onChange={(e) => setScheduledTime(e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 pl-10 pr-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Técnico Asignado
                </label>
                <input
                  type="text"
                  name="technician"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Accesorios (separados por coma)
                </label>
                <input
                  type="text"
                  name="accessories"
                  placeholder="Cargador, funda, etc."
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Crear Orden de Reparación
              </button>
            </div>
          </form>
        )}
      </div>

      {showReceipt && currentRepair && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
            <div id={`repair-document-${currentRepair.id}`} className="mb-6 bg-white">
              <RepairDocument
                repair={currentRepair}
                company={company}
                format="receipt"
                options={{
                  includeLogo: true,
                  includeBarcode: true,
                  includeFooter: true
                }}
              />
            </div>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowReceipt(false)}
                className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}