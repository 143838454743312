import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { RepairOrder } from '../../types/repair';

Chart.register(ArcElement, Tooltip, Legend);

interface RepairStatusChartProps {
  repairs: RepairOrder[];
}

export function RepairStatusChart({ repairs }: RepairStatusChartProps) {
  const statusCounts = {
    pending: repairs.filter(r => r.status === 'pending').length,
    in_progress: repairs.filter(r => r.status === 'in_progress').length,
    completed: repairs.filter(r => r.status === 'completed').length,
    delivered: repairs.filter(r => r.status === 'delivered').length,
  };

  const data: ChartData<'doughnut'> = {
    labels: ['Pendiente', 'En Progreso', 'Completado', 'Entregado'],
    datasets: [
      {
        data: [
          statusCounts.pending,
          statusCounts.in_progress,
          statusCounts.completed,
          statusCounts.delivered,
        ],
        backgroundColor: [
          'rgba(245, 158, 11, 0.8)',
          'rgba(59, 130, 246, 0.8)',
          'rgba(16, 185, 129, 0.8)',
          'rgba(139, 92, 246, 0.8)',
        ],
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: [
          'rgb(245, 158, 11)',
          'rgb(59, 130, 246)',
          'rgb(16, 185, 129)',
          'rgb(139, 92, 246)',
        ],
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#1f2937',
        bodyColor: '#1f2937',
        bodyFont: {
          size: 14,
        },
        padding: 12,
        boxPadding: 8,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        displayColors: true,
        callbacks: {
          label: (context) => {
            const total = repairs.length;
            const value = context.raw as number;
            const percentage = total > 0 ? Math.round((value / total) * 100) : 0;
            return `${context.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    cutout: '60%',
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div className="relative h-[300px]">
      <Doughnut data={data} options={options} />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <div className="text-3xl font-bold text-gray-900 dark:text-white">
            {repairs.length}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">Total</div>
        </div>
      </div>
    </div>
  );
}