import React, { useState } from 'react';
import { useInventoryStore } from '../../store/inventory';
import { InventoryItem } from '../../types/inventory';
import { PlusCircle, MinusCircle } from 'lucide-react';

interface StockAdjustmentProps {
  item: InventoryItem;
  onClose: () => void;
}

export function StockAdjustment({ item, onClose }: StockAdjustmentProps) {
  const [quantity, setQuantity] = useState(0);
  const [type, setType] = useState<'add' | 'remove'>('add');
  const [reason, setReason] = useState('');
  const updateStock = useInventoryStore((state) => state.updateStock);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const adjustment = type === 'add' ? quantity : -quantity;
    updateStock(item.id, adjustment);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl dark:bg-gray-900">
        <h2 className="mb-4 text-lg font-medium">Ajustar Stock - {item.name}</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Stock Actual: {item.stock}
            </label>
          </div>

          <div className="flex gap-4">
            <button
              type="button"
              onClick={() => setType('add')}
              className={`flex-1 rounded-md px-4 py-2 text-sm font-medium ${
                type === 'add'
                  ? 'bg-green-600 text-white'
                  : 'border border-gray-300 text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300'
              }`}
            >
              <div className="flex items-center justify-center gap-2">
                <PlusCircle className="h-4 w-4" />
                Entrada
              </div>
            </button>
            <button
              type="button"
              onClick={() => setType('remove')}
              className={`flex-1 rounded-md px-4 py-2 text-sm font-medium ${
                type === 'remove'
                  ? 'bg-red-600 text-white'
                  : 'border border-gray-300 text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300'
              }`}
            >
              <div className="flex items-center justify-center gap-2">
                <MinusCircle className="h-4 w-4" />
                Salida
              </div>
            </button>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Cantidad
            </label>
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Motivo
            </label>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              rows={3}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
            >
              Guardar Ajuste
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}