import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Customer } from '../types/customer';

interface CustomerState {
  customers: Customer[];
  addCustomer: (customer: Omit<Customer, 'id' | 'createdAt' | 'updatedAt'>) => Customer;
  searchCustomers: (query: string) => Customer[];
  getCustomer: (id: string) => Customer | undefined;
  setCustomers: (customers: Customer[]) => void;
}

export const useCustomerStore = create<CustomerState>()(
  persist(
    (set, get) => ({
      customers: [],
      addCustomer: (customer) => {
        const newCustomer: Customer = {
          ...customer,
          id: Math.random().toString(36).substring(7),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        set((state) => ({ customers: [...state.customers, newCustomer] }));
        return newCustomer;
      },
      searchCustomers: (query) => {
        const { customers } = get();
        const searchTerm = query.toLowerCase();
        return customers.filter(
          (customer) =>
            customer.name.toLowerCase().includes(searchTerm) ||
            customer.phone.includes(searchTerm)
        );
      },
      getCustomer: (id) => get().customers.find((customer) => customer.id === id),
      setCustomers: (customers) => set({ customers }),
    }),
    {
      name: 'customers-storage',
    }
  )
);