import React from 'react';
import { MessageSquare, Volume2, Zap, MoveHorizontal } from 'lucide-react';
import { useChatbotStore } from '../../store/chatbot';
import { cn } from '../../lib/utils';

interface ChatbotSettingsProps {
  onUpdate: () => void;
}

export function ChatbotSettings({ onUpdate }: ChatbotSettingsProps) {
  const {
    position,
    autoSuggest,
    soundEnabled,
    setPosition,
    setAutoSuggest,
    setSoundEnabled,
  } = useChatbotStore();

  const handlePositionChange = (newPosition: 'left' | 'right') => {
    setPosition(newPosition);
    onUpdate();
  };

  const handleAutoSuggestChange = (enabled: boolean) => {
    setAutoSuggest(enabled);
    onUpdate();
  };

  const handleSoundChange = (enabled: boolean) => {
    setSoundEnabled(enabled);
    onUpdate();
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-lg font-medium">Configuración del Chatbot</h2>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Personaliza el comportamiento del asistente virtual
        </p>
      </div>

      <div className="space-y-6">
        {/* Posición */}
        <section>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-medium">Posición</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Elige dónde aparecerá el chatbot
              </p>
            </div>
            <MoveHorizontal className="h-5 w-5 text-gray-400" />
          </div>
          <div className="mt-4 flex gap-4">
            <button
              onClick={() => handlePositionChange('left')}
              className={cn(
                "flex-1 rounded-lg border px-4 py-3 text-sm transition-colors",
                position === 'left'
                  ? "border-blue-500 bg-blue-50 text-blue-700 dark:border-blue-400 dark:bg-blue-900/20 dark:text-blue-400"
                  : "border-gray-200 hover:border-blue-500 dark:border-gray-700"
              )}
            >
              Izquierda
            </button>
            <button
              onClick={() => handlePositionChange('right')}
              className={cn(
                "flex-1 rounded-lg border px-4 py-3 text-sm transition-colors",
                position === 'right'
                  ? "border-blue-500 bg-blue-50 text-blue-700 dark:border-blue-400 dark:bg-blue-900/20 dark:text-blue-400"
                  : "border-gray-200 hover:border-blue-500 dark:border-gray-700"
              )}
            >
              Derecha
            </button>
          </div>
        </section>

        {/* Sugerencias Automáticas */}
        <section>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-medium">Sugerencias Automáticas</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Recibe sugerencias basadas en el contexto
              </p>
            </div>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                checked={autoSuggest}
                onChange={(e) => handleAutoSuggestChange(e.target.checked)}
                className="peer sr-only"
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
        </section>

        {/* Sonidos */}
        <section>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-medium">Sonidos</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Notificaciones sonoras del chatbot
              </p>
            </div>
            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                checked={soundEnabled}
                onChange={(e) => handleSoundChange(e.target.checked)}
                className="peer sr-only"
              />
              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
        </section>

        {/* Vista Previa */}
        <section className="rounded-lg border p-6 dark:border-gray-700">
          <h3 className="mb-4 font-medium">Vista Previa</h3>
          <div className="relative h-48 rounded-lg bg-gray-100 dark:bg-gray-800">
            <div className={cn(
              "absolute bottom-4 flex w-64 flex-col gap-2 rounded-lg bg-white p-4 shadow-lg dark:bg-gray-900",
              position === 'left' ? "left-4" : "right-4"
            )}>
              <div className="flex items-center gap-2">
                <div className="rounded-lg bg-blue-100 p-2 dark:bg-blue-900/20">
                  <MessageSquare className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                </div>
                <div>
                  <p className="text-sm font-medium">Asistente Virtual</p>
                  <p className="text-xs text-gray-500">¿En qué puedo ayudarte?</p>
                </div>
              </div>
              {autoSuggest && (
                <div className="flex gap-2">
                  <button className="rounded-full bg-gray-100 px-3 py-1 text-xs text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">
                    Nueva reparación
                  </button>
                  <button className="rounded-full bg-gray-100 px-3 py-1 text-xs text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">
                    Ver inventario
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}