import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Sale, SaleItem } from '../types/sale';
import { format } from 'date-fns';

interface SalesState {
  sales: Sale[];
  addSale: (sale: Omit<Sale, 'id' | 'createdAt' | 'updatedAt'>) => Sale;
  updateSale: (id: string, updates: Partial<Sale>) => void;
  getSale: (id: string) => Sale | undefined;
  getDailySales: (date: Date) => Sale[];
  getTotalSales: (startDate: Date, endDate: Date) => number;
  setSales: (sales: Sale[]) => void;
}

export const useSalesStore = create<SalesState>()(
  persist(
    (set, get) => ({
      sales: [],
      addSale: (sale) => {
        const newSale: Sale = {
          ...sale,
          id: Math.random().toString(36).substring(7),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        set((state) => ({ sales: [...state.sales, newSale] }));
        return newSale;
      },
      updateSale: (id, updates) => {
        set((state) => ({
          sales: state.sales.map((sale) =>
            sale.id === id
              ? { ...sale, ...updates, updatedAt: new Date().toISOString() }
              : sale
          ),
        }));
      },
      getSale: (id) => get().sales.find((sale) => sale.id === id),
      getDailySales: (date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        return get().sales.filter((sale) =>
          format(new Date(sale.createdAt), 'yyyy-MM-dd') === dateStr
        );
      },
      getTotalSales: (startDate, endDate) => {
        return get().sales
          .filter((sale) => {
            const saleDate = new Date(sale.createdAt);
            return saleDate >= startDate && saleDate <= endDate && sale.status === 'completed';
          })
          .reduce((total, sale) => total + sale.total, 0);
      },
      setSales: (sales) => set({ sales }),
    }),
    {
      name: 'sales-storage',
    }
  )
);