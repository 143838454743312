import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { toast } from 'react-hot-toast';

export async function generatePDF(
  elementId: string, 
  fileName: string, 
  format: 'receipt' | 'sticker' | 'ticket-58' | 'ticket-80' | 'compact' | 'detailed' | 'service-label' = 'receipt',
  options?: {
    margin?: number;
    scale?: number;
    autoOrientation?: boolean;
    saveToFile?: boolean;
  }
): Promise<void> {
  try {
    const element = document.getElementById(elementId);
    if (!element) {
      throw new Error('Elemento no encontrado');
    }

    // Ensure all images are loaded
    const images = element.getElementsByTagName('img');
    await Promise.all(Array.from(images).map(img => {
      if (img.complete) return Promise.resolve();
      return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
    }));

    // Apply options with defaults
    const scale = options?.scale ?? (format === 'sticker' ? 2 : 1.5);
    const margin = options?.margin ?? (format === 'sticker' ? 0 : 10);

    const canvas = await html2canvas(element, {
      scale,
      useCORS: true,
      logging: false,
      backgroundColor: '#ffffff',
      windowWidth: format === 'sticker' ? 302 : element.scrollWidth,
      windowHeight: format === 'sticker' ? 189 : element.scrollHeight
    });

    const pdf = new jsPDF({
      orientation: format === 'sticker' ? 'l' : (options?.autoOrientation ? 'p' : 'p'),
      unit: 'mm',
      format: format === 'sticker' ? [50, 80] : 'a4'
    });

    const imgWidth = format === 'sticker' ? 80 : 210;
    const pageHeight = format === 'sticker' ? 50 : 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Add image to PDF with margins
    pdf.addImage(
      canvas.toDataURL('image/jpeg', 1.0),
      'JPEG',
      margin,
      margin,
      imgWidth - (margin * 2),
      imgHeight - (margin * 2)
    );

    // Save PDF
    pdf.save(fileName);
    toast.success('PDF generado correctamente');
  } catch (error) {
    console.error('Error al generar PDF:', error);
    toast.error('Error al generar el PDF. Por favor, inténtalo de nuevo.');
    throw error;
  }
}