import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';

interface PublicRouteProps {
  children: React.ReactNode;
  restricted?: boolean;
}

export function PublicRoute({ children, restricted = false }: PublicRouteProps) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  if (isAuthenticated && restricted) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
}