import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { Smartphone, AlertCircle } from 'lucide-react';
import { useSettingsStore } from '../store/settings';

export function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { company } = useSettingsStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      // Aquí implementarías la llamada real a tu API de autenticación
      // Por ahora simulamos una validación básica
      if (email === 'admin@example.com' && password === 'admin') {
        login({
          id: '1',
          name: 'Administrador',
          email: email,
          role: 'admin',
          permissions: ['all'],
        });
        navigate('/');
      } else if (email === 'tech@example.com' && password === 'tech') {
        login({
          id: '2',
          name: 'Técnico',
          email: email,
          role: 'technician',
          permissions: ['repairs.view', 'repairs.update'],
        });
        navigate('/');
      } else {
        setError('Credenciales inválidas');
      }
    } catch (err) {
      setError('Error al iniciar sesión');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left Panel - Login Form */}
      <div className="flex w-full flex-col justify-center px-4 sm:px-6 lg:w-1/2 lg:px-8">
        <div className="mx-auto w-full max-w-sm">
          <div className="flex justify-center">
            <div className="rounded-xl bg-blue-600 p-2 text-white">
              <Smartphone className="h-8 w-8" />
            </div>
          </div>
          
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Acceso al Sistema
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
            Ingresa tus credenciales para continuar
          </p>

          {error && (
            <div className="mt-4 rounded-md bg-red-50 p-4 dark:bg-red-900/20">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700 dark:text-red-400">{error}</p>
              </div>
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                  placeholder="tu@email.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Contraseña
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                  placeholder="••••••••"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
                  Recordarme
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400">
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="flex w-full justify-center rounded-lg bg-blue-600 px-4 py-2 text-sm font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
            </button>
          </form>
        </div>
      </div>

      {/* Right Panel - Company Info */}
      <div className="relative hidden w-1/2 lg:block">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800">
          <img
            src="https://images.unsplash.com/photo-1591799264318-7e6ef8ddb7ea?auto=format&fit=crop&q=80"
            alt="Background"
            className="h-full w-full object-cover opacity-20"
          />
        </div>
        <div className="absolute inset-0 flex flex-col justify-center px-12">
          <div className="max-w-lg">
            <h1 className="text-4xl font-bold text-white">
              {company.name}
            </h1>
            <p className="mt-4 text-xl text-blue-100">
              Sistema de Gestión de Reparaciones
            </p>
            <div className="mt-8 space-y-4">
              <div className="flex items-center gap-3 text-blue-100">
                <div className="rounded-full bg-blue-500 bg-opacity-20 p-2">
                  <Smartphone className="h-6 w-6" />
                </div>
                <span>Gestión completa de reparaciones</span>
              </div>
              <div className="flex items-center gap-3 text-blue-100">
                <div className="rounded-full bg-blue-500 bg-opacity-20 p-2">
                  <Smartphone className="h-6 w-6" />
                </div>
                <span>Control de inventario</span>
              </div>
              <div className="flex items-center gap-3 text-blue-100">
                <div className="rounded-full bg-blue-500 bg-opacity-20 p-2">
                  <Smartphone className="h-6 w-6" />
                </div>
                <span>Seguimiento de clientes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}